import classNames from "classnames";
import { forwardRef } from "react";
import styles from "./Textarea.module.css";

export type Props = JSX.IntrinsicElements["textarea"] & {
  isInvalid?: boolean;
  rows?: number;
};

const Textarea = forwardRef<HTMLTextAreaElement, Props>(({ isInvalid, ...props }, ref) => {
  const { autoComplete = "off", spellCheck = "false", rows = 5 } = props;
  return (
    <textarea
      ref={ref}
      {...props}
      rows={rows}
      autoComplete={autoComplete}
      spellCheck={spellCheck}
      readOnly={props.disabled}
      className={classNames(styles.textarea, props.className, {
        [styles.invalid]: isInvalid,
      })}
    />
  );
});

export default Textarea;
