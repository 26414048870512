import classNames from "classnames";
import { forwardRef } from "react";
import styles from "./Input.module.css";

export type Props = JSX.IntrinsicElements["input"] & {
  isInvalid?: boolean;
  forceUpper?: boolean;
};

const Input = forwardRef<HTMLInputElement, Props>(({ isInvalid, forceUpper, ...props }, ref) => {
  const { type = "text", autoComplete = "off", spellCheck = "false" } = props;
  return (
    <input
      ref={ref}
      {...props}
      type={type}
      autoComplete={autoComplete}
      spellCheck={spellCheck}
      readOnly={props.disabled || props.readOnly}
      className={classNames(styles.input, props.className, {
        [styles.invalid]: isInvalid,
      })}
      onChange={(e) => {
        e.target.value = forceUpper ? e.target.value.toUpperCase() : e.target.value;
        return props.onChange?.(e);
      }}
    />
  );
});

export default Input;
