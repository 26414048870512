import { useEffect, useState } from "react";

const useInitializer = (data: unknown) => {
  const [isInitialized, setIsInitialized] = useState(!!data);

  useEffect(() => {
    data && !isInitialized && setIsInitialized(true);
  }, [data]);

  return isInitialized;
};

export default useInitializer;
