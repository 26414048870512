import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";
import useSWRMutation from "swr/mutation";
import { Paginated, PaginationQuery, PostPolicy } from "../../types";
import { buildUrl, mutateCache, useApi } from "../api";
import { CustomError } from "../errors";
import {
  AdminWallMaterial,
  InsertWallMaterialBody,
  UpdateWallMaterialNewImageBody,
  UpdateWallMaterialRemoveImageBody,
} from "../types/vertigrip/wallMaterials";

export const useAdminWallMaterialsApi = () => {
  const api = useApi();

  const getAdminWallMaterial = useCallback(
    (id: string, signal: AbortSignal) => {
      const url = `/admin/settings/vertigrip/wall_materials/${id}`;
      return useSWRMutation<AdminWallMaterial, CustomError, string>(url, (url) =>
        api.get<AdminWallMaterial>({ url, signal })
      );
    },
    [api]
  );

  const useAdminInstallationTypeWallMaterials = useCallback(
    (installationTypeId: string, query: PaginationQuery, signal: AbortSignal, includeHidden?: boolean) => {
      const url = buildUrl(
        `/admin/settings/vertigrip/installation_types/${installationTypeId}/wall_materials`,
        query,
        includeHidden
      );
      return useSWRImmutable<Paginated<AdminWallMaterial>, CustomError, string>(url, (url) =>
        api.get<Paginated<AdminWallMaterial>>({ url, signal })
      );
    },
    [api]
  );

  const insertAdminWallMaterial = useCallback(
    async (body: InsertWallMaterialBody): Promise<{ id: string }> => {
      const url = `/admin/settings/vertigrip/wall_materials`;
      const id = await api.post<{ id: string }, InsertWallMaterialBody>(url, body);
      mutateCache([url, `/admin/settings/vertigrip/installation_types/${body.installationTypeId}/wall_materials`]);
      return id;
    },
    [api]
  );

  const updateAdminWallMaterial = useCallback(
    async (
      wallMaterialId: string,
      body: UpdateWallMaterialRemoveImageBody | UpdateWallMaterialNewImageBody
    ): Promise<void> => {
      const url = `/admin/settings/vertigrip/wall_materials/${wallMaterialId}`;
      await api.put<UpdateWallMaterialRemoveImageBody | UpdateWallMaterialNewImageBody>(url, body);
      mutateCache([
        `/admin/settings/vertigrip/wall_materials`,
        `/admin/settings/vertigrip/installation_types/${body.installationTypeId}/wall_materials`,
      ]);
    },
    [api]
  );

  const deleteAdminWallMaterial = useCallback(
    async (wallMaterialId: string, installationTypeId: string): Promise<void> => {
      const url = `/admin/settings/vertigrip/wall_materials/${wallMaterialId}`;
      await api.del(url).then(() => {
        mutateCache([
          `/admin/settings/vertigrip/wall_materials`,
          `/admin/settings/vertigrip/wall_materials/${wallMaterialId}`,
          `/admin/settings/vertigrip/installation_types/${installationTypeId}/wall_materials`,
          `/admin/settings/vertigrip/installation_types/${installationTypeId}/wall_materials/${wallMaterialId}`,
        ]);
      });
    },
    [api]
  );

  const generateUploadImageUrl = useCallback(
    async (wallMaterialId: string, fileName: string): Promise<PostPolicy> => {
      const encodedFileName = encodeURIComponent(fileName);
      const url = `/admin/settings/vertigrip/wall_materials/${wallMaterialId}/image/generate_upload_url?file_name=${encodedFileName}`;
      return api.get<PostPolicy>({ url });
    },
    [api]
  );

  return {
    getAdminWallMaterial,
    useAdminInstallationTypeWallMaterials,
    insertAdminWallMaterial,
    updateAdminWallMaterial,
    deleteAdminWallMaterial,
    generateUploadImageUrl,
  };
};
