import { FC, useContext, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import NotificationDispatch, { showErrorNotification } from "../../../../../../../context/notificationContext";
import { useDebounce } from "../../../../../../../hooks/useDebounce";
import useInitializer from "../../../../../../../hooks/useInitializer";
import useListFilters from "../../../../../../../hooks/useListFilters";
import useI18n from "../../../../../../../hooks/useTranslations";
import { AdminWallMaterialFixing } from "../../../../../../../http/types/vertigrip/wallMaterialFixings";
import { useAdminWallMaterialFixingsApi } from "../../../../../../../http/vertigrip/useWallMaterialFixings";
import { PaginationQuery } from "../../../../../../../types";
import { formatBoolean } from "../../../../../../../utils";
import SvgAdd from "../../../../../../icons/Add";
import Button from "../../../../../../ui/Button";
import ModalDialog from "../../../../../../ui/ModalDialog";
import ScrollContent from "../../../../../../ui/ScrollContent";
import Table from "../../../../../../ui/Table";

const WallMaterialFixings: FC = () => {
  const navigate = useNavigate();
  const i18n = useI18n();
  const { systemId } = useParams<{ systemId: string }>();
  const { installationTypeId } = useParams<{ installationTypeId: string }>();
  const { id } = useParams<{ id: string }>();
  const dispatch = useContext(NotificationDispatch);
  const filters = useListFilters({ isSearchable: true });
  const aborter = useRef(new AbortController());
  const wallMaterialFixingsApi = useAdminWallMaterialFixingsApi();

  const [itemToDelete, setItemToDelete] = useState<AdminWallMaterialFixing>();

  const debouncedValue = useDebounce(filters.state.q);

  const query: PaginationQuery = {
    ...filters.state,
    q: debouncedValue,
  };

  const { error, data } = wallMaterialFixingsApi.useAdminWallMaterialFixings(id!, query, aborter.current.signal);

  const isInitialized = useInitializer(data || error);

  if (!data && !error && !isInitialized) {
    return;
  }

  return (
    <ScrollContent padding paddingTop>
      <Table<AdminWallMaterialFixing>
        isTall={true}
        filters={filters}
        searchOptions={{
          placeholder: i18n.translation.wallMaterialFixings.search,
          onChange: () => {
            aborter.current.abort();
            aborter.current = new AbortController();
          },
        }}
        searchButtons={
          <Button
            glyph={SvgAdd}
            buttonProps={{
              onClick: () =>
                navigate(
                  `/admin/systems/${systemId}/installation_types/${installationTypeId}/wall_materials/${id}/fixings/new`
                ),
            }}
          >
            {i18n.translation.wallMaterialFixings.createNew}
          </Button>
        }
        head={
          <tr>
            <th align="left">{i18n.translation.common.name}</th>
            <th align="left">{i18n.translation.common.visible}</th>
            <th align="left">{i18n.translation.common.order}</th>
            <th></th>
          </tr>
        }
        items={data?.items}
        renderRow={(item) => (
          <>
            <td>
              <Link
                to={`/admin/systems/${systemId}/installation_types/${installationTypeId}/wall_materials/${id}/fixings/${item.id}`}
              >
                {item.name}
              </Link>
            </td>
            <td>{formatBoolean(i18n.translation, item.isVisible)}</td>
            <td>{item.order}</td>
          </>
        )}
        rowActions={[
          {
            title: i18n.translation.common.edit,
            onClick: (item) =>
              navigate(
                `/admin/systems/${systemId}/installation_types/${installationTypeId}/wall_materials/${id}/fixings/${item.id}`
              ),
          },
          { title: i18n.translation.common.delete, onClick: setItemToDelete },
        ]}
        total={data?.total}
        error={!!error}
      />
      <ModalDialog
        isOpen={!!itemToDelete}
        onClose={() => setItemToDelete(undefined)}
        title={i18n.translation.wallMaterialFixings.delete}
        actions={[
          {
            title: i18n.translation.common.cancel,
            onClick: () => setItemToDelete(undefined),
          },
          {
            title: i18n.translation.common.yes,
            onClick: () => {
              itemToDelete &&
                wallMaterialFixingsApi
                  .deleteAdminWallMaterialFixing(itemToDelete.id, itemToDelete.wallMaterial.id)
                  .then(() => setItemToDelete(undefined))
                  .catch((err) => dispatch(showErrorNotification(i18n, err)));
            },
          },
        ]}
      >
        {i18n.translation.wallMaterialFixings.questions.delete}
      </ModalDialog>
    </ScrollContent>
  );
};

export default WallMaterialFixings;
