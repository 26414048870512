import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";
import useSWRMutation from "swr/mutation";
import { Paginated, PaginationQuery, PostPolicy } from "../../types";
import { buildUrl, mutateCache, useApi } from "../api";
import { CustomError } from "../errors";
import {
  AdminWallMaterialFixing,
  InsertWallMaterialFixingBody,
  UpdateWallMaterialFixingNewImageBody,
  UpdateWallMaterialFixingRemoveImageBody,
} from "../types/vertigrip/wallMaterialFixings";

export const useAdminWallMaterialFixingsApi = () => {
  const api = useApi();

  const getAdminWallMaterialFixing = useCallback(
    (id: string, signal: AbortSignal) => {
      const url = `/admin/settings/vertigrip/wall_material_fixings/${id}`;
      return useSWRMutation<AdminWallMaterialFixing, CustomError, string>(url, (url) =>
        api.get<AdminWallMaterialFixing>({ url, signal })
      );
    },
    [api]
  );

  const useAdminWallMaterialFixings = useCallback(
    (wallMaterialId: string, query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl(`/admin/settings/vertigrip/wall_materials/${wallMaterialId}/fixings`, query);
      return useSWRImmutable<Paginated<AdminWallMaterialFixing>, CustomError, string>(url, (url) =>
        api.get<Paginated<AdminWallMaterialFixing>>({ url, signal })
      );
    },
    [api]
  );

  const insertAdminWallMaterialFixing = useCallback(
    async (body: InsertWallMaterialFixingBody): Promise<{ id: string }> => {
      const url = `/admin/settings/vertigrip/wall_material_fixings`;
      const id = await api.post<{ id: string }, InsertWallMaterialFixingBody>(url, body);
      mutateCache([url, `/admin/settings/vertigrip/wall_materials/${body.wallMaterialId}/fixings`]);
      return id;
    },
    [api]
  );

  const updateAdminWallMaterialFixing = useCallback(
    async (
      wallMaterialFixingId: string,
      body: UpdateWallMaterialFixingRemoveImageBody | UpdateWallMaterialFixingNewImageBody
    ): Promise<void> => {
      const url = `/admin/settings/vertigrip/wall_material_fixings/${wallMaterialFixingId}`;
      await api.put<UpdateWallMaterialFixingRemoveImageBody | UpdateWallMaterialFixingNewImageBody>(url, body),
        mutateCache([
          `/admin/settings/vertigrip/wall_material_fixings`,
          `/admin/settings/vertigrip/wall_materials/${body.wallMaterialId}/fixings`,
        ]);
    },
    [api]
  );

  const deleteAdminWallMaterialFixing = useCallback(
    async (wallMaterialFixingId: string, wallMaterialId: string): Promise<void> => {
      const url = `/admin/settings/vertigrip/wall_material_fixings/${wallMaterialFixingId}`;
      await api.del(url).then(() => {
        mutateCache([
          `/admin/settings/vertigrip/wall_material_fixings`,
          `/admin/settings/vertigrip/wall_material_fixings/${wallMaterialFixingId}`,
          `/admin/settings/vertigrip/wall_materials/${wallMaterialId}/fixings`,
          `/admin/settings/vertigrip/wall_materials/${wallMaterialId}/fixings/${wallMaterialFixingId}`,
        ]);
      });
    },
    [api]
  );

  const generateUploadImageUrl = useCallback(
    async (wallMaterialFixingId: string, fileName: string): Promise<PostPolicy> => {
      const encodedFileName = encodeURIComponent(fileName);
      const url = `/admin/settings/vertigrip/wall_material_fixings/${wallMaterialFixingId}/image/generate_upload_url?file_name=${encodedFileName}`;
      return api.get<PostPolicy>({ url });
    },
    [api]
  );

  return {
    getAdminWallMaterialFixing,
    useAdminWallMaterialFixings,
    insertAdminWallMaterialFixing,
    updateAdminWallMaterialFixing,
    deleteAdminWallMaterialFixing,
    generateUploadImageUrl,
  };
};
