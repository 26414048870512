import { Glyph } from "../../types";

const SvgUnchecked: Glyph = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 -5 14 20" {...props} height="100%" width="100%">
      <path
        d="M9.9 2.12L7.78 0 4.95 2.828 2.12 0 0 2.12l2.83 2.83L0 7.776 2.123 9.9 4.95 7.07 7.78 9.9 9.9 7.776 7.072 4.95 9.9 2.12"
        fill="#fff"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default SvgUnchecked;
