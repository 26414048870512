import { FC, SVGProps } from "react";
import { Translation } from "../hooks/useTranslations";
import de from "../i18n/de";
import en from "../i18n/en";
import es from "../i18n/es";
import fr from "../i18n/fr";
import it from "../i18n/it";

export type Glyph = FC<SVGProps<SVGSVGElement>>;

export type FetcherOptions = {
  url: string;
  signal?: AbortSignal;
};

export type ListFilters = {
  page: number;
  limit: number;
  q?: string;
};

export type Paginated<P> = {
  items: P[];
  total: number;
};

export type PaginationQuery = Partial<ListFilters>;

export type LanguageCode =
  | "en"
  | "it"
  | "de"
  | "es"
  | "fr"
  | "ar"
  | "cs"
  | "da"
  | "el"
  | "et"
  | "fi"
  | "hr"
  | "hu"
  | "is"
  | "ja"
  | "lt"
  | "lv"
  | "nl"
  | "no"
  | "pl"
  | "pt"
  | "ru"
  | "sk"
  | "sl"
  | "sv"
  | "tr"
  | "zh"
  | "kr";

export type Language = {
  value: LanguageCode;
  display: string;
};

export const getUILanguages = (translations: Translation): Language[] => [
  { value: en.languageCode, display: translations.common.languages.english },
  { value: it.languageCode, display: translations.common.languages.italian },
  { value: de.languageCode, display: translations.common.languages.german },
  { value: es.languageCode, display: translations.common.languages.spanish },
  { value: fr.languageCode, display: translations.common.languages.french },
];

export const getAllLanguages = (translations: Translation): Language[] => {
  const rest: Language[] = [
    { value: "ar", display: translations.common.languages.arabic },
    { value: "cs", display: translations.common.languages.czech },
    { value: "da", display: translations.common.languages.danish },
    { value: "el", display: translations.common.languages.greek },
    { value: "et", display: translations.common.languages.estonian },
    { value: "fi", display: translations.common.languages.finnish },
    { value: "hr", display: translations.common.languages.croatian },
    { value: "hu", display: translations.common.languages.hungarian },
    { value: "is", display: translations.common.languages.icelandic },
    { value: "ja", display: translations.common.languages.japanese },
    { value: "lt", display: translations.common.languages.lithuanian },
    { value: "lv", display: translations.common.languages.latvian },
    { value: "nl", display: translations.common.languages.dutch },
    { value: "no", display: translations.common.languages.norwegian },
    { value: "pl", display: translations.common.languages.polish },
    { value: "pt", display: translations.common.languages.portugese },
    { value: "ru", display: translations.common.languages.russian },
    { value: "sk", display: translations.common.languages.slovak },
    { value: "sl", display: translations.common.languages.slovenian },
    { value: "sv", display: translations.common.languages.swedish },
    { value: "tr", display: translations.common.languages.turkish },
    { value: "zh", display: translations.common.languages.chinese },
    { value: "kr", display: translations.common.languages.korean },
  ];

  return [...getUILanguages(translations), ...rest.sort((a, b) => a.display.localeCompare(b.display))];
};

export type PostPolicy = {
  objectId: string;
  url: string;
  fields: {
    [key: string]: string;
  };
};

export type UploadFileResponse = {
  ok: boolean;
  status: number;
};

export type Text = {
  value: string;
  image?: string;
};

export interface ItemFixingWithQuantity {
  wallMaterial: {
    id: string;
    name: string;
  };
  quantity: number | null;
}
