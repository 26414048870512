import { FC } from "react";
import useI18n from "../../../../../hooks/useTranslations";
import ModalDialog from "../../../../ui/ModalDialog";
import UpperExtensionForm from "./UpperExtensionForm";

interface EditUpperExtensionModalDialogProps {
  upperExtensionId?: string;
  installationTypeId?: string;
  steelVersionId?: string;
  onClose: () => void;
}

const EditUpperExtensionModalDialog: FC<EditUpperExtensionModalDialogProps> = ({
  upperExtensionId,
  installationTypeId,
  steelVersionId,
  onClose,
}: EditUpperExtensionModalDialogProps) => {
  const i18n = useI18n();

  return (
    <ModalDialog isOpen={!!upperExtensionId} onClose={() => onClose()} title={i18n.translation.upperExtensions.edit}>
      <UpperExtensionForm
        onCancel={onClose}
        installationTypeId={installationTypeId}
        steelVersionId={steelVersionId}
        upperExtensionId={upperExtensionId}
      />
    </ModalDialog>
  );
};

export default EditUpperExtensionModalDialog;
