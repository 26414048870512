import { FC, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import useTabs from "../../../../../hooks/useTabs";
import useI18n from "../../../../../hooks/useTranslations";
import { useAdminInstallationTypesApi } from "../../../../../http/vertigrip/useInstallationTypes";
import Header from "../../../../ui/Header";
import Tabs from "../../../../ui/Tabs";
import InstallationTypeIntermediateElements from "../intermediateElements/InstallationTypeIntermediateElements";
import LowerElements from "../lowerElements/InstallationTypeLowerElements";
import InstallationTypeUpperExtensions from "../upperExtensions/InstallationTypeUpperExtensions";
import InstallationTypeDetails from "./InstallationTypeDetails";
import LadderReinforcements from "./ladderReinforcements/LadderReinforcements";
import WallMaterials from "./wallMaterials/WallMaterials";

const InstallationType: FC = () => {
  const i18n = useI18n();
  const { systemId } = useParams<{ systemId: string }>();
  const { id } = useParams<{ id: string }>();
  const installationTypeApi = useAdminInstallationTypesApi();

  const aborter = useRef(new AbortController());
  const installationTypeSwr = installationTypeApi.getAdminInstallationType(id!, aborter.current.signal);

  useEffect(() => {
    id && installationTypeSwr.trigger();
  }, [id]);

  const tabs = [
    { title: i18n.translation.common.generalData },
    { title: i18n.translation.upperExtensions.plural },
    { title: i18n.translation.lowerElements.plural },
    { title: i18n.translation.intermediateElements.plural },
    { title: i18n.translation.ladderReinforcements.plural },
    { title: i18n.translation.wallMaterials.plural },
  ];
  const controller = useTabs(tabs.length);

  const path = [
    { url: `/admin/systems/${systemId}`, display: i18n.translation.systems.vertigrip },
    {
      url: `/admin/systems/${systemId}/settings?tab=3`,
      display: `${i18n.translation.common.settings}: ${i18n.translation.installationTypes.plural}`,
    },
    { display: installationTypeSwr.data?.name ?? "" },
  ];

  return (
    <>
      <Header
        title={`${i18n.translation.installationTypes.singular}: ${installationTypeSwr.data?.name}`}
        error={installationTypeSwr.error}
        path={path}
      />
      <Tabs
        controller={controller}
        tabs={tabs}
        panels={[
          <InstallationTypeDetails
            installationType={!installationTypeSwr.error ? installationTypeSwr.data : undefined}
            disabled={installationTypeSwr.isMutating}
          />,
          <InstallationTypeUpperExtensions />,
          <LowerElements />,
          <InstallationTypeIntermediateElements />,
          <LadderReinforcements />,
          <WallMaterials />,
        ]}
        hasSubTabs
      />
    </>
  );
};

export default InstallationType;
