import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";
import useSWRMutation from "swr/mutation";
import { Paginated, PaginationQuery } from "../../types";
import { buildUrl, mutateCache, useApi } from "../api";
import { CustomError } from "../errors";
import { InsertLowerElementBody, LowerElement, UpdateLowerElementBody } from "../types/vertigrip/lowerElements";

export const useAdminLowerElementsApi = () => {
  const api = useApi();

  const getAdminLowerElement = useCallback(
    (id: string, signal: AbortSignal) => {
      const url = `/admin/settings/vertigrip/lower_elements/${id}`;
      return useSWRMutation<LowerElement, CustomError, string>(url, (url) => api.get<LowerElement>({ url, signal }));
    },
    [api]
  );

  const useSteelVersionAdminLowerElements = useCallback(
    (steelVersionId: string, query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl(`/admin/settings/vertigrip/steel_versions/${steelVersionId}/lower_elements`, query);
      return useSWRImmutable<Paginated<LowerElement>, CustomError, string>(url, (url) =>
        api.get<Paginated<LowerElement>>({ url, signal })
      );
    },
    [api]
  );

  const useInstallationTypeAdminLowerElements = useCallback(
    (installationTypeId: string, query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl(`/admin/settings/vertigrip/installation_types/${installationTypeId}/lower_elements`, query);
      return useSWRImmutable<Paginated<LowerElement>, CustomError, string>(url, (url) =>
        api.get<Paginated<LowerElement>>({ url, signal })
      );
    },
    [api]
  );

  const insertAdminLowerElement = useCallback(
    async (body: InsertLowerElementBody): Promise<{ id: string }> => {
      const url = `/admin/settings/vertigrip/lower_elements`;
      const id = await api.post<{ id: string }, InsertLowerElementBody>(url, body);
      mutateCache([
        url,
        `/admin/settings/vertigrip/installation_types/${body.installationTypeId}/lower_elements`,
        `/admin/settings/vertigrip/steel_versions/${body.steelVersionId}/lower_elements`,
      ]);
      return id;
    },
    [api]
  );

  const updateAdminLowerElement = useCallback(
    async (lowerElementId: string, body: UpdateLowerElementBody): Promise<void> => {
      const url = `/admin/settings/vertigrip/lower_elements/${lowerElementId}`;
      await api.put<UpdateLowerElementBody>(url, body),
        mutateCache([
          `/admin/settings/vertigrip/lower_elements`,
          `/admin/settings/vertigrip/installation_types/${body.installationTypeId}/lower_elements`,
          `/admin/settings/vertigrip/steel_versions/${body.steelVersionId}/lower_elements`,
        ]);
    },
    [api]
  );

  const deleteAdminLowerElement = useCallback(
    async (lowerElementId: string, steelVersionId: string, installationTypeId: string): Promise<void> => {
      const url = `/admin/settings/vertigrip/lower_elements/${lowerElementId}`;
      await api.del(url).then(() => {
        mutateCache([
          `/admin/settings/vertigrip/lower_elements`,
          `/admin/settings/vertigrip/lower_elements/${lowerElementId}`,
          `/admin/settings/vertigrip/installation_types/${installationTypeId}/lower_elements`,
          `/admin/settings/vertigrip/steel_versions/${steelVersionId}/lower_elements`,
          `/admin/settings/vertigrip/installation_types/${installationTypeId}/lower_elements/${lowerElementId}`,
          `/admin/settings/vertigrip/steel_versions/${steelVersionId}/lower_elements/${lowerElementId}`,
        ]);
      });
    },
    [api]
  );

  return {
    getAdminLowerElement,
    useSteelVersionAdminLowerElements,
    useInstallationTypeAdminLowerElements,
    insertAdminLowerElement,
    updateAdminLowerElement,
    deleteAdminLowerElement,
  };
};
