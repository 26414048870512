import { Glyph } from "../../types";

const SvgResetImage: Glyph = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" {...props}>
      <path d="M144-576v-240h72v128q46-59 114.72-93.5Q399.43-816 480-816q115 0 203 67.5T802-576h-76q-29-74-95-121t-151-47q-62 0-115 25.5T276-648h108v72H144Zm120 336h432L552-432 444-288l-72-96-108 144ZM216-96q-29.7 0-50.85-21.15Q144-138.3 144-168v-312h72v312h528v-312h72v312q0 29.7-21.15 50.85Q773.7-96 744-96H216Z" />
    </svg>
  );
};

export default SvgResetImage;
