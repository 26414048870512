import { FC, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import useI18n from "../../../../../hooks/useTranslations";
import { useAdminNormativesApi } from "../../../../../http/vertigrip/useNormatives";
import Header from "../../../../ui/Header";
import NormativeDetails from "./NormativeDetails";

const Normative: FC = () => {
  const i18n = useI18n();
  const { systemId } = useParams<{ systemId: string }>();
  const { id } = useParams<{ id: string }>();
  const normativesApi = useAdminNormativesApi();

  const aborter = useRef(new AbortController());
  const { error, data, isMutating, trigger } = normativesApi.getAdminNormative(id!, aborter.current.signal);

  const path = [
    { url: `/admin/systems/${systemId}`, display: i18n.translation.systems.vertigrip },
    {
      url: `/admin/systems/${systemId}/settings?tab=0`,
      display: `${i18n.translation.common.settings}: ${i18n.translation.normatives.plural}`,
    },
    { display: data?.name ?? "" },
  ];

  useEffect(() => {
    id && trigger();
  }, [id]);

  return (
    <>
      <Header title={`${i18n.translation.normatives.singular}: ${data?.name ?? ""}`} error={error} path={path} />
      <NormativeDetails normative={!error ? data : undefined} disabled={isMutating || !!error} />
    </>
  );
};

export default Normative;
