import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import SvgChecked from "../icons/Checked";
import SvgUnchecked from "../icons/Unchecked";
import Icon from "./Icon";
import styles from "./Switch.module.css";

interface ReactSwitchProps {
  id: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
}

const Switch: React.FC<ReactSwitchProps> = (props) => {
  const { checked, disabled, onChange, id, ...rest } = props;

  const height = 28;
  const width = 56;

  const handleDiameter = height - 2;
  const checkedPos = Math.max(width - height, width - (height + handleDiameter) / 2);
  const uncheckedPos = Math.max(0, (height - handleDiameter) / 2);

  const [pos, setPos] = useState(checked ? checkedPos : uncheckedPos);
  const [isDragging, setIsDragging] = useState(false);
  //   const [dragStartingTime, setDragStartingTime] = useState<number | null>(null);
  //   const [startX, setStartX] = useState<number | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setPos(checked ? checkedPos : uncheckedPos);
  }, [checked, checkedPos, uncheckedPos]);

  const onDragStart = (_: number) => {
    inputRef.current?.focus();
    // setStartX(clientX);
    // setDragStartingTime(Date.now());
  };

  const onDrag = (_: number) => {
    // if (startX === null) {
    //   return;
    // }
    // const startPos = checked ? checkedPos : uncheckedPos;
    // const mousePos = startPos + clientX - startX;

    // if (!isDragging && clientX !== startX) {
    setIsDragging(true);
    // }

    // const newPos = Math.min(checkedPos, Math.max(uncheckedPos, mousePos));
    // if (newPos !== pos) {
    //   setPos(newPos);
    // }
  };

  const onDragStop = () => {
    // const halfwayCheckpoint = (checkedPos + uncheckedPos) / 2;
    // const prevPos = checked ? checkedPos : uncheckedPos;
    // setPos(prevPos);

    // const timeSinceStart = dragStartingTime ? Date.now() - dragStartingTime : 0;
    // const isSimulatedClick = !isDragging || timeSinceStart < 250;
    // const isDraggedHalfway = (checked && pos <= halfwayCheckpoint) || (!checked && pos >= halfwayCheckpoint);

    // if (isSimulatedClick || isDraggedHalfway) {
    !disabled && onChange(!checked);
    // }

    setIsDragging(false);
  };

  const onMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    if (event.button !== 0) {
      return;
    }

    onDragStart(event.clientX);
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
  };

  const onMouseMove = (event: MouseEvent) => {
    event.preventDefault();
    onDrag(event.clientX);
  };

  const onMouseUp = () => {
    onDragStop();
    window.removeEventListener("mousemove", onMouseMove);
    window.removeEventListener("mouseup", onMouseUp);
  };

  //   const onTouchStart = (event: React.TouchEvent) => {
  //     onDragStart(event.touches[0]?.clientX ?? 0);
  //   };

  //   const onTouchMove = (event: React.TouchEvent) => {
  //     onDrag(event.touches[0]?.clientX ?? 0);
  //   };

  //   const onTouchEnd = (event: React.TouchEvent) => {
  //     event.preventDefault();
  //     onDragStop();
  //   };

  const onInputChange = () => {
    !disabled && onChange(!checked);
  };

  const onClick = (event: React.MouseEvent) => {
    event.preventDefault();
    inputRef.current?.focus();
    !disabled && onChange(!checked);
  };

  return (
    <div>
      <div
        className={classNames(styles.switchContainer, {
          [styles.switchDisabled]: disabled,
          [styles.switchActive]: checked,
        })}
        style={{
          borderRadius: height / 2,
        }}
      >
        <div
          className={classNames(styles.wrapper, {
            [styles.wrapperDragging]: isDragging,
          })}
          style={{
            height,
            width,
            margin: Math.max(0, (handleDiameter - height) / 2),
            borderRadius: height / 2,
          }}
          onClick={onClick}
        >
          <div
            className={classNames(styles.icon, styles.iconChecked, { [styles.iconActive]: checked })}
            style={{
              height,
              width: Math.min(height * 1.5, width - (handleDiameter + height) / 2 + 1),
            }}
          >
            <Icon glyph={SvgChecked} />
          </div>
          <div
            className={classNames(styles.icon, styles.iconUnchecked, { [styles.iconActive]: !checked })}
            style={{
              height,
              width: Math.min(height * 1.5, width - (handleDiameter + height) / 2 + 1),
            }}
          >
            <Icon glyph={SvgUnchecked} />
          </div>
        </div>
        <div
          className={styles.handle}
          style={{
            height: handleDiameter,
            width: handleDiameter,
            transform: `translateX(${pos}px)`,
            top: Math.max(0, (height - handleDiameter) / 2),
          }}
          onMouseDown={onMouseDown}
          // onTouchStart={onTouchStart}
          // onTouchMove={onTouchMove}
          // onTouchEnd={onTouchEnd}
        ></div>
        <input
          ref={inputRef}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          className={styles.checkbox}
          onChange={onInputChange}
          {...rest}
        />
      </div>
    </div>
  );
};

export default Switch;
