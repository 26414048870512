import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";
import { Paginated, PaginationQuery } from "../../types";
import { buildUrl, mutateCache, useApi } from "../api";
import { CustomError } from "../errors";
import { Cable, InsertCableBody, UpdateCableBody } from "../types/vertigrip/cables";

export const useAdminCablesApi = () => {
  const api = useApi();

  const useAdminCables = useCallback(
    (query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl(`/admin/settings/vertigrip/cables`, query);
      return useSWRImmutable<Paginated<Cable>, CustomError, string>(url, (url) =>
        api.get<Paginated<Cable>>({ url, signal })
      );
    },
    [api]
  );

  const insertAdminCable = useCallback(
    async (body: InsertCableBody): Promise<{ id: string }> => {
      const url = `/admin/settings/vertigrip/cables`;
      const id = await api.post<{ id: string }, InsertCableBody>(url, body);
      mutateCache([url]);
      return id;
    },
    [api]
  );

  const updateAdminCable = useCallback(
    async (cableId: string, body: UpdateCableBody): Promise<void> => {
      const url = `/admin/settings/vertigrip/cables/${cableId}`;
      await api.put<UpdateCableBody>(url, body), mutateCache([`/admin/settings/vertigrip/cables`]);
    },
    [api]
  );

  const deleteAdminCable = useCallback(
    async (cableId: string): Promise<void> => {
      await api.del(`/admin/settings/vertigrip/cables/${cableId}`).then(() => {
        mutateCache([`/admin/settings/vertigrip/cables`]);
      });
    },
    [api]
  );

  return {
    useAdminCables,
    insertAdminCable,
    updateAdminCable,
    deleteAdminCable,
  };
};
