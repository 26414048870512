import { FC, useContext, useState } from "react";
import { Controller } from "react-hook-form";
import NotificationDispatch, {
  showErrorNotification,
  showSuccessNotification,
} from "../../../../../context/notificationContext";
import useI18n from "../../../../../hooks/useTranslations";
import { Cable } from "../../../../../http/types/vertigrip/cables";
import { useAdminCablesApi } from "../../../../../http/vertigrip/useCables";
import Button from "../../../../ui/Button";
import Form from "../../../../ui/Form";
import FormField from "../../../../ui/FormField";
import FormFieldsContainer from "../../../../ui/FormFieldsContainer";
import ModalDialog from "../../../../ui/ModalDialog";
import Numeric from "../../../../ui/Numeric";
import Switch from "../../../../ui/Switch";

interface UpdateCableForm {
  order: number | null;
  isVisible: boolean;
}

interface EditCableModalDialogProps {
  cable?: Cable;
  onClose: () => void;
}

const EditCableModalDialog: FC<EditCableModalDialogProps> = ({ cable, onClose }: EditCableModalDialogProps) => {
  const i18n = useI18n();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useContext(NotificationDispatch);
  const cablesApi = useAdminCablesApi();

  return (
    <ModalDialog isOpen={!!cable} onClose={() => onClose()} title={i18n.translation.cables.edit}>
      <Form<UpdateCableForm>
        defaultValues={{
          order: cable?.order ?? 50,
          isVisible: cable?.isVisible ?? false,
        }}
        submitText={i18n.translation.common.save}
        disabled={isSubmitting}
        secondaryButton={<Button buttonProps={{ onClick: () => onClose() }}>{i18n.translation.common.cancel}</Button>}
        onSubmit={() => (formData) =>
          cablesApi
            .updateAdminCable(cable?.id!, {
              order: formData.order!,
              isVisible: formData.isVisible,
            })
            .then(() => dispatch(showSuccessNotification(i18n.translation)))
            .catch((err) => {
              dispatch(showErrorNotification(i18n, err));
              setIsSubmitting(false);
            })}
      >
        {({ control, setValue, watch }) => {
          const visible = !!watch("isVisible");

          return (
            <FormFieldsContainer fullWidth>
              <FormField label={i18n.translation.common.isVisible}>
                {({ labelId }) => (
                  <Switch id={labelId} checked={visible} onChange={(checked) => setValue("isVisible", checked)} />
                )}
              </FormField>
              <Controller
                name="order"
                control={control}
                rules={{ required: true, min: 1 }}
                render={({ field, fieldState }) => (
                  <FormField label={i18n.translation.common.order} error={fieldState.error}>
                    {({ labelId, isInvalid }) => (
                      <Numeric
                        {...field}
                        id={labelId}
                        isInvalid={isInvalid}
                        decimalScale={0}
                        allowNegative={false}
                        onBlur={(value) => setValue("order", value)}
                      />
                    )}
                  </FormField>
                )}
              />
            </FormFieldsContainer>
          );
        }}
      </Form>
    </ModalDialog>
  );
};

export default EditCableModalDialog;
