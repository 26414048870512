import { Glyph } from "../../types";

const SvgArrowDropDown: Glyph = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" {...props}>
      <path d="M480-384 288-576h384L480-384Z" />
    </svg>
  );
};

export default SvgArrowDropDown;
