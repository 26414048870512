import { FC, Fragment, useContext, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";
import NotificationDispatch, {
  showErrorNotification,
  showSuccessNotification,
} from "../../../../../context/notificationContext";
import { createLoadOptions } from "../../../../../hooks/useDebounce";
import useI18n from "../../../../../hooks/useTranslations";
import { SearchAdminItem } from "../../../../../http/types/items";
import { Handle } from "../../../../../http/types/vertigrip/handles";
import { AdminInstallationType } from "../../../../../http/types/vertigrip/installationTypes";
import { AdminSteelVersion } from "../../../../../http/types/vertigrip/steelVersions";
import { useItemsApi } from "../../../../../http/useItems";
import { useAdminHandlesApi } from "../../../../../http/vertigrip/useHandles";
import { useAdminInstallationTypesApi } from "../../../../../http/vertigrip/useInstallationTypes";
import { useAdminSteelVersionsApi } from "../../../../../http/vertigrip/useSteelVersions";
import { useAdminUpperExtensionsApi } from "../../../../../http/vertigrip/useUpperExtensions";
import { ItemFixingWithQuantity } from "../../../../../types";
import Button from "../../../../ui/Button";
import ControlledNumericWithQuantity from "../../../../ui/ControlledNumericWithQuantity";
import Form from "../../../../ui/Form";
import FormField from "../../../../ui/FormField";
import FormFieldsContainer from "../../../../ui/FormFieldsContainer";
import Input from "../../../../ui/Input";
import Numeric from "../../../../ui/Numeric";
import reactSelectStyles from "../../../../ui/ReactSelectUtils";
import Switch from "../../../../ui/Switch";

interface UpperExtensionForm {
  item: SearchAdminItem | null;
  installationType: AdminInstallationType | null;
  steelVersion: AdminSteelVersion | null;
  handles: Handle[] | null;
  order: number | null;
  ladderExitDifference: number | null;
  fixings: ItemFixingWithQuantity[];
  isVisible: boolean;
}

interface UpperExtensionFormProps {
  installationTypeId?: string;
  steelVersionId?: string;
  upperExtensionId?: string;
  onCancel: () => void;
  onSave?: (itemId: string) => void;
}

const UpperExtensionForm: FC<UpperExtensionFormProps> = ({
  installationTypeId,
  steelVersionId,
  upperExtensionId,
  onCancel,
  onSave,
}: UpperExtensionFormProps) => {
  const i18n = useI18n();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useContext(NotificationDispatch);
  const aborter = useRef(new AbortController());
  const itemsApi = useItemsApi();
  const handlesApi = useAdminHandlesApi();
  const installationTypesApi = useAdminInstallationTypesApi();
  const steelVersionsApi = useAdminSteelVersionsApi();
  const upperExtensionsApi = useAdminUpperExtensionsApi();

  const upperExtensionSwr = upperExtensionsApi.getAdminUpperExtension(upperExtensionId!, aborter.current.signal);

  const installationTypesSwr = installationTypesApi.useAdminInstallationTypes(
    {},
    aborter.current.signal,
    !!installationTypeId
  );
  const steelVersionsSwr = steelVersionsApi.useAdminSteelVersions({}, aborter.current.signal, !!steelVersionId);

  const installationTypeSwr = installationTypesApi.getAdminInstallationType(
    installationTypeId!,
    aborter.current.signal
  );
  const steelVersionSwr = steelVersionsApi.getAdminSteelVersion(steelVersionId!, aborter.current.signal);

  useEffect(() => {
    upperExtensionId && upperExtensionSwr.trigger();
  }, [upperExtensionId]);

  useEffect(() => {
    installationTypeId && installationTypeSwr.trigger();
  }, [installationTypeId]);

  useEffect(() => {
    steelVersionId && steelVersionSwr.trigger();
  }, [steelVersionId]);

  const handlesData = handlesApi.useAdminHandles({ limit: 100 }, aborter.current.signal);

  const steelVersions: AdminSteelVersion[] = (() => {
    const items = steelVersionsSwr.data?.items ?? [];

    if (!upperExtensionSwr.data) {
      return items;
    }

    return !items.find((x) => x.id === upperExtensionSwr.data?.steelVersion.id)
      ? [...items, upperExtensionSwr.data?.steelVersion]
      : items;
  })();

  const installationTypes: AdminInstallationType[] = (() => {
    const items = installationTypesSwr.data?.items ?? [];

    if (!upperExtensionSwr.data) {
      return items;
    }

    return !items.find((x) => x.id === upperExtensionSwr.data?.installationType.id)
      ? [...items, upperExtensionSwr.data?.installationType]
      : items;
  })();

  return (
    <Form<UpperExtensionForm>
      defaultValues={{
        item: null,
        fixings: [],
        order: upperExtensionSwr.data?.order ?? 50,
        ladderExitDifference: upperExtensionSwr.data?.ladderExitDifference,
        isVisible: upperExtensionSwr.data?.isVisible ?? false,
      }}
      submitText={i18n.translation.common.save}
      disabled={isSubmitting}
      secondaryButton={
        <Button
          buttonProps={{
            onClick: onCancel,
          }}
        >
          {i18n.translation.common.cancel}
        </Button>
      }
      onSubmit={() => (formData) => {
        const body = {
          steelVersionId: formData.steelVersion?.id!,
          ladderExitDifference: formData.ladderExitDifference!,
          order: formData.order!,
          handleIds: formData.handles?.map((x) => x.id) ?? [],
          installationTypeId: formData.installationType?.id!,
          fixings: formData.fixings
            .filter((x) => !!x.quantity)
            .map((x) => ({
              wallMaterialId: x.wallMaterial.id,
              quantity: x.quantity ?? 0,
            })),
          isVisible: formData.isVisible,
        };

        if (upperExtensionSwr.data?.id) {
          upperExtensionsApi
            .updateAdminUpperExtension(upperExtensionSwr.data.id, body)
            .then(() => dispatch(showSuccessNotification(i18n.translation)))
            .catch((err) => {
              dispatch(showErrorNotification(i18n, err));
              setIsSubmitting(false);
            });
        } else {
          upperExtensionsApi
            .insertAdminUpperExtension({
              ...body,
              itemId: formData.item?.id!,
            })
            .then((res) => {
              onSave?.(res.id);
              dispatch(showSuccessNotification(i18n.translation));
            })
            .catch((err) => {
              dispatch(showErrorNotification(i18n, err));
              setIsSubmitting(false);
            });
        }
      }}
    >
      {({ control, setValue, watch, formState: { disabled } }) => {
        const visible = !!watch("isVisible");

        useEffect(() => {
          const ids = upperExtensionSwr.data?.handles?.map((x) => x.id) ?? [];
          const handles = handlesData.data?.items.filter((x) => ids.includes(x.id));
          setValue("handles", handles ?? []);
        }, [upperExtensionSwr.data, handlesData.data]);

        useEffect(() => {
          if (upperExtensionSwr.data) {
            setValue("order", upperExtensionSwr.data.order);
            setValue("isVisible", upperExtensionSwr.data.isVisible);
            setValue("ladderExitDifference", upperExtensionSwr.data.ladderExitDifference);
          }
        }, [upperExtensionSwr.data]);

        useEffect(() => {
          if (upperExtensionSwr.data) {
            const fixings: ItemFixingWithQuantity[] =
              (
                upperExtensionSwr.data?.installationType ||
                installationTypesSwr.data?.items.find((x) => x.id === installationTypeId)
              )?.wallMaterials.reduce((rv: ItemFixingWithQuantity[], wm) => {
                const f = upperExtensionSwr.data?.fixings.find((x) => x.wallMaterialId === wm.id);
                return [
                  ...rv,
                  {
                    wallMaterial: wm,
                    quantity: f?.quantity ?? 0,
                  },
                ];
              }, []) ?? [];
            setValue("fixings", fixings);
          }
        }, [upperExtensionSwr.data, installationTypesSwr.data]);

        useEffect(() => {
          const installationType =
            installationTypes.find((x) => {
              return x.id === (upperExtensionSwr.data?.installationType?.id ?? installationTypeId);
            }) ?? null;
          setValue("installationType", installationType);
        }, [installationTypes, upperExtensionSwr.data?.steelVersion.id]);

        useEffect(() => {
          const steelVersion =
            steelVersions.find((x) => x.id === (upperExtensionSwr.data?.steelVersion.id ?? steelVersionId)) ?? null;
          setValue("steelVersion", steelVersion);
        }, [steelVersions, upperExtensionSwr.data?.steelVersion.id]);

        const fixings =
          watch("installationType")?.wallMaterials.map((x, index) => ({
            wallMaterial: x,
            quantity: watch("fixings")[index]?.quantity ?? 0,
          })) ?? [];

        return (
          <FormFieldsContainer fullWidth>
            {!!upperExtensionSwr.data ? (
              <FormField label={i18n.translation.common.description}>
                <Input disabled value={upperExtensionSwr.data?.description} />
              </FormField>
            ) : (
              <Controller
                name="item"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <FormField label={i18n.translation.items.singular} error={fieldState.error}>
                        {({ labelId }) => {
                          const searchTerm = useRef("");
                          const aborter = useRef(new AbortController());

                          const loadOptions = createLoadOptions(aborter, searchTerm, itemsApi.getAdminItems);

                          return (
                            <AsyncSelect
                              {...field}
                              inputId={labelId}
                              loadOptions={loadOptions}
                              isClearable
                              getOptionLabel={(x) => x.code}
                              getOptionValue={(x) => x.id}
                              styles={reactSelectStyles<SearchAdminItem>()}
                              onChange={(value) => setValue("item", value ?? null)}
                            />
                          );
                        }}
                      </FormField>
                    </>
                  );
                }}
              />
            )}
            <Controller
              name="installationType"
              control={control}
              rules={{ required: !installationTypeId }}
              render={({ field, fieldState }) => (
                <FormField
                  label={i18n.translation.installationTypes.singular}
                  error={fieldState.error}
                  hidden={!!installationTypeId}
                >
                  {({ labelId }) => (
                    <ReactSelect
                      {...field}
                      inputId={labelId}
                      options={installationTypes}
                      placeholder={`${i18n.translation.common.select}...`}
                      isLoading={installationTypesSwr.isLoading}
                      getOptionValue={(x) => x.id}
                      getOptionLabel={(x) => x.name}
                      styles={reactSelectStyles<AdminInstallationType>()}
                      onChange={(selectedOption) => setValue("installationType", selectedOption ?? null)}
                    />
                  )}
                </FormField>
              )}
            />
            <Controller
              name="steelVersion"
              control={control}
              rules={{ required: !steelVersionId }}
              render={({ field, fieldState }) => (
                <FormField
                  label={i18n.translation.steelVersions.plural}
                  error={fieldState.error}
                  hidden={!!steelVersionId}
                >
                  {({ labelId }) => (
                    <ReactSelect
                      {...field}
                      inputId={labelId}
                      options={steelVersions}
                      placeholder={`${i18n.translation.common.select}...`}
                      isLoading={steelVersionsSwr.isLoading}
                      getOptionValue={(x) => x.id}
                      getOptionLabel={(x) => x.name}
                      styles={reactSelectStyles<AdminSteelVersion>()}
                      onChange={(selectedOption) => setValue("steelVersion", selectedOption ?? null)}
                    />
                  )}
                </FormField>
              )}
            />
            <Controller
              name="handles"
              control={control}
              render={({ field, fieldState }) => (
                <FormField label={i18n.translation.handles.plural} error={fieldState.error}>
                  {({ labelId }) => (
                    <ReactSelect<Handle, true>
                      {...field}
                      inputId={labelId}
                      isMulti
                      options={handlesData.data?.items ?? []}
                      placeholder={`${i18n.translation.common.select}...`}
                      isLoading={handlesData.isLoading}
                      getOptionValue={(x) => x.id}
                      getOptionLabel={(x) => x.code}
                      styles={reactSelectStyles<Handle, true>()}
                      // onChange={(selectedOption) => setValue("handles", selectedOption ?? null)}
                    />
                  )}
                </FormField>
              )}
            />
            <Controller
              name="ladderExitDifference"
              control={control}
              rules={{ required: true, min: 0 }}
              defaultValue={0}
              render={({ field, fieldState }) => (
                <FormField label={`${i18n.translation.common.ladderExitDifference} [m]`} error={fieldState.error}>
                  {({ labelId, isInvalid }) => (
                    <Numeric
                      {...field}
                      id={labelId}
                      isInvalid={isInvalid}
                      onBlur={(value) => setValue("ladderExitDifference", value ?? null)}
                    />
                  )}
                </FormField>
              )}
            />
            <FormField label={i18n.translation.common.isVisible}>
              {({ labelId }) => (
                <Switch
                  id={labelId}
                  checked={visible}
                  disabled={disabled}
                  onChange={(checked) => setValue("isVisible", checked)}
                />
              )}
            </FormField>
            <Controller
              name="order"
              control={control}
              rules={{ required: true, min: 1 }}
              render={({ field, fieldState }) => (
                <FormField label={i18n.translation.common.order} error={fieldState.error}>
                  {({ labelId, isInvalid }) => (
                    <Numeric
                      {...field}
                      id={labelId}
                      isInvalid={isInvalid}
                      decimalScale={0}
                      allowNegative={false}
                      onBlur={(value) => setValue("order", value)}
                    />
                  )}
                </FormField>
              )}
            />

            {fixings.length > 0 && (
              <FormField label={i18n.translation.common.nrOfFixingItemsRequired}>
                <ControlledNumericWithQuantity.List>
                  {fixings.map((x) => (
                    <Fragment key={x.wallMaterial.id}>
                      <ControlledNumericWithQuantity
                        itemReadonly
                        item={{
                          id: x.wallMaterial.id,
                          display: x.wallMaterial.name,
                          quantity: x.quantity ?? 0,
                          scale: 1,
                        }}
                        onUpdateQuantity={(id, quantity) =>
                          setValue(
                            "fixings",
                            fixings.reduce((rv: ItemFixingWithQuantity[], i) => {
                              if (i.wallMaterial.id === id) {
                                return [...rv, { ...i, quantity: quantity }];
                              }
                              return [...rv, i];
                            }, [])
                          )
                        }
                      />
                    </Fragment>
                  ))}
                </ControlledNumericWithQuantity.List>
              </FormField>
            )}
          </FormFieldsContainer>
        );
      }}
    </Form>
  );
};

export default UpperExtensionForm;
