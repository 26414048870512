import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import styles from "./LoadingSpinner.module.css";

export type Theme = "light" | "dark";

interface LoadingSpinnerProps {
  delayed?: number;
  size?: "small" | "default";
  // theme?: Theme;
  // className?: string;
}

const LoadingSpinner: FC<LoadingSpinnerProps> = ({
  delayed = 125,
  size = "default",
}: // theme = "dark",
// className,
LoadingSpinnerProps) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), delayed);
    return () => clearTimeout(timeout);
  }, [delayed]);

  return <>{show && <div className={classNames(styles.spinner, { [styles.small]: size === "small" })}></div>}</>;
};

export default LoadingSpinner;
