import { I18n } from "../hooks/useTranslations";

export type CustomError = {
  error: {
    status: number;
    message: string;
    error: number | null;
  };
};

// export type GenericError = CustomError & {
//   status: number;
// };

// export type NotUnauthorizedError = CustomError & {
//   status: 401;
// };

// export type NotFoundError = CustomError & {
//   status: 404;
// };

// export type BadRequestError = CustomError & {
//   status: 400;
// };

// export type ConflictError = CustomError & {
//   status: 409;
// };

// export const isGenericError = (error: any): boolean =>
//   error.status && error.status >= 500 && error.status < 600;

// export const isUnauthorized = (error: any): boolean => error?.json?.error?.status === 401; // TODO needed? remove?

export const isForbidden = (error: any): boolean => error?.json?.error?.status === 403 || error?.status === 403;

export const isNotFound = (error: any): boolean => error?.json?.error?.status === 404 || error?.status === 404;

// export const isBadRequest = (error: any): boolean => error?.json?.error?.status === 400;

export const isConflict = (error: any): boolean => error?.json?.error?.status === 409 || error?.status === 409;

export const getErrorMessage = (i18n: I18n, error: any): string => {
  if (error?.json?.error?.code) {
    const msg = i18n.errorsMap.get(error.json.error.code);
    return msg ?? `${i18n.translation.common.genericError} (${error.json.error.code})`;
  }
  return i18n.translation.common.genericError;
};
