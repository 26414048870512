import { FC } from "react";
import useTabs from "../../../../hooks/useTabs";
import useI18n from "../../../../hooks/useTranslations";
import Tabs from "../../../ui/Tabs";
import Cables from "./cables/Cables";
import Handles from "./Handles";
import InstallationTypes from "./installationTypes/InstallationTypes";
import Normatives from "./normatives/Normatives";
import SteelVersions from "./steelVersions/SteelVersions";
import SystemTypes from "./systemTypes/SystemTypes";
import Texts from "./texts/Texts";

const VertigripSettings: FC = () => {
  const i18n = useI18n();

  const tabs = [
    { title: i18n.translation.normatives.plural },
    { title: i18n.translation.handles.plural },
    { title: i18n.translation.cables.plural },

    { title: i18n.translation.installationTypes.plural },
    { title: i18n.translation.steelVersions.plural },
    { title: i18n.translation.systemTypes.plural },

    { title: i18n.translation.texts.plural },
  ];
  const controller = useTabs(tabs.length);

  return (
    <Tabs
      controller={controller}
      tabs={tabs}
      panels={[
        <Normatives />,
        <Handles />,
        <Cables />,
        <InstallationTypes />,
        <SteelVersions />,
        <SystemTypes />,
        <Texts />,
      ]}
    />
  );
};

export default VertigripSettings;
