import classNames from "classnames";
import { ButtonHTMLAttributes, FC, PropsWithChildren } from "react";
import { Glyph } from "../../types";
import styles from "./Button.module.css";
import Icon from "./Icon";

type Props = PropsWithChildren<{
  glyph?: Glyph;
  isLoading?: boolean;
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  type?: "primary" | "secondary";
}>;

const Button: FC<Props> = ({ glyph, buttonProps, children, type = "primary" }: Props) => {
  return (
    <button
      type="button"
      {...buttonProps}
      className={classNames(styles.button, buttonProps?.className, {
        [styles.primary]: type == "primary",
        [styles.secondary]: type == "secondary",
      })}
    >
      {glyph && <Icon className={classNames(styles.icon, { [styles.iconMargin]: !!children })} glyph={glyph} />}
      {children}
    </button>
  );
};

export default Button;
