import { FC, Fragment, ReactNode } from "react";
import { Link } from "react-router-dom";
import useI18n from "../../hooks/useTranslations";
import { CustomError, isForbidden, isNotFound } from "../../http/errors";
import styles from "./Header.module.css";

export interface HeaderPath {
  display: string;
  url?: string;
}

interface HeaderProps {
  title: string;
  path?: HeaderPath | HeaderPath[];
  buttons?: ReactNode | ReactNode[];
  error?: CustomError;
}

const Header: FC<HeaderProps> = ({ title, buttons, path, error }: HeaderProps) => {
  const i18n = useI18n();
  const pathItems = path ? (Array.isArray(path) ? path : [path]) : [];

  const nodes = pathItems?.reduce((acc: JSX.Element[], x, index) => {
    const key = `p_${index}`;
    if (x.display) {
      const node = x.url ? (
        <Link key={key} to={x.url}>
          {x.display}
        </Link>
      ) : (
        <Fragment key={key}>{x.display}</Fragment>
      );

      acc.push(node);
    }

    return acc;
  }, []);

  const result = nodes?.flatMap((x, index) => {
    const key = `s_${index}`;
    return index < nodes.length - 1 ? [x, <Fragment key={key}>{" / "}</Fragment>] : [x];
  });

  const errorText = isNotFound(error)
    ? i18n.translation.common.resourceNotFound
    : isForbidden(error)
    ? i18n.translation.common.youAreNotAllowed
    : error
    ? i18n.translation.common.genericError
    : undefined;

  const titleText = isNotFound(error)
    ? `${i18n.translation.common.notFound}`
    : isForbidden(error)
    ? i18n.translation.common.forbidden
    : error
    ? i18n.translation.common.error
    : title;

  return (
    <div className={styles.header}>
      <div className={styles.heading}>
        <div className={styles.h2}>
          <div className={styles.title}>
            <div className={styles.bar}></div>
            <div>{titleText}</div>
          </div>
        </div>

        {!!result.length && (
          <div className={styles.path}>
            <span>{result}</span>
            <span className={styles.error}>{errorText ? ` / ${errorText}` : ""}</span>
          </div>
        )}
      </div>
      {buttons &&
        (Array.isArray(buttons) ? buttons : [buttons])?.map((b, index) => (
          <Fragment key={`button_${index}`}>{b}</Fragment>
        ))}
    </div>
  );
};

export default Header;
