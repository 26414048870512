import { FC, Fragment, useContext, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";
import NotificationDispatch, {
  showErrorNotification,
  showSuccessNotification,
} from "../../../../../context/notificationContext";
import { createLoadOptions } from "../../../../../hooks/useDebounce";
import useI18n from "../../../../../hooks/useTranslations";
import { SearchAdminItem } from "../../../../../http/types/items";
import { AdminInstallationType } from "../../../../../http/types/vertigrip/installationTypes";
import { AdminSteelVersion } from "../../../../../http/types/vertigrip/steelVersions";
import { useItemsApi } from "../../../../../http/useItems";
import { useAdminInstallationTypesApi } from "../../../../../http/vertigrip/useInstallationTypes";
import { useAdminLowerElementsApi } from "../../../../../http/vertigrip/useLowerElements";
import { useAdminSteelVersionsApi } from "../../../../../http/vertigrip/useSteelVersions";
import { ItemFixingWithQuantity } from "../../../../../types";
import Button from "../../../../ui/Button";
import ControlledNumericWithQuantity from "../../../../ui/ControlledNumericWithQuantity";
import Form from "../../../../ui/Form";
import FormField from "../../../../ui/FormField";
import FormFieldsContainer from "../../../../ui/FormFieldsContainer";
import Input from "../../../../ui/Input";
import Numeric from "../../../../ui/Numeric";
import reactSelectStyles from "../../../../ui/ReactSelectUtils";
import Switch from "../../../../ui/Switch";

interface LowerElementForm {
  item: SearchAdminItem | null;
  steelVersion: AdminSteelVersion | null;
  installationType: AdminInstallationType | null;
  order: number | null;
  fixings: ItemFixingWithQuantity[];
  isVisible: boolean;
}

interface LowerElementFormProps {
  lowerElementId?: string;
  installationTypeId?: string;
  steelVersionId?: string;
  onCancel: () => void;
  onSave?: (itemId: string) => void;
}

const LowerElementForm: FC<LowerElementFormProps> = ({
  lowerElementId,
  installationTypeId,
  steelVersionId,
  onCancel,
  onSave,
}: LowerElementFormProps) => {
  const i18n = useI18n();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useContext(NotificationDispatch);
  const aborter = useRef(new AbortController());
  const itemsApi = useItemsApi();
  const installationTypesApi = useAdminInstallationTypesApi();
  const steelVersionsApi = useAdminSteelVersionsApi();
  const lowerElementsApi = useAdminLowerElementsApi();

  const lowerElementSwr = lowerElementsApi.getAdminLowerElement(lowerElementId!, aborter.current.signal);

  const steelVersionsSwr = steelVersionsApi.useAdminSteelVersions(
    { limit: 100 },
    aborter.current.signal,
    !!steelVersionId
  );
  const installationTypesSwr = installationTypesApi.useAdminInstallationTypes(
    {},
    aborter.current.signal,
    !!installationTypeId
  );

  const steelVersions: AdminSteelVersion[] = (() => {
    const items = steelVersionsSwr.data?.items ?? [];

    if (!lowerElementSwr.data) {
      return items;
    }

    return !items.find((x) => x.id === lowerElementSwr.data?.steelVersion.id)
      ? [...items, lowerElementSwr.data?.steelVersion]
      : items;
  })();

  const installationTypes: AdminInstallationType[] = (() => {
    const items = installationTypesSwr.data?.items ?? [];

    if (!lowerElementSwr.data) {
      return items;
    }

    return !items.find((x) => x.id === lowerElementSwr.data?.installationType.id)
      ? [...items, lowerElementSwr.data?.installationType]
      : items;
  })();

  return (
    <Form<LowerElementForm>
      defaultValues={{
        order: lowerElementSwr.data?.order ?? 50,
        fixings: [],
        isVisible: lowerElementSwr.data?.isVisible ?? false,
      }}
      submitText={i18n.translation.common.save}
      disabled={isSubmitting}
      secondaryButton={
        <Button
          buttonProps={{
            onClick: onCancel,
          }}
        >
          {i18n.translation.common.cancel}
        </Button>
      }
      onSubmit={() => (formData) => {
        const body = {
          steelVersionId: formData.steelVersion?.id!,
          order: formData.order!,
          installationTypeId: formData?.installationType?.id!,
          fixings: formData.fixings
            .filter((x) => !!x.quantity)
            .map((x) => ({
              wallMaterialId: x.wallMaterial.id,
              quantity: x.quantity ?? 0,
            })),
          isVisible: formData.isVisible,
        };
        if (lowerElementSwr.data?.id) {
          lowerElementsApi
            .updateAdminLowerElement(lowerElementSwr.data.id, body)
            .then(() => dispatch(showSuccessNotification(i18n.translation)))
            .catch((err) => {
              dispatch(showErrorNotification(i18n, err));
              setIsSubmitting(false);
            });
        } else {
          lowerElementsApi
            .insertAdminLowerElement({
              ...body,
              itemId: formData.item?.id!,
            })
            .then((res) => {
              onSave?.(res.id);
              dispatch(showSuccessNotification(i18n.translation));
            })
            .catch((err) => {
              dispatch(showErrorNotification(i18n, err));
              setIsSubmitting(false);
            });
        }
      }}
    >
      {({ control, setValue, watch, formState: { disabled } }) => {
        const visible = !!watch("isVisible");

        useEffect(() => {
          lowerElementId && lowerElementSwr.trigger();
        }, [lowerElementId]);

        useEffect(() => {
          const steelVersion =
            steelVersions.find((x) => x.id === (lowerElementSwr.data?.steelVersion.id ?? steelVersionId)) ?? null;
          setValue("steelVersion", steelVersion);
        }, [steelVersions, lowerElementSwr.data?.steelVersion.id]);

        useEffect(() => {
          const installationType =
            installationTypes.find(
              (x) => x.id === (lowerElementSwr.data?.installationType?.id ?? installationTypeId)
            ) ?? null;
          setValue("installationType", installationType);
        }, [installationTypes, lowerElementSwr.data?.steelVersion.id]);

        useEffect(() => {
          if (lowerElementSwr.data) {
            setValue("order", lowerElementSwr.data.order);
            setValue("isVisible", lowerElementSwr.data.isVisible);
          }
        }, [lowerElementSwr.data]);

        const fixings = watch("fixings");

        return (
          <FormFieldsContainer fullWidth>
            {!!lowerElementSwr.data ? (
              <FormField label={i18n.translation.common.description}>
                <Input disabled value={lowerElementSwr.data?.description} />
              </FormField>
            ) : (
              <Controller
                name="item"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <FormField label={i18n.translation.items.singular} error={fieldState.error}>
                        {({ labelId }) => {
                          const searchTerm = useRef("");
                          const aborter = useRef(new AbortController());

                          const loadOptions = createLoadOptions(aborter, searchTerm, itemsApi.getAdminItems);

                          return (
                            <AsyncSelect
                              {...field}
                              inputId={labelId}
                              loadOptions={loadOptions}
                              isClearable
                              getOptionLabel={(x) => x.code}
                              getOptionValue={(x) => x.id}
                              styles={reactSelectStyles<SearchAdminItem>()}
                              onChange={(value) => setValue("item", value ?? null)}
                            />
                          );
                        }}
                      </FormField>
                    </>
                  );
                }}
              />
            )}
            <Controller
              name="steelVersion"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <FormField
                  label={i18n.translation.steelVersions.singular}
                  error={fieldState.error}
                  hidden={!!steelVersionId}
                >
                  {({ labelId }) => (
                    <ReactSelect
                      {...field}
                      inputId={labelId}
                      options={steelVersions}
                      placeholder={`${i18n.translation.common.select}...`}
                      isLoading={steelVersionsSwr.isLoading}
                      isDisabled={!!steelVersionId}
                      getOptionValue={(x) => x.id}
                      getOptionLabel={(x) => x.name}
                      styles={reactSelectStyles<AdminSteelVersion>()}
                      onChange={(selectedOption) => setValue("steelVersion", selectedOption ?? null)}
                    />
                  )}
                </FormField>
              )}
            />
            <Controller
              name="installationType"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <FormField
                  label={i18n.translation.installationTypes.singular}
                  error={fieldState.error}
                  hidden={!!installationTypeId}
                >
                  {({ labelId }) => (
                    <ReactSelect
                      {...field}
                      inputId={labelId}
                      options={installationTypes}
                      placeholder={`${i18n.translation.common.select}...`}
                      isLoading={installationTypesSwr.isLoading}
                      isDisabled={!!installationTypeId}
                      getOptionValue={(x) => x.id}
                      getOptionLabel={(x) => x.name}
                      styles={reactSelectStyles<AdminInstallationType>()}
                      onChange={(selectedOption) => setValue("installationType", selectedOption ?? null)}
                    />
                  )}
                </FormField>
              )}
            />
            <FormField label={i18n.translation.common.isVisible}>
              {({ labelId }) => (
                <Switch
                  id={labelId}
                  checked={visible}
                  disabled={disabled}
                  onChange={(checked) => setValue("isVisible", checked)}
                />
              )}
            </FormField>
            <Controller
              name="order"
              control={control}
              rules={{ required: true, min: 1 }}
              render={({ field, fieldState }) => (
                <FormField label={i18n.translation.common.order} error={fieldState.error}>
                  {({ labelId, isInvalid }) => (
                    <Numeric
                      {...field}
                      id={labelId}
                      isInvalid={isInvalid}
                      decimalScale={0}
                      allowNegative={false}
                      onBlur={(value) => setValue("order", value)}
                    />
                  )}
                </FormField>
              )}
            />

            {fixings.length > 0 && (
              <FormField label={i18n.translation.common.nrOfFixingItemsRequired}>
                <ControlledNumericWithQuantity.List>
                  {fixings.map((x) => (
                    <Fragment key={x.wallMaterial.id}>
                      <ControlledNumericWithQuantity
                        itemReadonly
                        item={{
                          id: x.wallMaterial.id,
                          display: x.wallMaterial.name,
                          quantity: x.quantity ?? 0,
                          scale: 1,
                        }}
                        onUpdateQuantity={(id, quantity) =>
                          setValue(
                            "fixings",
                            fixings.reduce((rv: ItemFixingWithQuantity[], i) => {
                              if (i.wallMaterial.id === id) {
                                return [...rv, { ...i, quantity: quantity }];
                              }
                              return [...rv, i];
                            }, [])
                          )
                        }
                      />
                    </Fragment>
                  ))}
                </ControlledNumericWithQuantity.List>
              </FormField>
            )}
          </FormFieldsContainer>
        );
      }}
    </Form>
  );
};

export default LowerElementForm;
