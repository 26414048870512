import { useCallback } from "react";
import { mutate } from "swr";
import wretch from "wretch";
import QueryStringAddon from "wretch/addons/queryString";
import { useAuth } from "../context/useAuthContext";
import { FetcherOptions, PaginationQuery, PostPolicy, UploadFileResponse } from "../types";

export const mutateCache = (keys: string[], skipKey?: string) => {
  for (const k of keys) {
    mutate(
      (key) => {
        if (typeof key === "string" && key !== skipKey) {
          return key === k || key.toLowerCase().startsWith(`${k}?`);
        }
        return false;
      },
      undefined,
      {
        revalidate: true,
      }
    );
  }
};

export const buildUrl = (url: string, query: PaginationQuery, includeHidden?: boolean): string => {
  const params = new URLSearchParams();
  !!query.limit && params.set("limit", `${query.limit}`);
  !!query.page && params.set("page", `${query.page}`);
  !!query.q && params.set("q", query.q);
  !!includeHidden && params.set("h", "t");

  return url + (params.size > 0 ? `?${params.toString()}` : "");
};

export const useApi = () => {
  const auth = useAuth();

  const handleError = (error: any) => {
    if (error && error.status === 401) {
      // Redirect to the login page
      // window.location.href = "/login"; // Adjust the path as needed
      auth.expire();
    }
    throw error; // Re-throw error for further handling if needed
  };

  const w = wretch().addon(QueryStringAddon).options({ credentials: "include" });

  const api = w.url("/api");

  const get = useCallback(
    <R>({ url, signal }: FetcherOptions) => api.options({ signal }).url(url).get().json<R>().catch(handleError),
    [api]
  );

  const post = useCallback(
    <V, R>(url: string, body?: R) => api.url(url).post(body).json<V>().catch(handleError),
    [api]
  );

  const put = useCallback(<R>(url: string, body: R) => api.url(url).put(body).res().catch(handleError), [api]);

  const del = useCallback((url: string) => api.url(url).delete().res().catch(handleError), [api]);

  const uploadFile = useCallback((policy: PostPolicy, file: File): Promise<UploadFileResponse> => {
    const formData = new FormData();

    for (const field in policy.fields) {
      const value = policy.fields[field];
      value && formData.append(field, value);
    }

    formData.append("file", file);

    const headers = new Headers();
    headers.append("Cache-Control", "private, max-age=0, no-transform");

    return fetch(policy.url, {
      method: "POST",
      headers: headers,
      body: formData,
    })
      .then((res) => res as UploadFileResponse)
      .catch(handleError);
  }, []);

  const createObjectURL = useCallback(
    (url: string, redirectError?: boolean) =>
      w
        .options({ redirect: redirectError ? "error" : undefined })
        .url(url)
        .get()
        .blob()
        .then((blob) => URL.createObjectURL(blob))
        .catch(handleError),
    [w]
  );

  const downloadFile = useCallback(
    (url: string, fileName: string) =>
      createObjectURL(url, true)
        .then((res) => {
          const link = document.createElement("a");
          link.href = res;
          link.setAttribute("download", fileName);

          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
        })
        .catch(handleError),
    [createObjectURL]
  );

  const downloadExcel = useCallback(
    (url: string, fileName: string) =>
      api
        .headers({ Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
        .url(url)
        .get()
        .blob()
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);

          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
        })
        .catch(handleError),
    [api]
  );

  return {
    get,
    post,
    put,
    del,
    uploadFile,
    createObjectURL,
    downloadFile,
    downloadExcel,
  };
};
