import { FC, useContext, useState } from "react";
import { Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import NotificationDispatch, { showErrorNotification } from "../../../../../context/notificationContext";
import useI18n from "../../../../../hooks/useTranslations";
import { InsertNormativeBody } from "../../../../../http/types/vertigrip/normatives";
import { useAdminNormativesApi } from "../../../../../http/vertigrip/useNormatives";
import Form from "../../../../ui/Form";
import FormField from "../../../../ui/FormField";
import FormFieldsContainer from "../../../../ui/FormFieldsContainer";
import Input from "../../../../ui/Input";
import Numeric from "../../../../ui/Numeric";
import Switch from "../../../../ui/Switch";
import Textarea from "../../../../ui/Textarea";

interface InsertNormativeForm {
  name: string | null;
  order: number | null;
  isVisible: boolean;
  description: string | null;
}

const NewNormative: FC = () => {
  const { id: systemId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const i18n = useI18n();
  const dispatch = useContext(NotificationDispatch);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const normativesApi = useAdminNormativesApi();

  const onSubmit = (formData: InsertNormativeForm) => {
    const body: InsertNormativeBody = {
      name: formData.name!,
      nameDe: null,
      nameEs: null,
      nameFr: null,
      nameIt: null,
      order: formData.order!,
      description: formData.description!,
      descriptionDe: null,
      descriptionEs: null,
      descriptionFr: null,
      descriptionIt: null,
      isVisible: formData.isVisible,
    };

    setIsSubmitting(true);
    normativesApi
      .insertAdminNormative(body)
      .then((res) => {
        setIsSubmitting(false);
        navigate(`/admin/systems/${systemId}/normatives/${res.id}`);
      })
      .catch((err) => {
        dispatch(showErrorNotification(i18n, err));
        setIsSubmitting(false);
      });
  };

  return (
    <Form<InsertNormativeForm>
      defaultValues={{
        name: null,
        description: null,
        order: 50,
        isVisible: false,
      }}
      submitText={i18n.translation.common.save}
      disabled={isSubmitting}
      onSubmit={() => onSubmit}
    >
      {({ register, formState: { errors }, control, setValue, watch }) => {
        const visible = !!watch("isVisible");

        return (
          <FormFieldsContainer fullWidth>
            <FormField label={i18n.translation.common.name} error={errors.name}>
              {({ labelId, isInvalid, isOptional }) => (
                <Input
                  id={labelId}
                  isInvalid={isInvalid}
                  disabled={isSubmitting}
                  {...register("name", {
                    required: !isOptional,
                    minLength: 1,
                  })}
                />
              )}
            </FormField>
            <FormField label={i18n.translation.common.description} error={errors.description}>
              {({ labelId, isOptional, isInvalid }) => (
                <Textarea
                  id={labelId}
                  isInvalid={isInvalid}
                  disabled={isSubmitting}
                  {...register("description", {
                    required: !isOptional,
                    minLength: 1,
                  })}
                />
              )}
            </FormField>
            <FormField label={i18n.translation.common.isVisible}>
              {({ labelId }) => (
                <Switch id={labelId} checked={visible} onChange={(checked) => setValue("isVisible", checked)} />
              )}
            </FormField>
            <Controller
              name="order"
              control={control}
              rules={{ required: true, min: 1 }}
              disabled={isSubmitting}
              render={({ field, fieldState }) => (
                <FormField label={i18n.translation.common.order} error={fieldState.error}>
                  {({ labelId, isInvalid }) => (
                    <Numeric
                      {...field}
                      id={labelId}
                      isInvalid={isInvalid}
                      decimalScale={0}
                      allowNegative={false}
                      onBlur={(value) => setValue("order", value)}
                    />
                  )}
                </FormField>
              )}
            />
          </FormFieldsContainer>
        );
      }}
    </Form>
  );
};

export default NewNormative;
