import { CSSObjectWithLabel, ControlProps, GroupBase, OptionProps, SingleValueProps, StylesConfig } from "react-select";
import { Translation } from "../../hooks/useTranslations";
import { formatBoolean } from "../../utils";

const reactSelectStyles = <T, U extends boolean = false>(isInvalid?: boolean): StylesConfig<T, U, GroupBase<T>> => ({
  container: (base: CSSObjectWithLabel) => ({
    ...base,
    flex: 1,
  }),
  control: (base: CSSObjectWithLabel, state: ControlProps<T, U, GroupBase<T>>) => ({
    ...base,
    cursor: state.isDisabled ? "not-allowed" : "pointer",
    backgroundColor: state.isDisabled ? "var(--grey-10)" : undefined,
    borderRadius: 0,
    border: isInvalid ? "1px solid var(--red)" : "1px solid var(--grey-15)",
    fontWeight: 300,
    boxShadow: "0px 0px 0px ",
    ":hover": {
      ...base[":hover"],
      border: "1px solid var(--grey-40);",
      boxShadow: "0px 0px 0px ",
    },
  }),
  singleValue: (base: CSSObjectWithLabel, state: SingleValueProps<T, U, GroupBase<T>>) => ({
    ...base,
    color: state.isDisabled ? "var(--grey-60)" : "var(--text)",
  }),
  option: (base: CSSObjectWithLabel, state: OptionProps<T, U, GroupBase<T>>) => ({
    ...base,
    backgroundColor: state.isSelected ? "var(--grey-15)" : "white",
    color: "var(--text)",
    fontWeight: 300,
    ":hover": {
      ...base[":hover"],
      backgroundColor: state.isSelected ? "var(--grey-15)" : "var(--grey-10)",
    },
  }),
  menu: (base: CSSObjectWithLabel) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
    marginBottom: 0,
  }),
  menuList: (base: CSSObjectWithLabel) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  multiValue: (base: CSSObjectWithLabel) => ({
    ...base,
    borderRadius: 0,
    backgroundColor: "var(--grey-20)",
  }),
});

export interface BooleanSelectValue {
  value: boolean;
  label: string;
}

export const getBooleanValues = (translations: Translation): BooleanSelectValue[] => [
  { value: true, label: formatBoolean(translations, true) },
  { value: false, label: formatBoolean(translations, false) },
];

export default reactSelectStyles;
