import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";
import { Paginated, PaginationQuery } from "../../types";
import { buildUrl, mutateCache, useApi } from "../api";
import { CustomError } from "../errors";
import { Absorber, InsertAbsorberBody, UpdateAbsorberBody } from "../types/vertigrip/absorbers";

export const useAdminAbsorbersApi = () => {
  const api = useApi();

  const useAdminSteelVersionAbsorbers = useCallback(
    (steelVersionId: string, query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl(`/admin/settings/vertigrip/steel_versions/${steelVersionId}/absorbers`, query);
      return useSWRImmutable<Paginated<Absorber>, CustomError, string>(url, (url) =>
        api.get<Paginated<Absorber>>({ url, signal })
      );
    },
    [api]
  );

  const insertAdminAbsorber = useCallback(
    async (body: InsertAbsorberBody): Promise<{ id: string }> => {
      const url = `/admin/settings/vertigrip/absorbers`;
      const id = await api.post<{ id: string }, InsertAbsorberBody>(url, body);
      mutateCache([url, `/admin/settings/vertigrip/steel_versions/${body.steelVersionId}/absorbers`]);
      return id;
    },
    [api]
  );

  const updateAdminAbsorber = useCallback(
    async (absorberId: string, body: UpdateAbsorberBody): Promise<void> => {
      const url = `/admin/settings/vertigrip/absorbers/${absorberId}`;
      await api.put<UpdateAbsorberBody>(url, body);
      mutateCache([
        `/admin/settings/vertigrip/absorbers`,
        `/admin/settings/vertigrip/steel_versions/${body.steelVersionId}/absorbers`,
      ]);
    },
    [api]
  );

  const deleteAdminAbsorber = useCallback(
    async (absorberId: string, steelVersionId: string): Promise<void> => {
      await api.del(`/admin/settings/vertigrip/absorbers/${absorberId}`).then(() => {
        mutateCache([
          `/admin/settings/vertigrip/absorbers`,
          `/admin/settings/vertigrip/absorbers/${absorberId}`,
          `/admin/settings/vertigrip/steel_versions/${steelVersionId}/absorbers`,
          `/admin/settings/vertigrip/steel_versions/${steelVersionId}/absorbers/${absorberId}`,
        ]);
      });
    },
    [api]
  );

  return {
    useAdminSteelVersionAbsorbers,
    insertAdminAbsorber,
    updateAdminAbsorber,
    deleteAdminAbsorber,
  };
};
