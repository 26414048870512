import { FC, useContext, useState } from "react";
import NotificationDispatch, {
  showErrorNotification,
  showSuccessNotification,
} from "../../../../context/notificationContext";
import useI18n from "../../../../hooks/useTranslations";
import { Handle } from "../../../../http/types/vertigrip/handles";
import { useAdminHandlesApi } from "../../../../http/vertigrip/useHandles";
import Button from "../../../ui/Button";
import Form from "../../../ui/Form";
import FormField from "../../../ui/FormField";
import FormFieldsContainer from "../../../ui/FormFieldsContainer";
import ModalDialog from "../../../ui/ModalDialog";
import Switch from "../../../ui/Switch";

interface UpdateHandleForm {
  isVisible: boolean;
}

interface EditHandleModalDialogProps {
  handle?: Handle;
  onClose: () => void;
}

const EditHandleModalDialog: FC<EditHandleModalDialogProps> = ({ handle, onClose }: EditHandleModalDialogProps) => {
  const i18n = useI18n();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useContext(NotificationDispatch);
  const handlesApi = useAdminHandlesApi();

  return (
    <ModalDialog isOpen={!!handle} onClose={() => onClose()} title={i18n.translation.handles.edit}>
      <Form<UpdateHandleForm>
        defaultValues={{
          isVisible: handle?.isVisible,
        }}
        submitText={i18n.translation.common.save}
        disabled={isSubmitting}
        secondaryButton={<Button buttonProps={{ onClick: () => onClose() }}>{i18n.translation.common.cancel}</Button>}
        onSubmit={() => (formData) =>
          handlesApi
            .updateAdminHandle(handle?.id!, {
              isVisible: formData.isVisible,
            })
            .then(() => dispatch(showSuccessNotification(i18n.translation)))
            .catch((err) => {
              dispatch(showErrorNotification(i18n, err));
              setIsSubmitting(false);
            })}
      >
        {({ setValue, watch }) => {
          const visible = !!watch("isVisible");

          return (
            <FormFieldsContainer fullWidth>
              <FormField label={i18n.translation.common.isVisible}>
                {({ labelId }) => (
                  <Switch
                    id={labelId}
                    checked={visible}
                    disabled={!handle}
                    onChange={(checked) => setValue("isVisible", checked)}
                  />
                )}
              </FormField>
            </FormFieldsContainer>
          );
        }}
      </Form>
    </ModalDialog>
  );
};

export default EditHandleModalDialog;
