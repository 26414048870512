import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";
import { Paginated, PaginationQuery } from "../../types";
import { buildUrl, mutateCache, useApi } from "../api";
import { CustomError } from "../errors";
import {
  InsertLadderReinforcementBody,
  LadderReinforcement,
  UpdateLadderReinforcementBody,
} from "../types/vertigrip/ladderReinforcements";

export const useAdminLadderReinforcementsApi = () => {
  const api = useApi();

  const useAdminInstallationTypeLadderReinforcements = useCallback(
    (installationTypeId: string, query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl(
        `/admin/settings/vertigrip/installation_types/${installationTypeId}/ladder_reinforcements`,
        query
      );
      return useSWRImmutable<Paginated<LadderReinforcement>, CustomError, string>(url, (url) =>
        api.get<Paginated<LadderReinforcement>>({ url, signal })
      );
    },
    [api]
  );

  const insertAdminLadderReinforcement = useCallback(
    async (body: InsertLadderReinforcementBody): Promise<{ id: string }> => {
      const url = `/admin/settings/vertigrip/ladder_reinforcements`;
      const id = await api.post<{ id: string }, InsertLadderReinforcementBody>(url, body);
      mutateCache([
        url,
        `/admin/settings/vertigrip/installation_types/${body.installationTypeId}/ladder_reinforcements`,
      ]);
      return id;
    },
    [api]
  );

  const updateAdminLadderReinforcement = useCallback(
    async (ladderReinforcementId: string, body: UpdateLadderReinforcementBody): Promise<void> => {
      const url = `/admin/settings/vertigrip/ladder_reinforcements/${ladderReinforcementId}`;
      await api.put<UpdateLadderReinforcementBody>(url, body),
        mutateCache([
          `/admin/settings/vertigrip/ladder_reinforcements`,
          `/admin/settings/vertigrip/installation_types/${body.installationTypeId}/ladder_reinforcements`,
        ]);
    },
    [api]
  );

  const deleteAdminLadderReinforcement = useCallback(
    async (ladderReinforcementId: string, installationTypeId: string): Promise<void> => {
      const url = `/admin/settings/vertigrip/ladder_reinforcements/${ladderReinforcementId}`;
      await api.del(url).then(() => {
        mutateCache([
          `/admin/settings/vertigrip/ladder_reinforcements`,
          `/admin/settings/vertigrip/installation_types/${installationTypeId}/ladder_reinforcements`,
          `/admin/settings/vertigrip/installation_types/${installationTypeId}/ladder_reinforcements/${ladderReinforcementId}`,
        ]);
      });
    },
    [api]
  );

  return {
    useAdminInstallationTypeLadderReinforcements,
    insertAdminLadderReinforcement,
    updateAdminLadderReinforcement,
    deleteAdminLadderReinforcement,
  };
};
