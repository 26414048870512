import { FC, useContext, useEffect, useState } from "react";
import NotificationDispatch, {
  showErrorNotification,
  showSuccessNotification,
} from "../../../../../context/notificationContext";
import useTabs from "../../../../../hooks/useTabs";
import useI18n from "../../../../../hooks/useTranslations";
import { useApi } from "../../../../../http/api";
import {
  AdminText,
  UpdateTextNewImageBody,
  UpdateTextRemoveImageBody,
} from "../../../../../http/types/vertigrip/texts";
import { useAdminTextsApi } from "../../../../../http/vertigrip/useTexts";
import { getUILanguages } from "../../../../../types";
import Form from "../../../../ui/Form";
import FormField from "../../../../ui/FormField";
import FormFieldsContainer from "../../../../ui/FormFieldsContainer";
import useImageUploader from "../../../../ui/ImageUploader";
import Input from "../../../../ui/Input";
import ScrollContent from "../../../../ui/ScrollContent";
import Tabs from "../../../../ui/Tabs";
import Textarea from "../../../../ui/Textarea";

interface UpdateTextForm {
  value: string | null;
  valueIt: string | null;
  valueDe: string | null;
  valueEs: string | null;
  valueFr: string | null;
}

interface TextDetailsProps {
  text?: AdminText;
  disabled?: boolean;
}

const TextDetails: FC<TextDetailsProps> = ({ text, disabled }: TextDetailsProps) => {
  const i18n = useI18n();
  const api = useApi();
  const dispatch = useContext(NotificationDispatch);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const textsApi = useAdminTextsApi();

  const languages = getUILanguages(i18n.translation);
  const controller = useTabs(languages.length);
  const [tabs, setTabs] = useState(languages.map((x) => ({ title: x.display, value: x.value })));

  const { component, newImage, removeImage, onSave } = useImageUploader({ defaultImage: text?.image, disabled });

  const defaultValues: UpdateTextForm = {
    value: text?.value ?? null,
    valueIt: text?.valueIt ?? null,
    valueDe: text?.valueDe ?? null,
    valueEs: text?.valueEs ?? null,
    valueFr: text?.valueFr ?? null,
  };

  useEffect(() => {
    setTabs(languages.map((x) => ({ title: x.display, value: x.value })));
  }, [i18n.translation]);

  const submit = (body: UpdateTextNewImageBody | UpdateTextRemoveImageBody) => {
    textsApi
      .updateAdminText(text?.id!, body)
      .then(() => {
        setIsSubmitting(false);
        dispatch(showSuccessNotification(i18n.translation));
        onSave();
      })
      .catch((err) => {
        dispatch(showErrorNotification(i18n, err));
        setIsSubmitting(false);
      });
  };

  const onSubmit = (formData: UpdateTextForm) => {
    setIsSubmitting(true);

    const commonBody: UpdateTextNewImageBody | UpdateTextRemoveImageBody = {
      value: formData.value?.trim()!,
      valueIt: formData.valueIt?.trim() ?? null,
      valueDe: formData.valueDe?.trim() ?? null,
      valueEs: formData.valueEs?.trim() ?? null,
      valueFr: formData.valueFr?.trim() ?? null,
    };

    if (newImage) {
      textsApi
        .generateUploadImageUrl(text?.id!, newImage.name)
        .then((uploadPolicy) => {
          api
            .uploadFile(uploadPolicy, newImage)
            .then((res) => {
              if (!res.ok) {
                dispatch(showErrorNotification(i18n, res));
                setIsSubmitting(false);
                return;
              }
              const body: UpdateTextNewImageBody = {
                ...commonBody,
                image: newImage.name,
              };
              submit(body);
            })
            .catch((err) => {
              dispatch(showErrorNotification(i18n, err));
              setIsSubmitting(false);
            });
        })
        .catch((err) => {
          dispatch(showErrorNotification(i18n, err));
          setIsSubmitting(false);
        });
    } else {
      const body: UpdateTextRemoveImageBody = {
        ...commonBody,
        removeImage: removeImage,
      };
      submit(body);
    }
  };

  const panel = (
    <ScrollContent direction="row">
      <Form<UpdateTextForm>
        defaultValues={defaultValues}
        submitText={i18n.translation.common.save}
        disabled={isSubmitting || disabled}
        onSubmit={() => onSubmit}
      >
        {({ register, formState: { errors }, reset }) => {
          useEffect(() => {
            reset(defaultValues);
          }, [text]);

          return (
            <FormFieldsContainer imageUploader={component} tabbed>
              <FormField label="Id">
                <Input disabled={true} defaultValue={text?.id} />
              </FormField>
              <FormField
                label={i18n.translation.common.value}
                error={errors.value}
                hidden={controller.selectedIndex != 0}
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("value", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return <Textarea id={labelId} isInvalid={isInvalid} disabled={disabled} {...options} />;
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.value}
                error={errors.valueIt}
                hidden={controller.selectedIndex != 1}
                isOptional
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("valueIt", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return <Textarea id={labelId} isInvalid={isInvalid} disabled={disabled} {...options} />;
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.value}
                error={errors.valueDe}
                hidden={controller.selectedIndex != 2}
                isOptional
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("valueDe", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return <Textarea id={labelId} isInvalid={isInvalid} disabled={disabled} {...options} />;
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.value}
                error={errors.valueEs}
                hidden={controller.selectedIndex != 3}
                isOptional
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("valueEs", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return <Textarea id={labelId} isInvalid={isInvalid} disabled={disabled} {...options} />;
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.value}
                error={errors.valueFr}
                hidden={controller.selectedIndex != 4}
                isOptional
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("valueFr", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return <Textarea id={labelId} isInvalid={isInvalid} disabled={disabled} {...options} />;
                }}
              </FormField>
            </FormFieldsContainer>
          );
        }}
      </Form>
    </ScrollContent>
  );

  return <Tabs controller={controller} tabs={tabs} panels={languages.map(() => panel)} />;
};

export default TextDetails;
