import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";
import useSWRMutation from "swr/mutation";
import { Paginated, PaginationQuery, PostPolicy } from "../../types";
import { buildUrl, mutateCache, useApi } from "../api";
import { CustomError } from "../errors";
import {
  AdminSteelVersion,
  InsertSteelVersionBody,
  UpdateSteelVersionNewImageBody,
  UpdateSteelVersionRemoveImageBody,
} from "../types/vertigrip/steelVersions";

export const useAdminSteelVersionsApi = () => {
  const api = useApi();

  const getAdminSteelVersion = useCallback(
    (id: string, signal: AbortSignal) => {
      const url = `/admin/settings/vertigrip/steel_versions/${id}`;
      return useSWRMutation<AdminSteelVersion, CustomError, string>(url, (url) =>
        api.get<AdminSteelVersion>({ url, signal })
      );
    },
    [api]
  );

  const useAdminSteelVersions = useCallback(
    (query: PaginationQuery, signal: AbortSignal, includeHidden?: boolean) => {
      const url = buildUrl("/admin/settings/vertigrip/steel_versions", query, includeHidden);
      return useSWRImmutable<Paginated<AdminSteelVersion>, CustomError, string>(url, (url) =>
        api.get<Paginated<AdminSteelVersion>>({ url, signal })
      );
    },
    [api]
  );

  const insertAdminSteelVersion = useCallback(
    async (body: InsertSteelVersionBody): Promise<{ id: string }> => {
      const url = `/admin/settings/vertigrip/steel_versions`;
      const id = await api.post<{ id: string }, InsertSteelVersionBody>(url, body);
      mutateCache([url]);
      return id;
    },
    [api]
  );

  const updateAdminSteelVersion = useCallback(
    async (
      steelVersionId: string,
      body: UpdateSteelVersionRemoveImageBody | UpdateSteelVersionNewImageBody
    ): Promise<void> => {
      const url = `/admin/settings/vertigrip/steel_versions/${steelVersionId}`;
      await api.put<UpdateSteelVersionRemoveImageBody | UpdateSteelVersionNewImageBody>(url, body),
        mutateCache([`/admin/settings/vertigrip/steel_versions`]);
    },
    [api]
  );

  const deleteAdminSteelVersion = useCallback(
    async (steelVersionId: string): Promise<void> => {
      const url = `/admin/settings/vertigrip/steel_versions/${steelVersionId}`;
      await api.del(url).then(() => {
        mutateCache([`/admin/settings/vertigrip/steel_versions`]);
      });
    },
    [api]
  );

  const generateUploadImageUrl = useCallback(
    async (installationTypeId: string, fileName: string): Promise<PostPolicy> => {
      const encodedFileName = encodeURIComponent(fileName);
      const url = `/admin/settings/vertigrip/steel_versions/${installationTypeId}/image/generate_upload_url?file_name=${encodedFileName}`;
      return api.get<PostPolicy>({ url });
    },
    [api]
  );

  return {
    getAdminSteelVersion,
    useAdminSteelVersions,
    insertAdminSteelVersion,
    updateAdminSteelVersion,
    deleteAdminSteelVersion,
    generateUploadImageUrl,
  };
};
