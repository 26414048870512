import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import SvgMoreVert from "../icons/MoreVert";
import Icon from "./Icon";
import styles from "./ListItemMenu.module.css";

interface ListItemMenuProps<T> {
  actions: {
    title: string;
    onClick: (item: T) => void;
    isDisabled?: (item: T) => boolean;
  }[];
  item: T;
}

const ListItemMenu = <T,>({ actions, item }: ListItemMenuProps<T>) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const menuRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(menuRef, () => setModalOpen(false));

  const handler = (event: MouseEvent) => {
    menuRef.current && event.target && !menuRef.current?.contains(event.target as Node) && setModalOpen(false);
  };

  useEffect(() => {
    document.addEventListener("click", handler);

    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  return (
    <div className={styles.container} ref={menuRef}>
      <Icon glyph={SvgMoreVert} onClick={() => setModalOpen(true)} className={styles.icon} />
      {isModalOpen && (
        <div className={styles.menu}>
          <ul className={styles.list}>
            {actions.map((x, index) => (
              <li
                key={index}
                className={classNames({ [styles.disabled]: x.isDisabled?.(item) })}
                onClick={() => {
                  setModalOpen(false);
                  x.onClick(item);
                }}
              >
                {x.title}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ListItemMenu;
