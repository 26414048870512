import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export interface TabsController {
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
  previous: () => void;
  next: () => void;
}

const useTabs = (count: number, urlParameterName?: string): TabsController => {
  const tab = urlParameterName ?? "tab";

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedIndex, setSelectedIndex] = useState(Number(searchParams.get(tab)) ?? 0);

  const previous = () => selectedIndex > 0 && setSelectedIndex(selectedIndex - 1);
  const next = () => selectedIndex < count - 1 && setSelectedIndex(selectedIndex + 1);

  useEffect(() => {
    searchParams.set(tab, `${selectedIndex}`);
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  }, [selectedIndex]);

  return { selectedIndex, setSelectedIndex, previous, next };
};

export default useTabs;
