import classNames from "classnames";

import { FC, Fragment, PropsWithChildren } from "react";
import { TabsController } from "../../hooks/useTabs";
import styles from "./Tabs.module.css";

interface TabsProps {
  controller: TabsController;
  tabs: {
    title: string;
    onClick?: () => void;
    disabled?: boolean;
    hasError?: boolean;
  }[];
  panels: JSX.Element[];
  hasSubTabs?: boolean;
}

const Tabs: FC<TabsProps> = ({ tabs, panels, controller, hasSubTabs }: TabsProps) => {
  const selected = controller.selectedIndex > tabs.length - 1 ? 0 : controller.selectedIndex;

  return (
    <>
      <ul className={classNames(styles.ul, { [styles.hasSubTabs]: hasSubTabs })}>
        {tabs.map((tab, tabIndex) => (
          <Fragment key={`tab_${tabIndex}`}>
            <Tab
              {...controller}
              selected={selected == tabIndex}
              error={tab.hasError}
              onClick={() => {
                tab.onClick ? tab.onClick() : controller.setSelectedIndex(tabIndex);
              }}
              disabled={tab.disabled}
              hasSubTabs={hasSubTabs}
            >
              {tab.title}
            </Tab>
          </Fragment>
        ))}
      </ul>
      {panels.find((_, i) => selected === i)}
    </>
  );
};

export default Tabs;

export type TabProps = PropsWithChildren<{
  selected: boolean;
  onClick: () => void;
  disabled?: boolean;
  error?: boolean;
  hasSubTabs?: boolean;
}>;

const Tab: FC<TabProps> = (props: TabProps) => {
  const { children, disabled, selected, onClick, error, hasSubTabs } = {
    ...props,
  };

  return (
    <li
      className={classNames(styles.li, {
        [styles.active]: selected,
        [styles.disabled]: disabled,
        [styles.error]: error,
        [styles.noSubTabs]: !hasSubTabs,
      })}
      onClick={() => !disabled && onClick()}
    >
      {children}
    </li>
  );
};
