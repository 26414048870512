import classNames from "classnames";

import { FC, PropsWithChildren, ReactNode } from "react";
import styles from "./FormFieldsContainer.module.css";

type FormFieldsContainerProps = PropsWithChildren<{
  tabbed?: boolean;
  imageUploader?: ReactNode;
  fullWidth?: boolean;
}>;

const FormFieldsContainer: FC<FormFieldsContainerProps> = ({
  children,
  imageUploader,
  tabbed,
  fullWidth = false,
}: FormFieldsContainerProps) => {
  return (
    <div className={styles.container}>
      <div className={classNames(styles.form, { [styles.tabbed]: tabbed, [styles.fullWidth]: fullWidth })}>
        {children}
      </div>
      {!!imageUploader && (
        <div className={classNames(styles.imageUploader, { [styles.tabbed]: tabbed })}>{imageUploader}</div>
      )}
    </div>
  );
};

export default FormFieldsContainer;
