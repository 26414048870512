import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";
import useSWRMutation from "swr/mutation";
import { Paginated, PaginationQuery, PostPolicy } from "../types";
import { buildUrl, mutateCache, useApi } from "./api";
import { CustomError } from "./errors";
import {
  AdminItem,
  InsertAutomaticSyncItemsBody,
  InsertManualItemsBody,
  Item,
  ListItemWithQuantity,
  SearchAdminItem,
  UpdateAutomaticSyncItemsNewImageBody,
  UpdateAutomaticSyncItemsRemoveImageBody,
  UpdateManualItemsNewImageBody,
  UpdateManualItemsRemoveImageBody,
} from "./types/items";

export const useItemsApi = () => {
  const api = useApi();

  const getItem = useCallback(
    (id: string, signal: AbortSignal) => {
      return useSWRImmutable<Item, CustomError, string>(`/items/${id}`, (url) => api.get<Item>({ url, signal }));
    },
    [api]
  );

  const getAdminItem = useCallback(
    (id: string, signal: AbortSignal) => {
      return useSWRMutation<AdminItem, CustomError, string>(`/admin/items/${id}`, (url) =>
        api.get<AdminItem>({ url, signal })
      );
    },
    [api]
  );

  const useAdminItems = useCallback(
    (query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl("/admin/items", query);
      return useSWRImmutable<Paginated<SearchAdminItem>, CustomError, string>(url, (url) =>
        api.get<Paginated<SearchAdminItem>>({ url, signal })
      );
    },
    [api]
  );

  const getAdminItems = useCallback(
    async (query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl("/admin/items", query);
      return api.get<Paginated<SearchAdminItem>>({ url, signal }).then((x) => x.items);
    },
    [api]
  );

  const insertAdminItem = useCallback(
    async (body: InsertManualItemsBody | InsertAutomaticSyncItemsBody): Promise<{ id: string }> => {
      const url = `/admin/items`;
      const id = await api.post<{ id: string }, InsertManualItemsBody | InsertAutomaticSyncItemsBody>(url, body);
      mutateCache(["/items", url]);
      return id;
    },
    [api]
  );

  const updateAdminItem = useCallback(
    async (
      itemId: string,
      body:
        | UpdateManualItemsRemoveImageBody
        | UpdateManualItemsNewImageBody
        | UpdateAutomaticSyncItemsRemoveImageBody
        | UpdateAutomaticSyncItemsNewImageBody
    ): Promise<void> => {
      const url = `/admin/items/${itemId}`;
      await api.put<
        | UpdateManualItemsRemoveImageBody
        | UpdateManualItemsNewImageBody
        | UpdateAutomaticSyncItemsRemoveImageBody
        | UpdateAutomaticSyncItemsNewImageBody
      >(url, body);
      mutateCache(["/items", "/admin/items"], url);
    },
    [api]
  );

  const deleteAdminItem = useCallback(
    async (itemId: string): Promise<void> => {
      await api.del(`/admin/items/${itemId}`).then(() => {
        mutateCache(["/items", "/admin/items"]);
      });
    },
    [api]
  );

  const getConfigurationItemsList = useCallback(
    (configurationId: string, signal: AbortSignal) => {
      const url = `/configurations/${configurationId}/items`;
      return useSWRImmutable<ListItemWithQuantity[], CustomError, string>(url, (url) =>
        api.get<ListItemWithQuantity[]>({ url, signal })
      );
    },
    [api]
  );

  const downloadEServiceExportConfigurationItemsList = useCallback(
    async (configurationId: string) => {
      return api.downloadExcel(`/configurations/${configurationId}/items`, "E-Service import.xlsx");
    },
    [api]
  );

  const generateUploadImageUrl = useCallback(
    async (itemId: string, fileName: string): Promise<PostPolicy> => {
      const encodedFileName = encodeURIComponent(fileName);
      const url = `/admin/items/${itemId}/image/generate_upload_url?file_name=${encodedFileName}`;
      return api.get<PostPolicy>({ url });
    },
    [api]
  );

  const syncAdminItem = useCallback(
    async (itemId: string): Promise<AdminItem> => {
      return await api.post<AdminItem, void>(`/admin/items/${itemId}/sync`).then((res) => {
        mutateCache(["/items", "/admin/items"]);
        return res;
      });
    },
    [api]
  );

  return {
    getItem,
    getAdminItem,
    useAdminItems,
    getAdminItems,
    insertAdminItem,
    updateAdminItem,
    deleteAdminItem,
    getConfigurationItemsList,
    downloadEServiceExportConfigurationItemsList,
    generateUploadImageUrl,
    syncAdminItem,
  };
};
