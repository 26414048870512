import classNames from "classnames";
import { forwardRef, useId, useRef } from "react";
import { InputAttributes, NumberFormatValues, NumericFormat, NumericFormatProps } from "react-number-format";
import useI18n from "../../hooks/useTranslations";
import SvgArrowDropDown from "../icons/ArrowDropDown";
import SvgArrowDropUp from "../icons/ArrowDropUp";
import Icon from "./Icon";
import inputStyles from "./Input.module.css";
import styles from "./Numeric.module.css";

export type Props = Omit<NumericFormatProps<InputAttributes>, "onChange" | "onValueChange" | "onBlur"> & {
  isInvalid?: boolean;
  controls?: {
    scale: number;
    onUpdate: (value: number) => void;
  };
  min?: number;
  width?: `${number}px`;
  onBlur?: (value: number | null) => void;
  // onValueChange?: (value?: number) => void;
};

const powerOfDecimals = (numStr: string): number => {
  const d = numStr.split(".")[1];
  return d ? Math.pow(10, d.length) : 1;
};

const Numeric = forwardRef<HTMLInputElement, Props>(({ isInvalid, controls, min, width, ...rest }, ref) => {
  const { autoComplete = "off", spellCheck = "false", id = useId() } = rest;
  const i18n = useI18n();
  const click = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: number) => {
    e.preventDefault();
    const factor = powerOfDecimals(`${rest.value}`) * 10;
    const newValue = Math.round((Number(rest.value) + value) * factor) / factor;
    (min === undefined || newValue >= min) && !rest.disabled && controls?.onUpdate(newValue);
  };

  /* 
    "lastChange" is saved at each "onValueChange" and triggered only at "onBlur",
    because when we have the scenario "0,something" and "something" is deleted, 
    the "," is deleted as well.

    - at each "onValueChange" the info are saved
    - once "onBlur" is triggered, the last info is returned
    
  */

  const lastChange = useRef<NumberFormatValues>();

  return (
    <div className={styles.numeric}>
      <NumericFormat
        {...rest}
        id={id}
        value={rest.value === undefined || rest.value === null ? "" : rest.value} // needed in order to reset to empty
        disabled={rest.disabled}
        getInputRef={ref}
        thousandSeparator="."
        decimalSeparator=","
        autoComplete={autoComplete}
        spellCheck={spellCheck}
        onValueChange={(value) => {
          lastChange.current = value;
          // rest.onValueChange?.(lastChange.current.floatValue);
        }}
        onBlur={() => rest.onBlur?.(lastChange.current?.floatValue ?? null)}
        onChange={() => {
          // needed in order to return a number instead a string
        }}
        style={{ width: width }}
        className={classNames(inputStyles.input, {
          [inputStyles.invalid]: isInvalid,
        })}
      />
      {controls && (
        <div className={classNames(styles.controls, { [styles.disabled]: rest.disabled })}>
          <button onClick={(e) => click(e, controls.scale)} title={i18n.translation.common.increase(controls.scale)}>
            <Icon glyph={SvgArrowDropUp} />
          </button>
          <button onClick={(e) => click(e, -controls.scale)} title={i18n.translation.common.decrease(controls.scale)}>
            <Icon glyph={SvgArrowDropDown} />
          </button>
        </div>
      )}
    </div>
  );
});

export default Numeric;
