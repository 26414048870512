import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";
import useSWRMutation from "swr/mutation";
import { Paginated, PaginationQuery } from "../../types";
import { buildUrl, mutateCache, useApi } from "../api";
import { CustomError } from "../errors";
import { InsertUpperExtensionBody, UpdateUpperExtensionBody, UpperExtension } from "../types/vertigrip/upperExtensions";

export const useAdminUpperExtensionsApi = () => {
  const api = useApi();

  const getAdminUpperExtension = useCallback(
    (id: string, signal: AbortSignal) => {
      const url = `/admin/settings/vertigrip/upper_extensions/${id}`;
      return useSWRMutation<UpperExtension, CustomError, string>(url, (url) =>
        api.get<UpperExtension>({ url, signal })
      );
    },
    [api]
  );

  const useSteelVersionAdminUpperExtensions = useCallback(
    (steelVersionId: string, query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl(`/admin/settings/vertigrip/steel_versions/${steelVersionId}/upper_extensions`, query);
      return useSWRImmutable<Paginated<UpperExtension>, CustomError, string>(url, (url) =>
        api.get<Paginated<UpperExtension>>({ url, signal })
      );
    },
    [api]
  );

  const useInstallationTypeAdminUpperExtensions = useCallback(
    (installationTypeId: string, query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl(
        `/admin/settings/vertigrip/installation_types/${installationTypeId}/upper_extensions`,
        query
      );
      return useSWRImmutable<Paginated<UpperExtension>, CustomError, string>(url, (url) =>
        api.get<Paginated<UpperExtension>>({ url, signal })
      );
    },
    [api]
  );

  const insertAdminUpperExtension = useCallback(
    async (body: InsertUpperExtensionBody): Promise<{ id: string }> => {
      const url = `/admin/settings/vertigrip/upper_extensions`;
      const id = await api.post<{ id: string }, InsertUpperExtensionBody>(url, body);
      mutateCache([
        url,
        `/admin/settings/vertigrip/steel_versions/${body.steelVersionId}/upper_extensions`,
        `/admin/settings/vertigrip/installation_types/${body.installationTypeId}/upper_extensions`,
      ]);
      return id;
    },
    [api]
  );

  const updateAdminUpperExtension = useCallback(
    async (upperExtensionId: string, body: UpdateUpperExtensionBody): Promise<void> => {
      const url = `/admin/settings/vertigrip/upper_extensions/${upperExtensionId}`;
      await api.put<UpdateUpperExtensionBody>(url, body),
        mutateCache([
          `/admin/settings/vertigrip/upper_extensions`,
          `/admin/settings/vertigrip/steel_versions/${body.steelVersionId}/upper_extensions`,
          `/admin/settings/vertigrip/installation_types/${body.installationTypeId}/upper_extensions`,
        ]);
    },
    [api]
  );

  const deleteAdminUpperExtension = useCallback(
    async (
      upperExtensionId: string,
      steelVersionId: string | null | undefined,
      installationTypeId: string | null | undefined
    ): Promise<void> => {
      const url = `/admin/settings/vertigrip/upper_extensions/${upperExtensionId}`;
      await api.del(url).then(() => {
        mutateCache([
          `/admin/settings/vertigrip/upper_extensions`,
          `/admin/settings/vertigrip/upper_extensions/${upperExtensionId}`,
          `/admin/settings/vertigrip/steel_versions/${steelVersionId}/upper_extensions`,
          `/admin/settings/vertigrip/installation_types/${installationTypeId}/upper_extensions`,
          `/admin/settings/vertigrip/steel_versions/${steelVersionId}/upper_extensions/${upperExtensionId}`,
          `/admin/settings/vertigrip/installation_types/${installationTypeId}/upper_extensions/${upperExtensionId}`,
        ]);
      });
    },
    [api]
  );

  return {
    getAdminUpperExtension,
    useSteelVersionAdminUpperExtensions,
    useInstallationTypeAdminUpperExtensions,
    insertAdminUpperExtension,
    updateAdminUpperExtension,
    deleteAdminUpperExtension,
  };
};
