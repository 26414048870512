import { FC, useContext, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import NotificationDispatch, { showErrorNotification } from "../../../../../context/notificationContext";
import { useDebounce } from "../../../../../hooks/useDebounce";
import useInitializer from "../../../../../hooks/useInitializer";
import useListFilters from "../../../../../hooks/useListFilters";
import useI18n from "../../../../../hooks/useTranslations";
import { AdminNormative } from "../../../../../http/types/vertigrip/normatives";
import { useAdminNormativesApi } from "../../../../../http/vertigrip/useNormatives";
import { PaginationQuery } from "../../../../../types";
import { formatBoolean } from "../../../../../utils";
import Button from "../../../../ui/Button";
import ModalDialog from "../../../../ui/ModalDialog";
import ScrollContent from "../../../../ui/ScrollContent";
import Table from "../../../../ui/Table";
import NewNormative from "./NewNormative";

const Normatives: FC = () => {
  const { id: systemId } = useParams<{ id: string }>();
  const i18n = useI18n();
  const navigate = useNavigate();
  const dispatch = useContext(NotificationDispatch);
  const filters = useListFilters({ isSearchable: true });
  const aborter = useRef(new AbortController());
  const normativesApi = useAdminNormativesApi();

  const [showNewItemDialog, setShowNewItemDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<AdminNormative>();

  const debouncedValue = useDebounce(filters.state.q);

  const query: PaginationQuery = {
    ...filters.state,
    q: debouncedValue,
  };

  const { error, data } = normativesApi.useAdminNormatives(query, aborter.current.signal);

  const isInitialized = useInitializer(data || error);

  if (!data && !error && !isInitialized) {
    return;
  }

  return (
    <ScrollContent padding paddingTop>
      <Table<AdminNormative>
        isTall={true}
        filters={filters}
        searchOptions={{
          placeholder: i18n.translation.normatives.search,
          onChange: () => {
            aborter.current.abort();
            aborter.current = new AbortController();
          },
        }}
        searchButtons={
          <Button buttonProps={{ onClick: () => setShowNewItemDialog(true) }}>
            {i18n.translation.common.createNew}
          </Button>
        }
        head={
          <tr>
            <th align="left">{i18n.translation.common.name}</th>
            <th align="left">{i18n.translation.common.visible}</th>
            <th align="left">{i18n.translation.common.order}</th>
            <th></th>
          </tr>
        }
        items={data?.items}
        renderRow={(item) => (
          <>
            <td>
              <Link to={`/admin/systems/${systemId}/normatives/${item.id}`}>{item.name}</Link>
            </td>
            <td>{formatBoolean(i18n.translation, item.isVisible)}</td>
            <td>{item.order}</td>
          </>
        )}
        rowActions={[
          {
            title: i18n.translation.common.edit,
            onClick: (item) => navigate(`/admin/systems/${systemId}/normatives/${item.id}`),
          },
          { title: i18n.translation.common.delete, onClick: setItemToDelete },
        ]}
        total={data?.total}
        error={!!error}
      />
      <ModalDialog
        isOpen={showNewItemDialog}
        onClose={() => setShowNewItemDialog(false)}
        title={i18n.translation.normatives.createNew}
      >
        <NewNormative />
      </ModalDialog>
      <ModalDialog
        isOpen={!!itemToDelete}
        onClose={() => setItemToDelete(undefined)}
        title={i18n.translation.normatives.delete}
        actions={[
          {
            title: i18n.translation.common.cancel,
            onClick: () => setItemToDelete(undefined),
          },
          {
            title: i18n.translation.common.yes,
            onClick: () => {
              itemToDelete &&
                normativesApi
                  .deleteAdminNormative(itemToDelete.id)
                  .then(() => setItemToDelete(undefined))
                  .catch((err) => dispatch(showErrorNotification(i18n, err)));
            },
          },
        ]}
      >
        {i18n.translation.normatives.questions.delete}
      </ModalDialog>
    </ScrollContent>
  );
};

export default Normatives;
