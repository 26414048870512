import { Glyph } from "../../types";

const SvgPlaceItem: Glyph = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" {...props}>
      <path d="M216-144q-29.7 0-50.85-21.15Q144-186.3 144-216v-384q0-29.7 21.15-50.85Q186.3-672 216-672h156v72H216v384h528v-384H588v-72h156q29.7 0 50.85 21.15Q816-629.7 816-600v384q0 29.7-21.15 50.85Q773.7-144 744-144H216Zm264-192L336-480l51-51 57 57v-438h72v438l57-57 51 51-144 144Z" />
    </svg>
  );
};

export default SvgPlaceItem;
