import { FC } from "react";
import useI18n from "../../../../../hooks/useTranslations";
import ModalDialog from "../../../../ui/ModalDialog";
import IntermediateElementForm from "./IntermediateElementForm";

interface EditIntermediateElementModalDialogProps {
  intermediateElementId?: string;
  steelVersionId?: string;
  installationTypeId?: string;
  systemTypeId?: string;
  onClose: () => void;
}

const EditIntermediateElementModalDialog: FC<EditIntermediateElementModalDialogProps> = ({
  intermediateElementId,
  steelVersionId,
  installationTypeId,
  systemTypeId,
  onClose,
}: EditIntermediateElementModalDialogProps) => {
  const i18n = useI18n();

  return (
    <ModalDialog isOpen={!!intermediateElementId} onClose={onClose} title={i18n.translation.intermediateElements.edit}>
      <IntermediateElementForm
        onCancel={onClose}
        intermediateElementId={intermediateElementId}
        steelVersionId={steelVersionId}
        installationTypeId={installationTypeId}
        systemTypeId={systemTypeId}
      />
    </ModalDialog>
  );
};

export default EditIntermediateElementModalDialog;
