import { FC, useState } from "react";
import useI18n from "../../../../../hooks/useTranslations";
import Button from "../../../../ui/Button";
import ModalDialog from "../../../../ui/ModalDialog";
import IntermediateElementForm from "./IntermediateElementForm";

interface CreateIntermediateElementModalDialogButtonProps {
  steelVersionId?: string;
  installationTypeId?: string;
  systemTypeId?: string;
  onSave: (itemId: string) => void;
}

const CreateIntermediateElementModalDialogButton: FC<CreateIntermediateElementModalDialogButtonProps> = ({
  onSave,
  steelVersionId,
  installationTypeId,
  systemTypeId,
}) => {
  const i18n = useI18n();
  const [showNewItemDialog, setShowNewItemDialog] = useState(false);

  return (
    <>
      <Button buttonProps={{ onClick: () => setShowNewItemDialog(true) }}>{i18n.translation.common.createNew}</Button>
      <ModalDialog
        isOpen={showNewItemDialog}
        onClose={() => setShowNewItemDialog(false)}
        title={i18n.translation.intermediateElements.createNew}
      >
        <IntermediateElementForm
          onCancel={() => setShowNewItemDialog(false)}
          onSave={(id) => {
            onSave(id);
            setShowNewItemDialog(false);
          }}
          steelVersionId={steelVersionId}
          installationTypeId={installationTypeId}
          systemTypeId={systemTypeId}
        />
      </ModalDialog>
    </>
  );
};

export default CreateIntermediateElementModalDialogButton;
