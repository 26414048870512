import { Translation } from "../hooks/useTranslations";

const it: Translation = {
  languageCode: "it",
  projects: {
    addTo: "Aggiungi al progetto",
    all: "Tutti i progetti",
    new: "Nuovo progetto",
    singular: "Progetto",
    plural: "Progetti",
    my: "I miei progetti",
    createNew: "Crea nuovo progetto",
    delete: "Elimina progetto",
    search: "Cerca progetti",
    shareWithUser: "Condividi progetto con utente",
    editPermissions: "Modifica permessi",
    permissionToEdit: "Permesso di modificare",
    editSharings: "Modifica condivisioni",
    sharings: "Condivisioni del progetto",

    questions: {
      delete: "Vuoi davvero eliminare questo progetto?",
      unshare: "Vuoi davvero rimuovere la condivisione del progetto per questo utente?",
    },

    errors: {
      deleteProjectWithConfigurationsNotPossible:
        "Non è possibile eliminare un progetto se sono presenti configurazioni.",
      deleteProjectWithDocumentsNotPossible: "Non è possibile eliminare un progetto se sono presenti document.",
      notFound: "Progetto non trovato.",
      missingName: "Nome progetto mancante.",
      sharingNotFound: "Condivisione non trovata.",
      documentNotFound: "Documento non trovato.",
      missingDocumentName: "Nome del documento mancante.",
      documentAlreadyPresent: "Documento già presente.",
      missingProjectStatus: "Stato progetto mancante.",
    },
  },

  configurations: {
    all: "Tutte le configurazioni",
    new: "Nuova configurazione",
    plural: "Configurazioni",
    create: "Crea configurazione",
    createNew: "Crea nuova configurazione",
    delete: "Elimina configurazione",
    duplicate: "Duplica configurazione",
    my: "Le mie configurazioni",
    search: "Cerca configurazioni",
    reset: "Reimposta configurazione",

    questions: {
      delete: "Vuoi davvero eliminare questa configurazione?",
      reset: "Vuoi davvero reimpostare tutti i dati della configurazione attuale?",
    },

    errors: {
      notFound: "Configurazione non trovata.",
      missingName: "Nome configurazione mancante.",
      missingNormative: "Normativa configurazione mancante.",
      missingSteelVersion: "Versione dell'acciaio configurazione mancante.",
      missingUpperFixationInstallationType: "Tipo di installazione fissazione superiore configurazione mancante.",
      missingUpperExtension: "Estensione superiore configurazione mancante.",
      missingAbsorber: "Assorbitore configurazione mancante.",
      missingSystemType: "Tipo di sistema configurazione mancante.",
      missingCable: "Cavo configurazione mancante.",
    },
  },

  documents: {
    add: "Aggiungi documento",
    all: "Tutti i documenti",
    alreadyPresent: "Documento già presente.",
    plural: "Documenti",
    delete: "Elimina documento",
    search: "Cerca documenti",

    questions: {
      delete: "Vuoi davvero eliminare questo documento?",
      overwrite: "Vuoi davvero sovrascrivere il documento già presente?",
    },

    errors: {
      someCouldNotBeSaved: "Alcuni documenti non sono stati salvati.",
    },
  },

  systems: {
    all: "Tutti i sistemi",
    new: "Nuovo sistema",
    singular: "Sistema",
    plural: "Sistemi",
    delete: "Elimina sistema",
    search: "Cerca sistemi",
    our: "I nostri sistemi",
    vertigrip: "Sistema Vertigrip",

    questions: {
      delete: "Vuoi davvero eliminare questo sistema?",
    },

    errors: {
      notFound: "Sistema non trovato.",
      missingName: "Nome sistema mancante.",
      missingDescriptionShort: "Descrizione breve sistema mancante.",
      missingDescriptionLong: "Descrizione lunga sistema mancante.",
    },
  },

  users: {
    add: "Aggiungi utente",
    all: "Tutti gli utenti",
    plural: "Utenti",
    remove: "Rimuovi utente",
    search: "Cerca utenti",
    usersEmail: "Email utente",
    usersName: "Nome utente",

    questions: {},

    errors: {
      notFound: "Utente non trovato.",
    },
  },

  items: {
    all: "Tutti gli elementi",
    singular: "Elemento",
    plural: "Elementi",
    createNew: "Crea nuovo elemento",
    delete: "Elimina elemento",
    search: "Cerca elementi",
    list: "Lista elementi",

    questions: {
      delete: "Vuoi davvero eliminare questo elemento?",
    },

    errors: {
      notFound: "Elemento non trovato.",
      doesNotExist: "L'articolo con questo codice non esiste.",
      missingName: "Nome elemento mancante.",
      missingDescription: "Descrizione elemento mancante.",
      missingCode: "Codice elemento mancante.",
    },
  },

  absorbers: {
    singular: "Assorbitore",
    plural: "Assorbitori",
    createNew: "Crea nuovo assorbitore",
    delete: "Elimina assorbitore",
    edit: "Modifica assorbitore",
    searchs: "Cerca assorbitori",

    questions: {
      delete: "Vuoi davvero eliminare questo assorbitore?",
    },

    errors: {
      alreadyPresent: "Assorbitore già presente.",
      notFound: "Assorbitore non trovato.",
      noDefined: "Nessun assorbitore definito.",
      inUseByConfiguration: "L'assorbitore è ancora in uso in una configurazione.",
    },
  },

  cables: {
    createNew: "Crea nuovo cavo",
    delete: "Elimina cavo",
    edit: "Modifica cavo",
    search: "Cerca cavi",
    singular: "Cavo",
    plural: "Cavi",

    questions: {
      delete: "Vuoi davvero eliminare questo cavo?",
    },

    errors: {
      alreadyPresent: "Cavo già presente.",
      notFound: "Cavo non trovato.",
      noDefined: "Nessun cavo definito.",
      inUseByConfiguration: "Il cavo è ancora in uso in una configurazione.",
    },
  },

  handles: {
    addItemAsVertigrip: "Aggiungi elemento come maniglia Vertigrip",
    delete: "Elimina maniglia",
    edit: "Modifica maniglia",
    singular: "Maniglia",
    plural: "Maniglie",
    search: "Cerca maniglie",
    noHandle: "Nessuna maniglia",

    questions: {
      delete: "Vuoi davvero eliminare questa maniglia?",
    },

    errors: {
      alreadyPresent: "Maniglia già presente.",
      notFound: "Maniglia non trovata.",
      inUseByConfiguration: "La maniglia è ancora in uso in una configurazione.",
      inUseByUpperExtension: "La maniglia è ancora in uso in un'estensione superiore.",
    },
  },

  intermediateElements: {
    createNew: "Crea nuovo elemento intermedio",
    delete: "Elimina elemento intermedio",
    edit: "Modifica elemento intermedio",
    singular: "Elemento intermedio",
    plural: "Elementi intermedi",
    search: "Cerca elementi intermedi",

    questions: {
      delete: "Vuoi davvero eliminare questo elemento intermedio?",
    },

    errors: {
      alreadyPresent: "Elemento intermedio già presente.",
      notFound: "Elemento intermedio non trovato.",
      inUseByConfiguration: "L'elemento intermedio è ancora in uso in una configurazione.",
    },
  },

  ladderReinforcements: {
    createNew: "Crea nuovo rinforzo per scale",
    delete: "Elimina rinforzo per scale",
    edit: "Modifica rinforzo per scale",
    singular: "Rinforzo per scale",
    plural: "Rinforzi per scale",
    search: "Cerca rinforzi per scale",
    noLadderReinforcement: "Nessun rinforzo per scale",

    questions: {
      delete: "Vuoi davvero eliminare questo rinforzo per scale?",
    },

    errors: {
      alreadyPresent: "Rinforzo per scale già presente.",
      notFound: "Rinforzo per scale non trovato.",
    },
  },

  wallMaterials: {
    createNew: "Crea nuovo materiale per pareti",
    delete: "Elimina materiale per pareti",
    singular: "Materiale per pareti",
    plural: "Materiali per pareti",
    search: "Cerca materiali per pareti",
    noWallMaterial: "Nessun materiale per pareti",

    questions: {
      delete: "Vuoi davvero eliminare questo materiale per pareti?",
    },

    errors: {
      notFound: "Materiale per pareti non trovato.",
      missingName: "Nome materiale per pareti mancante.",
      missingDescription: "Descrizione materiale per pareti mancante.",
      inUseByConfiguration: "Materiale per pareti è ancora in uso in una configurazione.",
    },
  },

  wallMaterialFixings: {
    createNew: "Crea nuovo fissaggio",
    delete: "Elimina fissaggio",
    singular: "Fissaggio",
    plural: "Fissaggi",
    search: "Cerca fissaggi",

    questions: {
      delete: "Vuoi veramente eliminare questo fissaggio?",
    },

    errors: {
      notFound: "Fissaggio non trovato.",
      missingName: "Nome fissaggio mancante.",
      missingDescription: "Descrizione fissaggio mancante.",
      inUseByConfiguration: "Fissaggio in uso da una configurazione.",
    },
  },

  wallMaterialFixingItems: {
    errors: {
      alreadyPresent: "Elemento di fissaggio già presente.",
    },
  },

  lowerElements: {
    createNew: "Crea nuovo elemento inferiore",
    delete: "Elimina elemento inferiore",
    edit: "Modifica elemento inferiore",
    singular: "Elemento inferiore",
    plural: "Elementi inferiori",
    search: "Cerca elementi inferiori",

    questions: {
      delete: "Vuoi veramente eliminare questo elemento inferiore?",
    },

    errors: {
      alreadyPresent: "Elemento inferiore già presente.",
      notFound: "Elemento inferiore non trovato.",
      inUseByConfiguration: "Elemento inferiore in uso da una configurazione.",
    },
  },

  shuttles: {
    addItemAsVertigrip: "Aggiungi elemento come una navetta Vertigrip",
    delete: "Elimina navetta",
    edit: "Modifica navetta",
    singular: "Navetta",
    plural: "Navette",
    search: "Cerca navette",
    no: "Nessuna navetta",

    questions: {
      delete: "Vuoi veramente eliminare questa navetta?",
    },

    errors: {
      alreadyPresent: "Navetta già presente.",
      notFound: "Navetta non trovata.",
    },
  },

  installationTypes: {
    plural: "Tipi d'installazione",
    singular: "Tipo d'installazione",
    search: "Cerca tipi d'installazione",

    questions: {
      delete: "Vuoi veramente eliminare questo tipo d'installazione?",
    },

    errors: {
      notFound: "Tipo d'installazione non trovato.",
      missingName: "Nome tipo d'installazione mancante.",
      missingDescription: "Descrizione tipo d'installazione mancante.",
      noDefined: "Nessun tipo d'installazione definito.",
      inUseByConfiguration: "Tipo d'installazione in uso da una configurazione.",
      inUseByUpperExtension: "Tipo d'installazione in uso da un estensione superiore.",
      inUseByLowerElement: "Tipo d'installazione in uso da un elemento inferiore.",
      inUseByIntermediateElement: "Tipo d'installazione in uso da un elemento intermedio.",
      inUseByLadderReinforcement: "Tipo d'installazione in uso da un rinfozo per scale.",
    },
  },

  steelVersions: {
    createNew: "Crea nuova versione d'acciaio",
    delete: "Elimina versione d'acciaio",
    singular: "Versione d'acciaio",
    plural: "Versioni d'acciaio",
    search: "Cerca versioni d'acciaio",

    questions: {
      delete: "Vuoi veramente eliminare questa versione d'acciaio?",
    },

    errors: {
      notFound: "Versione d'acciaio non trovata.",
      missingName: "Nome versione d'acciaio mancante.",
      missingDescription: "Descrizione versione d'acciaio mancante.",
      noDefined: "Nessuna versione d'acciaio definita.",
      inUseByConfiguration: "Versione d'acciaio in uso da una configurazione.",
      inUseByUpperExtension: "Versione d'acciaio in uso da un estensione superiore.",
      inUseByLowerElement: "Versione d'acciaio in uso da un elemento inferiore.",
      inUseByIntermediateElement: "Versione d'acciaio in uso da un elemento intermedio.",
      inUseByAsorber: "Versione d'acciaio in uso da un assorbitore.",
    },
  },

  upperExtensions: {
    createNew: "Crea nuova estensione superiore",
    delete: "Elimina estensione superiore",
    edit: "Modifica estensione superiore",
    singular: "Estensione superiore",
    plural: "Estensioni superiori",
    search: "Cerca estensioni superiori",

    questions: {
      delete: "Vuoi veramente eliminare questa estensione superiore?",
    },

    errors: {
      alreadyPresent: "Estensione superiore già presente.",
      notFound: "Estensione superiore non trovata.",
      noDefined: "Nessuna estensione superiore definita.",
      inUseByConfiguration: "Estensione superiore in uso da una configurazione.",
    },
  },

  normatives: {
    createNew: "Crea nuova normativa",
    delete: "Elimina normativa",
    singular: "Normativa",
    plural: "Normative",
    search: "Cerca normative",

    questions: {
      delete: "Vuoi veramente eliminare questa normativa?",
    },

    errors: {
      notFound: "Normativa non trovata.",
      missingName: "Nome normativa mancante.",
      noDefined: "Nessuna normativa definita.",
      inUseByConfiguration: "Normativa in uso da una configurazione.",
    },
  },

  systemTypes: {
    singular: "Tipo di sistema",
    plural: "Tipi di sistema",
    search: "Cerca tipi di sistema",

    questions: {
      delete: "Vuoi veramente eliminare questo tipo di sistema?",
    },

    errors: {
      notFound: "Tipo di sistema non trovato.",
      missingName: "Nome tipo di sistema mancante.",
      missingDescription: "Descrizione tipo di sistema mancante.",
      noDefined: "Nessun tipo di sistema definito.",
      inUseByConfiguration: "Tipo di sistema in uso da una configurazione.",
      inUseByShuttle: "Tipo di sistema in uso da una navetta.",
      inUseByIntermediateElement: "Tipo di sistema in uso da un elemento intermedio.",
    },
  },

  texts: {
    singular: "Testo",
    plural: "Testi",
    search: "Cerca testi",

    questions: {
      delete: "Vuoi veramente eliminare questo testo?",
    },

    errors: {
      alreadyPresent: "Testo già presente.",
      notFound: "Testo non trovato.",
      missingId: "Id testo mancante.",
      missingValue: "Valore testo mancante.",
    },
  },

  clients: {
    all: "Tutti gli client",
    plural: "Client",
    createNew: "Crea nuovo client",
    delete: "Elimina client",
    search: "Cerca client",

    questions: {
      delete: "Vuoi davvero eliminare questo client?",
    },

    errors: {
      notFound: "Client non trovato.",
      missingName: "Nome client mancante.",
      insertNotAllowed: "Non sei autorizzato ad inserire un nuovo client.",
      readNotAllowed: "Non sei autorizzato a leggere questo client.",
      updateNotAllowed: "Non sei autorizzato ad aggiornare questo client.",
      deleteNotAllowed: "Non sei autorizzato ad eliminare questo client.",
    },
  },

  roles: {
    plural: "Ruoli",
  },

  permissions: {
    plural: "Permessi",
  },

  secrets: {
    plural: "Secret",

    errors: {
      missingName: "Nome secret mancante.",
      missingValue: "Valore secret mancante.",
    },
  },

  common: {
    active: "Attivo",
    add: "Aggiungi",
    addNewItem: "Aggiungi nuovo elemento",
    address: "Indirizzo",
    administration: "Amministrazione",
    alreadyPresent: "Già presente/i",
    archived: "Archiviato",
    atLeastOneItemNeeded: "É necessario almeno un elemento.",
    author: "Autore",
    automaticSync: "Sync automatico",
    back: "Indietro",
    baseHeight: "Altezza base",
    cancel: "Cancella",
    close: "Chiudi",
    code: "Codice",
    company: "Impresa",
    confirmed: "Confermato",
    offer: "Offerta",
    contactPerson: "Persona di contatto",
    cookiesText:
      "Questo sito web utilizza i cookie rispetta la tua privacy. Cliccando su <b>Ok, ho letto</b> sei a conoscenza dei cookie utilizzati dal nostro sito come specificato nella cookie policy.",
    coordinates: "Coordinate",
    country: "Paese",
    create: "Crea",
    created: "Creato",
    createNew: "Crea nuovo",
    dataHasBeenSavedSuccessfully: "I dati sono stati salvati correttamente.",
    decrease: (value: number) => `Decrementa ${value}`,
    download: "Scarica",
    delete: "Elimina",
    description: "Descrizione",
    descriptionLong: "Descrizione (lunga)",
    descriptionShort: "Descrizione (corta)",
    details: "Dettagli",
    dragAndDropOrUploadAnImage: "Trascina e rilascia o clicca per carica un'immagine",
    dropIt: "Rilascialo!",
    duplicate: "Duplica",
    edit: "Modifica",
    editor: "Editore",
    email: "E-mail",
    error: "Errore",
    errorLoadingData: "Errore caricamento dati",
    excel: "Excel",
    failed: "Fallito/i",
    fieldCannotBeEmpty: "Il campo non può essere vuoto.",
    fieldContainsInvalidValueInsertBiggerOne: "Il campo contiene un valore non valido, inserirne uno più grande.",
    fieldContainsInvalidValueInsertSmallerOne: "Il campo contiene un valore non valido, inserirne uno più piccolo.",
    fieldValueInvalidFormat: "Il valore ha un formato non valido.",
    fieldInvalidValue: "Il campo contiene un valore non valido.",
    fixed: "Fisso",
    forbidden: "Non consentito",
    generalData: "Date generali",
    genericError: "Si è verificato un errore, si prega di riprovare.",
    heightToReach: "Altezza da raggiuingere",
    hidden: "Nascosto",
    home: "Home",
    increase: (value: number) => `Incrementa ${value}`,
    installed: "Installato",
    internediateFixation: "Fissaggio intermedio",
    isVisible: "È visibile",
    ladderExitDifference: "Differenza uscita scala",
    languages: {
      singular: "Lingua",
      plural: "Lingue",
      arabic: "Arabo",
      czech: "Ceco",
      danish: "Danese",
      german: "Tedesco",
      greek: "Greco",
      english: "Inglese",
      spanish: "Spagnolo",
      estonian: "Estone",
      finnish: "Finlandese",
      french: "Francese",
      croatian: "Croato",
      hungarian: "Ungherese",
      icelandic: "Islandese",
      italian: "Italiano",
      japanese: "Giapponese",
      lithuanian: "Lituano",
      latvian: "Lettone",
      dutch: "Olandese",
      norwegian: "Norvegese",
      polish: "Polacco",
      portugese: "Portoghese",
      russian: "Russo",
      slovak: "Slovacco",
      slovenian: "Sloveno",
      swedish: "Svedese",
      turkish: "Turco",
      chinese: "Cinese",
      korean: "Coreano",
    },
    lastAccess: "Ultimo accesso",
    lastModified: "Ultima modifica",
    lastSync: "Ultimo sync",
    loading: "Caricamento...",
    location: "Luogo",
    logout: "Logout",
    lowerFixation: "Fissaggio inferiore",
    minConfigurableQuantity: "Quantità minima configurabile",
    minimumOrderQuantity: "Quantità minima ordinabile",
    minOrderQuantity: "Qtà min. ordinabile",
    moreInformation: "Più informazioni",
    myArea: "La mia area",
    myProfile: "Il Mio Profilo",
    name: "Nome",
    next: "Avanti",
    never: "mai",
    newName: "Nuovo nome",
    nextNRows: (n: number) => `${n} righe successive`,
    no: "No",
    noItemsFound: "Nessun elemento trovato",
    notFound: "Non trovato",
    nrIntermediatesAndSpan: "Nr. intermedi e intervallo",
    nrOfFixingItemsRequired: "Numero di elementi di fissaggio richiesti (per materiale da parete)",
    ok: "Ok",
    okIreadIt: "Ok, ho letto",
    onlyImagesAreAllowed: "Sono ammesse solo immagini.",
    optional: "opzionale",
    order: "Ordine",
    overwrite: "Sovrascrivere",
    previousNRows: (n: number) => `${n} righe precedenti`,
    print: "Stampa",
    qty: "Qtà.",
    removable: "Rimovibile",
    remove: "Rimuovi",
    removeImage: "Rimuovi immagine",
    reset: "Reimposta",
    resetToDefault: "Reimposta ai valori predefiniti",
    resourceNotFound: "La risorsa da lei cercata non è stata trovata.",
    save: "Salva",
    search: "Cerca",
    select: "Seleziona",
    selectProjectFirst: "Selezionare prima un progetto",
    sessionExpired: "Sessione scaduta",
    sessionExpiredText: "Accedi di nuovo per continuare a utilizzare l'applicazione.",
    settings: "Impostazioni",
    settingsSystem: (system: string) => `Impostazioni: ${system}`,
    status: "Stato",
    sync: "Sync",
    targa: "Targa",
    testEnvironment: "Ambiente di test",
    toBeInstalled: "Da installare",
    toInspect: "Da ispezionare",
    uploadedAt: "Caricato",
    uploadedBy: "Caricato da",
    upperFixation: "Fissaggio superiore",
    value: "Valore",
    visible: "Visibile",
    yes: "Si",
    youAreNotAllowed: "Non sei autorizzato ad accedere a questa risorsa.",
  },
};

export default it;
