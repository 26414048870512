import { FC, useContext, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import NotificationDispatch, { showErrorNotification } from "../../../../../../context/notificationContext";
import { useDebounce } from "../../../../../../hooks/useDebounce";
import useInitializer from "../../../../../../hooks/useInitializer";
import useListFilters from "../../../../../../hooks/useListFilters";
import useI18n from "../../../../../../hooks/useTranslations";
import { LadderReinforcement } from "../../../../../../http/types/vertigrip/ladderReinforcements";
import { useAdminLadderReinforcementsApi } from "../../../../../../http/vertigrip/useLadderReinforcements";
import { PaginationQuery } from "../../../../../../types";
import { formatBoolean } from "../../../../../../utils";
import ModalDialog from "../../../../../ui/ModalDialog";
import ScrollContent from "../../../../../ui/ScrollContent";
import Table from "../../../../../ui/Table";
import CreateLadderReinforcementModalDialogButton from "./CreateLadderReinforcementModalDialogButton";
import EditLadderReinforcementModalDialog from "./EditLadderReinforcementModalDialog";

const LadderReinforcements: FC = () => {
  const i18n = useI18n();
  const { id: installationTypeId } = useParams<{ id: string }>();
  const dispatch = useContext(NotificationDispatch);
  const filters = useListFilters({ isSearchable: true });
  const aborter = useRef(new AbortController());
  const ladderReinforcementApi = useAdminLadderReinforcementsApi();

  const [itemToEdit, setItemToEdit] = useState<LadderReinforcement>();
  const [itemToDelete, setItemToDelete] = useState<LadderReinforcement>();

  const debouncedValue = useDebounce(filters.state.q);

  const query: PaginationQuery = {
    ...filters.state,
    q: debouncedValue,
  };

  const { error, data } = ladderReinforcementApi.useAdminInstallationTypeLadderReinforcements(
    installationTypeId!,
    query,
    aborter.current.signal
  );

  const isInitialized = useInitializer(data || error);

  if (!data && !error && !isInitialized) {
    return;
  }

  return (
    <ScrollContent padding paddingTop>
      <Table<LadderReinforcement>
        isTall={true}
        filters={filters}
        searchOptions={{
          placeholder: i18n.translation.ladderReinforcements.search,
          onChange: () => {
            aborter.current.abort();
            aborter.current = new AbortController();
          },
        }}
        searchButtons={<CreateLadderReinforcementModalDialogButton />}
        head={
          <tr>
            <th align="left">{i18n.translation.common.code}</th>
            <th align="left">{i18n.translation.common.description}</th>
            <th align="left">{i18n.translation.common.visible}</th>
            <th align="left">{i18n.translation.common.order}</th>
            <th></th>
          </tr>
        }
        items={data?.items}
        renderRow={(item) => (
          <>
            <td>
              <Link to={`/admin/items/${item.id}`}>{item.code}</Link>
            </td>
            <td>{item.description}</td>
            <td>{formatBoolean(i18n.translation, item.isVisible)}</td>
            <td>{item.order}</td>
          </>
        )}
        rowActions={[
          { title: i18n.translation.common.edit, onClick: setItemToEdit },
          { title: i18n.translation.common.delete, onClick: setItemToDelete },
        ]}
        total={data?.total}
        error={!!error}
      />
      <ModalDialog
        isOpen={!!itemToDelete}
        onClose={() => setItemToDelete(undefined)}
        title={i18n.translation.ladderReinforcements.delete}
        actions={[
          {
            title: i18n.translation.common.cancel,
            onClick: () => setItemToDelete(undefined),
          },
          {
            title: i18n.translation.common.yes,
            onClick: () => {
              itemToDelete &&
                ladderReinforcementApi
                  .deleteAdminLadderReinforcement(itemToDelete.id, itemToDelete.installationType.id)
                  .then(() => setItemToDelete(undefined))
                  .catch((err) => dispatch(showErrorNotification(i18n, err)));
            },
          },
        ]}
      >
        {i18n.translation.ladderReinforcements.questions.delete}
      </ModalDialog>
      <EditLadderReinforcementModalDialog onClose={() => setItemToEdit(undefined)} ladderReinforcement={itemToEdit} />
    </ScrollContent>
  );
};

export default LadderReinforcements;
