import { Glyph } from "../../types";

const SvgQuestionMark: Glyph = (props) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        id="background"
        d="M12.25 1C6.04 1 1 6.04 1 12.25C1 18.46 6.04 23.5 12.25 23.5C18.46 23.5 23.5 18.46 23.5 12.25C23.5 6.04 18.46 1 12.25 1Z"
        fill={props.fill ?? "var(--grey-40)"}
      ></path>
      <path
        d="M13.8418 18.7061C13.8418 19.3381 13.3295 19.8504 12.6975 19.8504V19.8504C12.0655 19.8504 11.5532 19.3381 11.5532 18.7061V18.7061C11.5532 18.0741 12.0655 17.5618 12.6975 17.5618V17.5618C13.3295 17.5618 13.8418 18.0741 13.8418 18.7061V18.7061Z"
        fill="white"
      ></path>
      <path
        d="M15.6642 11.6598L14.6886 12.5906C13.9081 13.3292 13.4202 13.9363 13.4202 15.4539H11.2521V14.9481C11.2521 13.8351 11.74 12.8233 12.5205 12.0847L13.8647 10.8099C14.2658 10.4457 14.5043 9.93976 14.5043 9.38329C14.5043 8.27033 13.5286 7.35973 12.3362 7.35973C11.1437 7.35973 10.1681 8.27033 10.1681 9.38329H8C8 7.14726 9.94044 5.33618 12.3362 5.33618C14.7319 5.33618 16.6724 7.14726 16.6724 9.38329C16.6724 10.2736 16.2821 11.0831 15.6642 11.6598Z"
        fill="white"
      ></path>
    </svg>
  );
};

export default SvgQuestionMark;
