import classNames from "classnames";
import { memo, MouseEventHandler } from "react";
import { Glyph } from "../../types";
import styles from "./Icon.module.css";

interface Props {
  glyph: Glyph;
  className?: string;
  onClick?: MouseEventHandler;
  fill?: string;
}

const Icon = ({ glyph: Glyph, className, onClick, fill }: Props) => {
  return <Glyph className={classNames(styles.icon, className)} onClick={onClick} fill={fill} />;
};

export default memo(Icon);
