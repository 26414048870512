import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";
import { Paginated, PaginationQuery } from "../../types";
import { buildUrl, mutateCache, useApi } from "../api";
import { CustomError } from "../errors";
import { Handle, InsertHandleBody, UpdateHandleBody } from "../types/vertigrip/handles";

export const useAdminHandlesApi = () => {
  const api = useApi();

  const useAdminHandles = useCallback(
    (query: PaginationQuery, signal: AbortSignal, includeHidden?: boolean) => {
      const url = buildUrl("/admin/settings/vertigrip/handles", query, includeHidden);
      return useSWRImmutable<Paginated<Handle>, CustomError, string>(url, (url) =>
        api.get<Paginated<Handle>>({ url, signal })
      );
    },
    [api]
  );

  const insertAdminHandle = useCallback(
    async (body: InsertHandleBody): Promise<{ id: string }> => {
      const url = `/admin/settings/vertigrip/handles`;
      const id = await api.post<{ id: string }, InsertHandleBody>(url, body);
      mutateCache([url]);
      return id;
    },
    [api]
  );

  const updateAdminHandle = useCallback(
    async (handleId: string, body: UpdateHandleBody): Promise<void> => {
      const url = `/admin/settings/vertigrip/handles/${handleId}`;
      await api.put<UpdateHandleBody>(url, body), mutateCache([`/admin/settings/vertigrip/handles`]);
    },
    [api]
  );

  const deleteAdminHandle = useCallback(
    async (handleId: string): Promise<void> => {
      await api.del(`/admin/settings/vertigrip/handles/${handleId}`).then(() => {
        mutateCache([`/admin/settings/vertigrip/handles`]);
      });
    },
    [api]
  );

  return {
    useAdminHandles,
    insertAdminHandle,
    updateAdminHandle,
    deleteAdminHandle,
  };
};
