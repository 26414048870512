import { Glyph } from "../../types";

const SvgArrowDropUp: Glyph = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" {...props}>
      <path d="m288-384 192-192 192 192H288Z" />
    </svg>
  );
};

export default SvgArrowDropUp;
