import { FC, useRef } from "react";
import { useParams } from "react-router-dom";
import useTabs from "../../../../../hooks/useTabs";
import useI18n from "../../../../../hooks/useTranslations";
import { useAdminSystemTypesApi } from "../../../../../http/vertigrip/useSystemTypes";
import Header from "../../../../ui/Header";
import Tabs from "../../../../ui/Tabs";
import SystemTypeIntermediateElements from "../intermediateElements/SystemTypeIntermediateElements";
import Shuttles from "./shuttles/Shuttles";
import SystemTypeDetails from "./SystemTypeDetails";

const SystemType: FC = () => {
  const i18n = useI18n();
  const { systemId } = useParams<{ systemId: string }>();
  const { id } = useParams<{ id: string }>();
  const systemTypesApi = useAdminSystemTypesApi();

  const aborter = useRef(new AbortController());
  const { error, data, isLoading } = systemTypesApi.useAdminSystemType(id!, aborter.current.signal);

  const tabs = [
    { title: i18n.translation.common.generalData },
    { title: i18n.translation.intermediateElements.plural },
    { title: i18n.translation.shuttles.plural },
  ];
  const controller = useTabs(tabs.length);

  const path = [
    { url: `/admin/systems/${systemId}`, display: i18n.translation.systems.vertigrip },
    {
      url: `/admin/systems/${systemId}/settings?tab=5`,
      display: `${i18n.translation.common.settings}: ${i18n.translation.systemTypes.plural}`,
    },
    { display: data?.name ?? "" },
  ];

  return (
    <>
      <Header title={`${i18n.translation.systemTypes.singular}: ${data?.name ?? ""}`} error={error} path={path} />
      <Tabs
        controller={controller}
        tabs={tabs}
        panels={[
          <SystemTypeDetails systemType={!error ? data : undefined} disabled={isLoading} />,
          <SystemTypeIntermediateElements />,
          <Shuttles />,
        ]}
        hasSubTabs
      />
    </>
  );
};

export default SystemType;
