import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";
import { Paginated, PaginationQuery } from "../../types";
import { buildUrl, mutateCache, useApi } from "../api";
import { CustomError } from "../errors";
import { InsertShuttleBody, Shuttle, UpdateShuttleBody } from "../types/vertigrip/shuttles";

export const useAdminShuttlesApi = () => {
  const api = useApi();

  const useAdminSystemTypeShuttles = useCallback(
    (systemTypeId: string, query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl(`/admin/settings/vertigrip/system_types/${systemTypeId}/shuttles`, query);
      return useSWRImmutable<Paginated<Shuttle>, CustomError, string>(url, (url) =>
        api.get<Paginated<Shuttle>>({ url, signal })
      );
    },
    [api]
  );

  const insertAdminShuttle = useCallback(
    async (body: InsertShuttleBody): Promise<{ id: string }> => {
      const url = `/admin/settings/vertigrip/shuttles`;
      const id = await api.post<{ id: string }, InsertShuttleBody>(url, body);
      mutateCache([url, `/admin/settings/vertigrip/system_types/${body.systemTypeId}/shuttles`]);
      return id;
    },
    [api]
  );

  const updateAdminShuttle = useCallback(
    async (shuttleId: string, body: UpdateShuttleBody): Promise<void> => {
      const url = `/admin/settings/vertigrip/shuttles/${shuttleId}`;
      await api.put<UpdateShuttleBody>(url, body),
        mutateCache([
          `/admin/settings/vertigrip/shuttles`,
          `/admin/settings/vertigrip/system_types/${body.systemTypeId}/shuttles`,
        ]);
    },
    [api]
  );

  const deleteAdminShuttle = useCallback(
    async (shuttleId: string, systemTypeId: string): Promise<void> => {
      const url = `/admin/settings/vertigrip/shuttles/${shuttleId}`;
      await api.del(url).then(() => {
        mutateCache([
          `/admin/settings/vertigrip/shuttles`,
          `/admin/settings/vertigrip/shuttles/${shuttleId}`,
          `/admin/settings/vertigrip/system_types/${systemTypeId}/shuttles`,
        ]);
      });
    },
    [api]
  );

  return {
    useAdminSystemTypeShuttles,
    insertAdminShuttle,
    updateAdminShuttle,
    deleteAdminShuttle,
  };
};
