import { useEffect, useState } from "react";
import { PaginationQuery } from "../types";

export const DEFAULT_DELAY = 300;

export const useDebounce = <T>(value: T, delay: number = DEFAULT_DELAY): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};

export const createLoadOptions = <T>(
  aborterRef: React.MutableRefObject<AbortController>,
  searchTermRef: React.MutableRefObject<string>,
  get: (query: PaginationQuery, signal: AbortSignal) => Promise<T[]>
) => {
  const loadOptions = (inputValue: string): Promise<T[]> => {
    aborterRef.current.abort();
    aborterRef.current = new AbortController();
    searchTermRef.current = inputValue;

    return new Promise<T[]>((resolve, reject) => {
      setTimeout(async () => {
        if (searchTermRef.current == inputValue) {
          try {
            const items = await get(
              {
                limit: 5,
                q: inputValue,
              },
              aborterRef.current.signal
            );
            resolve(items ?? []);
          } catch (error) {
            reject(error);
          }
        } else {
          reject();
        }
      }, DEFAULT_DELAY);
    });
  };

  return loadOptions;
};
