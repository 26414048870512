import { FC, useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import NotificationDispatch, {
  showErrorNotification,
  showSuccessNotification,
} from "../../../../../context/notificationContext";
import useTabs from "../../../../../hooks/useTabs";
import useI18n from "../../../../../hooks/useTranslations";
import { useApi } from "../../../../../http/api";
import {
  AdminNormative,
  UpdateNormativeNewImageBody,
  UpdateNormativeRemoveImageBody,
} from "../../../../../http/types/vertigrip/normatives";
import { useAdminNormativesApi } from "../../../../../http/vertigrip/useNormatives";
import { getUILanguages } from "../../../../../types";
import Form from "../../../../ui/Form";
import FormField from "../../../../ui/FormField";
import FormFieldsContainer from "../../../../ui/FormFieldsContainer";
import { default as useImageUploader } from "../../../../ui/ImageUploader";
import Input from "../../../../ui/Input";
import Numeric from "../../../../ui/Numeric";
import ScrollContent from "../../../../ui/ScrollContent";
import Switch from "../../../../ui/Switch";
import Tabs from "../../../../ui/Tabs";
import Textarea from "../../../../ui/Textarea";

interface UpdateNormativeForm {
  name: string | null;
  nameIt: string | null;
  nameDe: string | null;
  nameEs: string | null;
  nameFr: string | null;
  order: number | null;
  description: string | null;
  descriptionIt: string | null;
  descriptionDe: string | null;
  descriptionEs: string | null;
  descriptionFr: string | null;
  isVisible: boolean;
}

interface NormativeDetailsProps {
  normative?: AdminNormative;
  disabled?: boolean;
}

const NormativeDetails: FC<NormativeDetailsProps> = ({ normative, disabled }: NormativeDetailsProps) => {
  const i18n = useI18n();
  const api = useApi();
  const dispatch = useContext(NotificationDispatch);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const normativesApi = useAdminNormativesApi();

  const languages = getUILanguages(i18n.translation);
  const controller = useTabs(languages.length);
  const [tabs, setTabs] = useState(languages.map((x) => ({ title: x.display, value: x.value })));

  const { component, newImage, removeImage, onSave } = useImageUploader({
    defaultImage: normative?.image,
    disabled,
  });

  const defaultValues: UpdateNormativeForm = {
    name: normative?.name ?? null,
    nameIt: normative?.nameIt ?? null,
    nameDe: normative?.nameDe ?? null,
    nameEs: normative?.nameEs ?? null,
    nameFr: normative?.nameFr ?? null,
    description: normative?.description ?? null,
    descriptionIt: normative?.descriptionIt ?? null,
    descriptionDe: normative?.descriptionDe ?? null,
    descriptionEs: normative?.descriptionEs ?? null,
    descriptionFr: normative?.descriptionFr ?? null,
    order: normative?.order ?? 50,
    isVisible: normative?.isVisible ?? false,
  };

  useEffect(() => {
    setTabs(languages.map((x) => ({ title: x.display, value: x.value })));
  }, [i18n.translation]);

  const submit = (body: UpdateNormativeNewImageBody | UpdateNormativeRemoveImageBody) => {
    normativesApi
      .updateAdminNormative(normative?.id!, body)
      .then(() => {
        setIsSubmitting(false);
        dispatch(showSuccessNotification(i18n.translation));
        onSave();
      })
      .catch((err) => {
        dispatch(showErrorNotification(i18n, err));
        setIsSubmitting(false);
      });
  };

  const onSubmit = (formData: UpdateNormativeForm) => {
    setIsSubmitting(true);

    const commonBody = {
      name: formData.name?.trim()!,
      nameIt: formData.nameIt?.trim() ?? null,
      nameDe: formData.nameDe?.trim() ?? null,
      nameEs: formData.nameEs?.trim() ?? null,
      nameFr: formData.nameFr?.trim() ?? null,
      order: formData.order!,
      description: formData.description!,
      descriptionIt: formData.descriptionIt?.trim() ?? null,
      descriptionDe: formData.descriptionDe?.trim() ?? null,
      descriptionEs: formData.descriptionEs?.trim() ?? null,
      descriptionFr: formData.descriptionFr?.trim() ?? null,
      isVisible: formData.isVisible,
    };

    if (newImage) {
      normativesApi
        .generateUploadImageUrl(normative?.id!, newImage.name)
        .then((uploadPolicy) => {
          api
            .uploadFile(uploadPolicy, newImage)
            .then((res) => {
              if (!res.ok) {
                dispatch(showErrorNotification(i18n, res));
                setIsSubmitting(false);
                return;
              }
              const body: UpdateNormativeNewImageBody = {
                ...commonBody,
                image: newImage.name,
              };
              submit(body);
            })
            .catch((err) => {
              dispatch(showErrorNotification(i18n, err));
              setIsSubmitting(false);
            });
        })
        .catch((err) => {
          dispatch(showErrorNotification(i18n, err));
          setIsSubmitting(false);
        });
    } else {
      const body: UpdateNormativeRemoveImageBody = {
        ...commonBody,
        removeImage: removeImage,
      };
      submit(body);
    }
  };

  const panel = (
    <ScrollContent direction="row">
      <Form<UpdateNormativeForm>
        defaultValues={defaultValues}
        submitText={i18n.translation.common.save}
        disabled={isSubmitting || disabled}
        onSubmit={() => onSubmit}
      >
        {({ register, formState: { errors }, control, setValue, reset, watch }) => {
          const visible = !!watch("isVisible");

          useEffect(() => {
            reset(defaultValues);
          }, [normative]);

          return (
            <FormFieldsContainer imageUploader={component} tabbed>
              <FormField
                label={i18n.translation.common.name}
                error={errors.name}
                hidden={controller.selectedIndex != 0}
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("name", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return <Input id={labelId} isInvalid={isInvalid} disabled={disabled} {...options} />;
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.name}
                error={errors.nameIt}
                hidden={controller.selectedIndex != 1}
                isOptional
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("nameIt", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return <Input id={labelId} isInvalid={isInvalid} disabled={disabled} {...options} />;
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.name}
                error={errors.nameDe}
                hidden={controller.selectedIndex != 2}
                isOptional
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("nameDe", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return <Input id={labelId} isInvalid={isInvalid} disabled={disabled} {...options} />;
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.name}
                error={errors.nameEs}
                hidden={controller.selectedIndex != 3}
                isOptional
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("nameEs", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return <Input id={labelId} isInvalid={isInvalid} disabled={disabled} {...options} />;
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.name}
                error={errors.nameFr}
                hidden={controller.selectedIndex != 4}
                isOptional
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("nameFr", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return <Input id={labelId} isInvalid={isInvalid} disabled={disabled} {...options} />;
                }}
              </FormField>

              <FormField
                label={i18n.translation.common.description}
                error={errors.description}
                hidden={controller.selectedIndex != 0}
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("description", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return <Textarea id={labelId} isInvalid={isInvalid} disabled={disabled} {...options} />;
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.description}
                error={errors.descriptionIt}
                hidden={controller.selectedIndex != 1}
                isOptional
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("descriptionIt", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return <Textarea id={labelId} isInvalid={isInvalid} disabled={disabled} {...options} />;
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.description}
                error={errors.descriptionDe}
                hidden={controller.selectedIndex != 2}
                isOptional
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("descriptionDe", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return <Textarea id={labelId} isInvalid={isInvalid} disabled={disabled} {...options} />;
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.description}
                error={errors.descriptionEs}
                hidden={controller.selectedIndex != 3}
                isOptional
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("descriptionEs", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return <Textarea id={labelId} isInvalid={isInvalid} disabled={disabled} {...options} />;
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.description}
                error={errors.descriptionFr}
                hidden={controller.selectedIndex != 4}
                isOptional
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("descriptionFr", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return <Textarea id={labelId} isInvalid={isInvalid} disabled={disabled} {...options} />;
                }}
              </FormField>
              <FormField label={i18n.translation.common.isVisible}>
                {({ labelId }) => (
                  <Switch
                    id={labelId}
                    checked={visible}
                    disabled={disabled || controller.selectedIndex != 0}
                    onChange={(checked) => setValue("isVisible", checked)}
                  />
                )}
              </FormField>
              <Controller
                name="order"
                control={control}
                rules={{ required: true, min: 1 }}
                render={({ field, fieldState }) => (
                  <FormField label={i18n.translation.common.order} error={fieldState.error}>
                    {({ labelId, isInvalid }) => (
                      <Numeric
                        {...field}
                        id={labelId}
                        disabled={disabled || controller.selectedIndex != 0}
                        isInvalid={isInvalid}
                        decimalScale={0}
                        allowNegative={false}
                        onBlur={(value) => setValue("order", value)}
                      />
                    )}
                  </FormField>
                )}
              />
            </FormFieldsContainer>
          );
        }}
      </Form>
    </ScrollContent>
  );

  return <Tabs controller={controller} tabs={tabs} panels={languages.map(() => panel)} />;
};

export default NormativeDetails;
