import { FC, PropsWithChildren, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

const modalRoot = document.getElementById("modal-root");
const errorMessage = "#modal-root not found";

const ModalRoot: FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
  const elRef = useRef(document.createElement("div"));

  useEffect(() => {
    if (!modalRoot) throw new Error(errorMessage);
    modalRoot.appendChild(elRef.current);

    return () => {
      modalRoot.removeChild(elRef.current);
    };
  }, []);

  return createPortal(children, elRef.current);
};

export default ModalRoot;
