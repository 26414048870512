import classNames from "classnames";

import { FC, PropsWithChildren } from "react";
import styles from "./ScrollContent.module.css";

type ScrollContentProps = PropsWithChildren<{
  padding?: boolean;
  paddingTop?: boolean;
  direction?: "row" | "column";
}>;

const ScrollContent: FC<ScrollContentProps> = ({
  children,
  padding = false,
  paddingTop = false,
  direction = "column",
}: ScrollContentProps) => (
  <div
    className={classNames(styles.content, {
      [styles.padding]: padding,
      [styles.paddingTop]: paddingTop,
      [styles.row]: direction == "row",
      [styles.column]: direction == "column",
    })}
  >
    {children}
  </div>
);

export default ScrollContent;
