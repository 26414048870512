import { FC, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import useTabs from "../../../../../../hooks/useTabs";
import useI18n from "../../../../../../hooks/useTranslations";
import { useAdminWallMaterialsApi } from "../../../../../../http/vertigrip/useWallMaterials";
import Header from "../../../../../ui/Header";
import Tabs from "../../../../../ui/Tabs";
import WallMaterialDetails from "./WallMaterialDetails";
import WallMaterialFixings from "./fixings/Fixings";

const WallMaterial: FC = () => {
  const i18n = useI18n();
  const { systemId } = useParams<{ systemId: string }>();
  const { installationTypeId } = useParams<{ installationTypeId: string }>();
  const { id } = useParams<{ id: string }>();
  const wallMaterialApi = useAdminWallMaterialsApi();

  const aborter = useRef(new AbortController());
  const { error, data, isMutating, trigger } = wallMaterialApi.getAdminWallMaterial(id!, aborter.current.signal);

  const tabs = [{ title: i18n.translation.common.generalData }, { title: i18n.translation.wallMaterialFixings.plural }];
  const controller = useTabs(tabs.length);

  const path = [
    { url: `/admin/systems/${systemId}`, display: i18n.translation.systems.vertigrip },
    {
      url: `/admin/systems/${systemId}/settings?tab=3`,
      display: `${i18n.translation.common.settings}: ${i18n.translation.installationTypes.plural}`,
    },
    {
      url: `/admin/systems/${systemId}/installation_types/${installationTypeId}?tab=5`,
      display: i18n.translation.wallMaterials.plural,
    },
    { display: data?.name ?? "" },
  ];

  useEffect(() => {
    id && trigger();
  }, [id]);

  return (
    <>
      <Header title={`${i18n.translation.wallMaterials.singular}: ${data?.name ?? ""}`} error={error} path={path} />
      <Tabs
        controller={controller}
        tabs={tabs}
        panels={[
          <WallMaterialDetails wallMaterial={!error ? data : undefined} disabled={isMutating} />,
          <WallMaterialFixings />,
        ]}
        hasSubTabs
      />
    </>
  );
};

export default WallMaterial;
