import classNames from "classnames";

import { FC, PropsWithChildren, useEffect, useRef } from "react";
import ModalRoot from "../ModalRoot";
import SvgClose from "../icons/Close";
import Button from "./Button";
import Icon from "./Icon";
import styles from "./ModalDialog.module.css";

type Props = PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
  title: string;
  actions?: ActionButton[];
  full?: boolean;
  fitContent?: boolean;
  minHeight?: `${number}px`;
}>;

export interface ActionButton {
  title: string;
  isSubmitting?: boolean;
  onClick: () => void;
  type?: "primary" | "secondary";
}

const ModalDialog: FC<Props> = ({ children, isOpen, onClose, title, actions, full, fitContent, minHeight }: Props) => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  const open = () => !dialogRef.current?.open && dialogRef.current?.showModal();
  const close = () => dialogRef.current?.open && dialogRef.current?.close();

  useEffect(() => {
    isOpen ? open() : close();
  }, [isOpen]);

  return (
    <ModalRoot>
      <dialog
        ref={dialogRef}
        className={classNames(styles.dialog, {
          [styles.full]: full,
          [styles.min]: !full && !fitContent,
        })}
        onClose={onClose}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={styles.content}
          style={{
            minHeight: minHeight,
          }}
        >
          <div className={styles.header}>
            <div className={styles.title}>{title}</div>
            <button className={styles.closeButton} onClick={close}>
              <Icon className={styles.icon} glyph={SvgClose} />
            </button>
          </div>

          {isOpen && children}

          {actions && (
            <div className={styles.buttons}>
              {actions.map(({ onClick, title, isSubmitting, type }, index) => (
                <Button
                  key={index}
                  isLoading={isSubmitting}
                  buttonProps={{
                    disabled: isSubmitting,
                    onClick,
                  }}
                  type={type}
                >
                  {title}
                </Button>
              ))}
            </div>
          )}
        </div>
      </dialog>
    </ModalRoot>
  );
};

export default ModalDialog;
