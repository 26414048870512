import { FC, useContext, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import NotificationDispatch, { showErrorNotification } from "../../../../../context/notificationContext";
import { useDebounce } from "../../../../../hooks/useDebounce";
import useInitializer from "../../../../../hooks/useInitializer";
import useListFilters from "../../../../../hooks/useListFilters";
import useI18n from "../../../../../hooks/useTranslations";
import { LowerElement } from "../../../../../http/types/vertigrip/lowerElements";
import { useAdminLowerElementsApi } from "../../../../../http/vertigrip/useLowerElements";
import { PaginationQuery } from "../../../../../types";
import { formatBoolean } from "../../../../../utils";
import ModalDialog from "../../../../ui/ModalDialog";
import ScrollContent from "../../../../ui/ScrollContent";
import Table from "../../../../ui/Table";
import CreateLowerElementModalDialogButton from "./CreateLowerElementModalDialogButton";
import EditLowerElementModalDialog from "./EditLowerElementModalDialog";

const SteelVersionLowerElements: FC = () => {
  const i18n = useI18n();
  const { id: systemId } = useParams<{ id: string }>();
  const { id: steelVersionId } = useParams<{ id: string }>();
  const dispatch = useContext(NotificationDispatch);
  const filters = useListFilters({ isSearchable: true });
  const aborter = useRef(new AbortController());
  const lowerElementaApi = useAdminLowerElementsApi();

  const [idItemToEdit, setIdItemToEdit] = useState<string>();
  const [itemToDelete, setItemToDelete] = useState<LowerElement>();

  const debouncedValue = useDebounce(filters.state.q);

  const query: PaginationQuery = {
    ...filters.state,
    q: debouncedValue,
  };

  const { error, data } = lowerElementaApi.useSteelVersionAdminLowerElements(
    steelVersionId!,
    query,
    aborter.current.signal
  );

  const isInitialized = useInitializer(data || error);

  if (!data && !error && !isInitialized) {
    return;
  }

  return (
    <ScrollContent padding paddingTop>
      <Table<LowerElement>
        isTall={true}
        filters={filters}
        searchOptions={{
          placeholder: i18n.translation.lowerElements.search,
          onChange: () => {
            aborter.current.abort();
            aborter.current = new AbortController();
          },
        }}
        searchButtons={<CreateLowerElementModalDialogButton onSave={setIdItemToEdit} steelVersionId={steelVersionId} />}
        head={
          <tr>
            <th align="left">{i18n.translation.common.code}</th>
            <th align="left">{i18n.translation.common.description}</th>
            <th align="left">{i18n.translation.installationTypes.singular}</th>
            <th align="left">{i18n.translation.common.visible}</th>
            <th align="left">{i18n.translation.common.order}</th>
            <th></th>
          </tr>
        }
        items={data?.items}
        renderRow={(item) => (
          <>
            <td>
              <Link to={`/admin/items/${item.id}`}>{item.code}</Link>
            </td>
            <td>{item.description}</td>
            <td>
              <Link to={`/admin/systems/${systemId}/installation_types/${item.installationType?.id}`}>
                {item.installationType?.name}
              </Link>
            </td>
            <td>{formatBoolean(i18n.translation, item.isVisible)}</td>
            <td>{item.order}</td>
          </>
        )}
        rowActions={[
          { title: i18n.translation.common.edit, onClick: (item) => setIdItemToEdit(item.id) },
          { title: i18n.translation.common.delete, onClick: setItemToDelete },
        ]}
        total={data?.total}
        error={!!error}
      />
      <ModalDialog
        isOpen={!!itemToDelete}
        onClose={() => setItemToDelete(undefined)}
        title={i18n.translation.lowerElements.delete}
        actions={[
          {
            title: i18n.translation.common.cancel,
            onClick: () => setItemToDelete(undefined),
          },
          {
            title: i18n.translation.common.yes,
            onClick: () => {
              itemToDelete &&
                lowerElementaApi
                  .deleteAdminLowerElement(
                    itemToDelete.id,
                    itemToDelete.steelVersion.id,
                    itemToDelete.installationType.id
                  )
                  .then(() => setItemToDelete(undefined))
                  .catch((err) => dispatch(showErrorNotification(i18n, err)));
            },
          },
        ]}
      >
        {i18n.translation.lowerElements.questions.delete}
      </ModalDialog>
      <EditLowerElementModalDialog
        onClose={() => setIdItemToEdit(undefined)}
        steelVersionId={steelVersionId}
        lowerElementId={idItemToEdit}
      />
    </ScrollContent>
  );
};

export default SteelVersionLowerElements;
