import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";
import { Paginated, PaginationQuery, PostPolicy } from "../../types";
import { buildUrl, mutateCache, useApi } from "../api";
import { CustomError } from "../errors";
import {
  AdminSystemType,
  UpdateSystemTypeNewImageBody,
  UpdateSystemTypeRemoveImageBody,
} from "../types/vertigrip/systemTypes";

export const useAdminSystemTypesApi = () => {
  const api = useApi();

  const useAdminSystemType = useCallback(
    (systemTypeId: string, signal: AbortSignal) => {
      const url = `/admin/settings/vertigrip/system_types/${systemTypeId}`;
      return useSWRImmutable<AdminSystemType, CustomError, string>(url, (url) =>
        api.get<AdminSystemType>({ url, signal })
      );
    },
    [api]
  );

  const useAdminSystemTypes = useCallback(
    (query: PaginationQuery, signal: AbortSignal, includeHidden?: boolean) => {
      const url = buildUrl("/admin/settings/vertigrip/system_types", query, includeHidden);
      return useSWRImmutable<Paginated<AdminSystemType>, CustomError, string>(url, (url) =>
        api.get<Paginated<AdminSystemType>>({ url, signal })
      );
    },
    [api]
  );

  // const insertAdminSystemType = useCallback(
  //   async (body: InsertSystemTypeBody): Promise<{ id: string }> => {
  //     const url = `/admin/settings/vertigrip/system_types`;
  //     const id = await api.post<{ id: string }, InsertSystemTypeBody>(url, body);
  //     mutateCache([url]);
  //     return id;
  //   },
  //   [api]
  // );

  const updateAdminSystemType = useCallback(
    async (
      systemTypeId: string,
      body: UpdateSystemTypeRemoveImageBody | UpdateSystemTypeNewImageBody
    ): Promise<void> => {
      const url = `/admin/settings/vertigrip/system_types/${systemTypeId}`;
      await api.put<UpdateSystemTypeRemoveImageBody | UpdateSystemTypeNewImageBody>(url, body),
        mutateCache(["/admin/settings/vertigrip/system_types"]);
    },
    [api]
  );

  // const deleteAdminSystemType = useCallback(
  //   async (systemTypeId: string): Promise<void> => {
  //     const url = `/admin/settings/vertigrip/system_types/${systemTypeId}`;
  //     await api.del(url).then(() => {
  //       mutateCache([`/admin/settings/vertigrip/system_types`]);
  //     });
  //   },
  //   [api]
  // );

  const generateUploadImageUrl = useCallback(
    async (systemTypeId: string, fileName: string): Promise<PostPolicy> => {
      const encodedFileName = encodeURIComponent(fileName);
      const url = `/admin/settings/vertigrip/system_types/${systemTypeId}/image/generate_upload_url?file_name=${encodedFileName}`;
      return api.get<PostPolicy>({ url });
    },
    [api]
  );

  return {
    useAdminSystemType,
    useAdminSystemTypes,
    // insertAdminSystemType,
    updateAdminSystemType,
    // deleteAdminSystemType,
    generateUploadImageUrl,
  };
};
