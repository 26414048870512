import { FC, useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import NotificationDispatch, { showErrorNotification } from "../../../../context/notificationContext";
import { useDebounce } from "../../../../hooks/useDebounce";
import useInitializer from "../../../../hooks/useInitializer";
import useListFilters from "../../../../hooks/useListFilters";
import useI18n from "../../../../hooks/useTranslations";
import { Handle } from "../../../../http/types/vertigrip/handles";
import { useAdminHandlesApi } from "../../../../http/vertigrip/useHandles";
import { PaginationQuery } from "../../../../types";
import { formatBoolean } from "../../../../utils";
import ModalDialog from "../../../ui/ModalDialog";
import ScrollContent from "../../../ui/ScrollContent";
import Table from "../../../ui/Table";
import CreateHandleModalDialogButton from "./CreateHandleModalDialogButton";
import EditHandleModalDialog from "./EditHandleModalDialog";

const Handles: FC = () => {
  const i18n = useI18n();
  const dispatch = useContext(NotificationDispatch);
  const filters = useListFilters({ isSearchable: true });
  const aborter = useRef(new AbortController());

  const [itemToEdit, setItemToEdit] = useState<Handle>();
  const [itemToDelete, setItemToDelete] = useState<Handle>();

  const handelsApi = useAdminHandlesApi();

  const debouncedValue = useDebounce(filters.state.q);

  const query: PaginationQuery = {
    ...filters.state,
    q: debouncedValue,
  };

  const { error, data } = handelsApi.useAdminHandles(query, aborter.current.signal, true);

  const isInitialized = useInitializer(data || error);

  if (!data && !error && !isInitialized) {
    return;
  }

  return (
    <ScrollContent padding paddingTop>
      <Table<Handle>
        isTall={true}
        filters={filters}
        searchOptions={{
          placeholder: i18n.translation.handles.search,
          onChange: () => {
            aborter.current.abort();
            aborter.current = new AbortController();
          },
        }}
        searchButtons={<CreateHandleModalDialogButton />}
        head={
          <tr>
            <th align="left">{i18n.translation.common.code}</th>
            <th align="left">{i18n.translation.common.description}</th>
            <th align="left">{i18n.translation.common.visible}</th>
            <th></th>
          </tr>
        }
        items={data?.items}
        renderRow={(item) => (
          <>
            <td>
              <Link to={`/admin/items/${item.id}`}>{item.code}</Link>
            </td>
            <td>{item.description}</td>
            <td>{formatBoolean(i18n.translation, item.isVisible)}</td>
          </>
        )}
        rowActions={[
          { title: i18n.translation.common.edit, onClick: setItemToEdit },
          { title: i18n.translation.common.delete, onClick: setItemToDelete },
        ]}
        total={data?.total}
        error={!!error}
      />
      <ModalDialog
        isOpen={!!itemToDelete}
        onClose={() => setItemToDelete(undefined)}
        title={i18n.translation.handles.delete}
        actions={[
          {
            title: i18n.translation.common.cancel,
            onClick: () => setItemToDelete(undefined),
          },
          {
            title: i18n.translation.common.yes,
            onClick: () => {
              itemToDelete &&
                handelsApi
                  .deleteAdminHandle(itemToDelete.id)
                  .then(() => setItemToDelete(undefined))
                  .catch((err) => dispatch(showErrorNotification(i18n, err)));
            },
          },
        ]}
      >
        {i18n.translation.handles.questions.delete}
      </ModalDialog>
      <EditHandleModalDialog onClose={() => setItemToEdit(undefined)} handle={itemToEdit} />
    </ScrollContent>
  );
};

export default Handles;
