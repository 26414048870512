import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";
import useSWRMutation from "swr/mutation";
import { Paginated, PaginationQuery, PostPolicy } from "../../types";
import { buildUrl, mutateCache, useApi } from "../api";
import { CustomError } from "../errors";
import {
  AdminInstallationType,
  InsertInstallationTypeBody,
  UpdateInstallationTypeNewImageBody,
  UpdateInstallationTypeRemoveImageBody,
} from "../types/vertigrip/installationTypes";

export const useAdminInstallationTypesApi = () => {
  const api = useApi();

  const getAdminInstallationType = useCallback(
    (id: string, signal: AbortSignal) => {
      const url = `/admin/settings/vertigrip/installation_types/${id}`;
      return useSWRMutation<AdminInstallationType, CustomError, string>(url, (url) =>
        api.get<AdminInstallationType>({ url, signal })
      );
    },
    [api]
  );

  const useAdminInstallationTypes = useCallback(
    (query: PaginationQuery, signal: AbortSignal, includeHidden?: boolean) => {
      const url = buildUrl("/admin/settings/vertigrip/installation_types", query, includeHidden);
      return useSWRImmutable<Paginated<AdminInstallationType>, CustomError, string>(url, (url) =>
        api.get<Paginated<AdminInstallationType>>({ url, signal })
      );
    },
    [api]
  );

  const insertAdminInstallationType = useCallback(
    async (body: InsertInstallationTypeBody): Promise<{ id: string }> => {
      const url = `/admin/settings/vertigrip/installation_types`;
      const id = await api.post<{ id: string }, InsertInstallationTypeBody>(url, body);
      mutateCache([url]);
      return id;
    },
    [api]
  );

  const updateAdminInstallationType = useCallback(
    async (
      installationTypeId: string,
      body: UpdateInstallationTypeRemoveImageBody | UpdateInstallationTypeNewImageBody
    ): Promise<void> => {
      const url = `/admin/settings/vertigrip/installation_types/${installationTypeId}`;
      await api.put<UpdateInstallationTypeRemoveImageBody | UpdateInstallationTypeNewImageBody>(url, body),
        mutateCache(["/admin/settings/vertigrip/installation_types"]);
    },
    [api]
  );

  const deleteAdminInstallationType = useCallback(
    async (installationTypeId: string): Promise<void> => {
      const url = `/admin/settings/vertigrip/installation_types/${installationTypeId}`;
      await api.del(url).then(() => {
        mutateCache([`/admin/settings/vertigrip/installation_types`]);
      });
    },
    [api]
  );

  const generateUploadImageUrl = useCallback(
    async (installationTypeId: string, fileName: string): Promise<PostPolicy> => {
      const encodedFileName = encodeURIComponent(fileName);
      const url = `/admin/settings/vertigrip/installation_types/${installationTypeId}/image/generate_upload_url?file_name=${encodedFileName}`;
      return api.get<PostPolicy>({ url });
    },
    [api]
  );

  return {
    getAdminInstallationType,
    useAdminInstallationTypes,
    insertAdminInstallationType,
    updateAdminInstallationType,
    deleteAdminInstallationType,
    generateUploadImageUrl,
  };
};
