import { LanguageCode } from "../types";

const en = {
  languageCode: "en" as LanguageCode,
  projects: {
    addTo: "Add to project",
    all: "All projects",
    new: "New project",
    singular: "Project",
    plural: "Projects",
    my: "My Projects",
    createNew: "Create new project",
    delete: "Delete project",
    search: "Search projects",
    shareWithUser: "Share project with user",
    editPermissions: "Edit permissions",
    permissionToEdit: "Permission to edit",
    editSharings: "Edit sharings",
    sharings: "Project sharings",

    questions: {
      delete: "Do you really want to delete this project?",
      unshare: "Do you really want to unshare the project for this user?",
    },

    errors: {
      deleteProjectWithConfigurationsNotPossible:
        "It is not possible to delete a project if configurations are present.",
      deleteProjectWithDocumentsNotPossible: "It is not possible to delete a project if documents are present.",
      notFound: "Project not found.",
      missingName: "Project name is missing.",
      sharingNotFound: "Sharing not found.",
      documentNotFound: "Document not found.",
      missingDocumentName: "Document name is missing.",
      documentAlreadyPresent: "Document already present.",
      missingProjectStatus: "Project status is missing.",
    },
  },

  configurations: {
    all: "All configurations",
    new: "New configuration",
    plural: "Configurations",
    create: "Create configuration",
    createNew: "Create new configuration",
    delete: "Delete configuration",
    duplicate: "Duplicate configuration",
    my: "My Configurations",
    search: "Search configurations",
    reset: "Reset configuration",

    questions: {
      delete: "Do you really want to delete this configuration?",
      reset: "Do you really want to reset all the data of the current configuration?",
    },

    errors: {
      notFound: "Configuration not found.",
      missingName: "Configuration name is missing.",
      missingNormative: "Configuration normative is missing.",
      missingSteelVersion: "Configuration steel version is missing.",
      missingUpperFixationInstallationType: "Configuration upper fixation installation type is missing.",
      missingUpperExtension: "Configuration upper extension is missing.",
      missingAbsorber: "Configuration absorber is missing.",
      missingSystemType: "Configuration system type is missing.",
      missingCable: "Configuration cable is missing.",
    },
  },

  documents: {
    add: "Add document",
    all: "All documents",
    alreadyPresent: "Document already present.",
    plural: "Documents",
    delete: "Delete document",
    search: "Search documents",

    questions: {
      delete: "Do you really want to delete this document?",
      overwrite: "Do you want to overwrite the already present document?",
    },

    errors: {
      someCouldNotBeSaved: "Some documents could not be saved",
    },
  },

  systems: {
    all: "All systems",
    new: "New system",
    singular: "System",
    plural: "Systems",
    delete: "Delete system",
    search: "Search systems",
    our: "Our systems",
    vertigrip: "System Vertigrip",

    questions: {
      delete: "Do you really want to delete this system?",
    },

    errors: {
      notFound: "System not found.",
      missingName: "System name is missing.",
      missingDescriptionShort: "System description short is missing.",
      missingDescriptionLong: "System description long is missing.",
    },
  },

  users: {
    add: "Add user",
    all: "All users",
    plural: "Users",
    remove: "Remove user",
    search: "Search users",
    usersEmail: "Users email",
    usersName: "Users name",

    questions: {},

    errors: {
      notFound: "User not found.",
    },
  },

  items: {
    all: "All items",
    singular: "Item",
    plural: "Items",
    createNew: "Create new item",
    delete: "Delete item",
    search: "Search items",
    list: "Items list",

    questions: {
      delete: "Do you really want to delete this item?",
    },

    errors: {
      notFound: "Item not found.",
      doesNotExist: "Item with this code does not exits.",
      missingName: "Item name is missing.",
      missingDescription: "Item description is missing.",
      missingCode: "Item code is missing.",
    },
  },

  absorbers: {
    singular: "Absorber",
    plural: "Absorbers",
    createNew: "Create new absorber",
    delete: "Delete absorber",
    edit: "Edit absorber",
    searchs: "Search absorbers",

    questions: {
      delete: "Do you really want to delete this absorber?",
    },

    errors: {
      alreadyPresent: "Absorber already present.",
      notFound: "Absorber not found.",
      noDefined: "No absorbers defined.",
      inUseByConfiguration: "Absorber is still in use by a configuration.",
    },
  },

  cables: {
    createNew: "Create new cable",
    delete: "Delete cable",
    edit: "Edit cable",
    search: "Search cables",
    singular: "Cable",
    plural: "Cables",

    questions: {
      delete: "Do you really want to delete this cable?",
    },

    errors: {
      alreadyPresent: "Cable already present.",
      notFound: "Cable not found.",
      noDefined: "No cables defined.",
      inUseByConfiguration: "Cable is still in use by a configuration.",
    },
  },

  handles: {
    addItemAsVertigrip: "Add item as Vertigrip handle",
    delete: "Delete handle",
    edit: "Edit handle",
    singular: "Handle",
    plural: "Handles",
    search: "Search handles",
    noHandle: "No handle",

    questions: {
      delete: "Do you really want to delete this handle?",
    },

    errors: {
      alreadyPresent: "Handle already present.",
      notFound: "Handle not found.",
      inUseByConfiguration: "Handle is still in use by a configuration.",
      inUseByUpperExtension: "Handle is still in use by an upper extension.",
    },
  },

  intermediateElements: {
    createNew: "Create new intermediate element",
    delete: "Delete intermediate element",
    edit: "Edit intermediate element",
    singular: "Intermediate element",
    plural: "Intermediate elements",
    search: "Search intermediate elements",

    questions: {
      delete: "Do you really want to delete this intermediate element?",
    },

    errors: {
      alreadyPresent: "Intermediate element already present.",
      notFound: "Intermediate element not found.",
      inUseByConfiguration: "Intermediate element is still in use by a configuration.",
    },
  },

  ladderReinforcements: {
    createNew: "Create new ladder reinforcement",
    delete: "Delete ladder reinforcement",
    edit: "Edit ladder reinforcement",
    singular: "Ladder reinforcement",
    plural: "Ladder reinforcements",
    search: "Search ladder reinforcements",
    noLadderReinforcement: "No ladder reinforcement",

    questions: {
      delete: "Do you really want to delete this ladder reinforcement?",
    },

    errors: {
      alreadyPresent: "Ladder reinforcement already present.",
      notFound: "Ladder reinforcement not found.",
    },
  },

  wallMaterials: {
    createNew: "Create new wall material",
    delete: "Delete wall material",
    singular: "Wall material",
    plural: "Wall materials",
    search: "Search wall materials",
    noWallMaterial: "No wall material",

    questions: {
      delete: "Do you really want to delete this wall material?",
    },

    errors: {
      notFound: "Wall material not found.",
      missingName: "Wall material name is missing.",
      missingDescription: "Wall material description is missing.",
      inUseByConfiguration: "Wall material is still in use by a configuration.",
    },
  },

  wallMaterialFixings: {
    createNew: "Create new fixing",
    delete: "Delete fixing",
    singular: "Fixing",
    plural: "Fixings",
    search: "Search fixings",

    questions: {
      delete: "Do you really want to delete this fixing?",
    },

    errors: {
      notFound: "Fixing not found.",
      missingName: "Fixing name is missing.",
      missingDescription: "Fixing description is missing.",
      inUseByConfiguration: "Fixing is still in use by a configuration.",
    },
  },

  wallMaterialFixingItems: {
    errors: {
      alreadyPresent: "Fixing already present.",
    },
  },

  lowerElements: {
    createNew: "Create new lower element",
    delete: "Delete lower element",
    edit: "Edit lower element",
    singular: "Lower element",
    plural: "Lower elements",
    search: "Search lower elements",

    questions: {
      delete: "Do you really want to delete this lower element?",
    },

    errors: {
      alreadyPresent: "Lower element already present.",
      notFound: "Lower element not found.",
      inUseByConfiguration: "Lower element is still in use by a configuration.",
    },
  },

  shuttles: {
    addItemAsVertigrip: "Add item as Vertigrip shuttle",
    delete: "Delete shuttle",
    edit: "Edit shuttle",
    singular: "Shuttle",
    plural: "Shuttles",
    search: "Search shuttles",
    no: "No shuttle",

    questions: {
      delete: "Do you really want to delete this shuttle?",
    },

    errors: {
      alreadyPresent: "Shuttle already present.",
      notFound: "Shuttle not found.",
    },
  },

  installationTypes: {
    plural: "Installation types",
    singular: "Installation type",
    search: "Search installation types",

    questions: {},

    errors: {
      notFound: "Installation type not found.",
      missingName: "Installation type name is missing.",
      missingDescription: "Installation type description is missing.",
      noDefined: "No installation types defined.",
      inUseByConfiguration: "Installation type is still in use by a configuration.",
      inUseByUpperExtension: "Installation type is still in use by an upperExtension.",
      inUseByLowerElement: "Installation type is still in use by a lower element.",
      inUseByIntermediateElement: "Installation type is still in use by an intermediate element.",
      inUseByLadderReinforcement: "Installation type is still in use by a ladder reinforcement.",
    },
  },

  steelVersions: {
    createNew: "Create new steel version",
    delete: "Delete steel version",
    singular: "Steel version",
    plural: "Steel versions",
    search: "Search steel versions",

    questions: {
      delete: "Do you really want to delete this steel version?",
    },

    errors: {
      notFound: "Steel version not found.",
      missingName: "Steel version name is missing.",
      missingDescription: "Steel version description is missing.",
      noDefined: "No steel versions defined.",
      inUseByConfiguration: "Steel version is still in use by a configuration.",
      inUseByUpperExtension: "Steel version is still in use by an upperExtension.",
      inUseByLowerElement: "Steel version is still in use by a lower element.",
      inUseByIntermediateElement: "Steel version is still in use by an intermediate element.",
      inUseByAsorber: "Steel version is still in use by an absorber.",
    },
  },

  upperExtensions: {
    createNew: "Create new upper extension",
    delete: "Delete upper extension",
    edit: "Edit upper extension",
    singular: "Upper extension",
    plural: "Upper extensions",
    search: "Search upper extensions",

    questions: {
      delete: "Do you really want to delete this upper extension?",
    },

    errors: {
      alreadyPresent: "Upper extension already present.",
      notFound: "Upper extension not found.",
      noDefined: "No upper extensions defined.",
      inUseByConfiguration: "Upper extension is still in use by a configuration.",
    },
  },

  normatives: {
    createNew: "Create new normative",
    delete: "Delete normative",
    singular: "Normative",
    plural: "Normatives",
    search: "Search normatives",

    questions: {
      delete: "Do you really want to delete this normative?",
    },

    errors: {
      notFound: "Normative not found.",
      missingName: "Normative name is missing.",
      noDefined: "No normatives defined.",
      inUseByConfiguration: "Normative is still in use by a configuration.",
    },
  },

  systemTypes: {
    singular: "System type",
    plural: "System types",
    search: "Search system types",

    questions: {},

    errors: {
      notFound: "System type not found.",
      missingName: "System type name is missing.",
      missingDescription: "System type description is missing.",
      noDefined: "No system types defined.",
      inUseByConfiguration: "System is still in use by a configuration.",
      inUseByShuttle: "System is still in use by a shuttle.",
      inUseByIntermediateElement: "System is still in use by an intermediate element.",
    },
  },

  texts: {
    singular: "Text",
    plural: "Texts",
    search: "Search texts",

    questions: {},

    errors: {},
  },

  clients: {
    all: "All clients",
    plural: "Clients",
    createNew: "Create new client",
    delete: "Delete client",
    search: "Search clients",

    questions: {
      delete: "Do you really want to delete this client?",
    },

    errors: {
      notFound: "Client not found.",
      missingName: "Client name is missing.",
      insertNotAllowed: "You are not allowed to insert a new client.",
      readNotAllowed: "You are not allowed to read this client.",
      updateNotAllowed: "You are not allowed to update this client.",
      deleteNotAllowed: "You are not allowed to delete this client.",
    },
  },

  roles: {
    plural: "Roles",
  },

  permissions: {
    plural: "Permissions",
  },

  secrets: {
    plural: "Secrets",

    errors: {
      missingName: "Secret name is missing.",
      missingValue: "Secret value is missing.",
    },
  },

  common: {
    active: "Active",
    add: "Add",
    addNewItem: "Add new item",
    address: "Address",
    administration: "Administration",
    alreadyPresent: "Already present",
    archived: "Archived",
    atLeastOneItemNeeded: "At least one item is needed.",
    author: "Author",
    automaticSync: "Automatic sync",
    back: "Back",
    baseHeight: "Base height",
    cancel: "Cancel",
    close: "Close",
    code: "Code",
    company: "Company",
    confirmed: "Confirmed",
    contactPerson: "Contact person",
    cookiesText:
      "This website uses cookies respects your privacy. By clicking <b>Ok, I read it</b> you are aware of the cookies used by our site as specified in the cookie policy.",
    coordinates: "Coordinates",
    country: "Country",
    create: "Create",
    created: "Created",
    createNew: "Create new",
    dataHasBeenSavedSuccessfully: "Data has been saved succesfully.",
    decrease: (value: number) => `Decrease ${value}`,
    download: "Download",
    delete: "Delete",
    description: "Description",
    descriptionLong: "Description (long)",
    descriptionShort: "Description (short)",
    details: "Details",
    dragAndDropOrUploadAnImage: "Drag and Drop or click to upload an image",
    dropIt: "Drop it!",
    duplicate: "Duplicate",
    edit: "Edit",
    editor: "Editor",
    email: "E-mail",
    error: "Error",
    errorLoadingData: "Error loading data",
    excel: "Excel",
    failed: "Failed",
    fieldCannotBeEmpty: "The field cannot be empty.",
    fieldContainsInvalidValueInsertBiggerOne: "The field contains an invalid value, insert a bigger one.",
    fieldContainsInvalidValueInsertSmallerOne: "The field contains an invalid value, insert a smaller one.",
    fieldValueInvalidFormat: "The value has an invalid format.",
    fieldInvalidValue: "The field contains an invalid value.",
    fixed: "Fixed",
    forbidden: "Forbidden",
    generalData: "General data",
    genericError: "An error has occurred, please try again.",
    heightToReach: "Height to reach",
    hidden: "Hidden",
    home: "Home",
    increase: (value: number) => `Increase ${value}`,
    installed: "Installed",
    internediateFixation: "Intermediate fixation",
    isVisible: "Is visible",
    ladderExitDifference: "Ladder exit difference",
    languages: {
      singular: "Language",
      plural: "Languages",
      arabic: "Arabic",
      czech: "Czech",
      danish: "Danish",
      german: "German",
      greek: "Greek",
      english: "English",
      spanish: "Spanish",
      estonian: "Estonian",
      finnish: "Finnish",
      french: "French",
      croatian: "Croatian",
      hungarian: "Hungarian",
      icelandic: "Icelandic",
      italian: "Italian",
      japanese: "Japanese",
      lithuanian: "Lithuanian",
      latvian: "Latvian",
      dutch: "Dutch",
      norwegian: "Norvegian",
      polish: "Polish",
      portugese: "Portugese",
      russian: "Russian",
      slovak: "Slovak",
      slovenian: "Slovenian",
      swedish: "Swedish",
      turkish: "Turkish",
      chinese: "Chinese",
      korean: "Korean",
    },
    lastAccess: "Last access",
    lastModified: "Last modified",
    lastSync: "Last sync",
    loading: "Loading...",
    location: "Location",
    logout: "Logout",
    lowerFixation: "Lower fixation",
    minConfigurableQuantity: "Minimum configurable quantity",
    minimumOrderQuantity: "Minimum order quantity",
    minOrderQuantity: "Min order qty.",
    moreInformation: "More information",
    myArea: "My area",
    myProfile: "My Profile",
    name: "Name",
    next: "Next",
    never: "never",
    newName: "New name",
    nextNRows: (n: number) => `Next ${n} rows`,
    no: "No",
    noItemsFound: "No items found.",
    notFound: "Not found",
    nrIntermediatesAndSpan: "Nr. intermediates and span",
    nrOfFixingItemsRequired: "Nr of fixings items required (per wall material)",
    offer: "Offer",
    ok: "Ok",
    okIreadIt: "Ok, I read it",
    onlyImagesAreAllowed: "Only images are allowed.",
    optional: "optional",
    order: "Order",
    overwrite: "Overwrite",
    previousNRows: (n: number) => `Previous ${n} rows`,
    print: "Print",
    qty: "Qty.",
    removable: "Removable",
    remove: "Remove",
    removeImage: "Remove image",
    reset: "Reset",
    resetToDefault: "Reset to default",
    resourceNotFound: "The resource you are looking for has not been found.",
    save: "Save",
    search: "Search",
    select: "Select",
    selectProjectFirst: "Select project first",
    sessionExpired: "Session expired",
    sessionExpiredText: "Please log in again to continue using the app.",
    settings: "Settings",
    settingsSystem: (system: string) => `Settings: ${system}`,
    status: "Status",
    sync: "Sync",
    targa: "Targa",
    testEnvironment: "Test environment",
    toBeInstalled: "To be installed",
    toInspect: "To inspect",
    uploadedAt: "Uploaded",
    uploadedBy: "Uploaded by",
    upperFixation: "Upper fixation",
    value: "Value",
    visible: "Visible",
    yes: "Yes",
    youAreNotAllowed: "You are not allowed to access this resource.",
  },
};

export default en;
