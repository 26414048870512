import { ButtonHTMLAttributes, FC, Fragment, useContext, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import NotificationDispatch, {
  showErrorNotification,
  showSuccessNotification,
} from "../../../../../../../context/notificationContext";
import { createLoadOptions } from "../../../../../../../hooks/useDebounce";
import useTabs from "../../../../../../../hooks/useTabs";
import useI18n from "../../../../../../../hooks/useTranslations";
import { useApi } from "../../../../../../../http/api";
import { SearchAdminItem } from "../../../../../../../http/types/items";
import {
  AdminWallMaterialFixing,
  AdminWallMaterialFixingItem,
} from "../../../../../../../http/types/vertigrip/wallMaterialFixings";
import { useItemsApi } from "../../../../../../../http/useItems";
import { useAdminWallMaterialFixingsApi } from "../../../../../../../http/vertigrip/useWallMaterialFixings";
import { getUILanguages } from "../../../../../../../types";
import SvgAdd from "../../../../../../icons/Add";
import SvgRestore from "../../../../../../icons/Restore";
import Button from "../../../../../../ui/Button";
import ControlledNumericWithQuantity from "../../../../../../ui/ControlledNumericWithQuantity";
import Form from "../../../../../../ui/Form";
import FormField from "../../../../../../ui/FormField";
import FormFieldsContainer from "../../../../../../ui/FormFieldsContainer";
import Header from "../../../../../../ui/Header";
import useImageUploader from "../../../../../../ui/ImageUploader";
import Input from "../../../../../../ui/Input";
import ModalDialog from "../../../../../../ui/ModalDialog";
import Numeric from "../../../../../../ui/Numeric";
import reactSelectStyles from "../../../../../../ui/ReactSelectUtils";
import ScrollContent from "../../../../../../ui/ScrollContent";
import Switch from "../../../../../../ui/Switch";
import Tabs from "../../../../../../ui/Tabs";
import Textarea from "../../../../../../ui/Textarea";
import styles from "./Fixing.module.css";

export const NewFixing: FC = () => {
  const i18n = useI18n();
  const { systemId } = useParams<{ systemId: string }>();
  const { installationTypeId } = useParams<{ installationTypeId: string }>();
  const { wallMaterialId } = useParams<{ wallMaterialId: string }>();

  const path = [
    { url: `/admin/systems/${systemId}`, display: i18n.translation.systems.vertigrip },
    {
      url: `/admin/systems/${systemId}/settings?tab=3`,
      display: `${i18n.translation.common.settings}: ${i18n.translation.installationTypes.plural}`,
    },
    {
      url: `/admin/systems/${systemId}/installation_types/${installationTypeId}?tab=5`,
      display: i18n.translation.wallMaterials.plural,
    },
    {
      url: `/admin/systems/${systemId}/installation_types/${installationTypeId}/wall_materials/${wallMaterialId}?tab=1`,
      display: i18n.translation.wallMaterialFixings.plural,
    },
    { display: i18n.translation.common.createNew },
  ];

  return (
    <>
      <Header title={`${i18n.translation.wallMaterialFixings.createNew}`} path={path} />
      <Fixing wallMaterialId={wallMaterialId} />
    </>
  );
};

export const EditFixing: FC = () => {
  const i18n = useI18n();
  const { systemId } = useParams<{ systemId: string }>();
  const { installationTypeId } = useParams<{ installationTypeId: string }>();
  const { wallMaterialId } = useParams<{ wallMaterialId: string }>();
  const { id } = useParams<{ id: string }>();
  const wallMaterialFixingsApi = useAdminWallMaterialFixingsApi();

  const aborter = useRef(new AbortController());
  const { error, data, isMutating, trigger } = wallMaterialFixingsApi.getAdminWallMaterialFixing(
    id!,
    aborter.current.signal
  );

  const path = [
    { url: `/admin/systems/${systemId}`, display: i18n.translation.systems.vertigrip },
    {
      url: `/admin/systems/${systemId}/settings?tab=3`,
      display: `${i18n.translation.common.settings}: ${i18n.translation.installationTypes.plural}`,
    },
    {
      url: `/admin/systems/${systemId}/installation_types/${installationTypeId}?tab=5`,
      display: i18n.translation.wallMaterials.plural,
    },
    {
      url: `/admin/systems/${systemId}/installation_types/${installationTypeId}/wall_materials/${wallMaterialId}?tab=1`,
      display: i18n.translation.wallMaterialFixings.plural,
    },
    { display: data?.name! },
  ];

  useEffect(() => {
    id && trigger();
  }, [id]);

  return (
    <>
      <Header
        title={`${i18n.translation.wallMaterialFixings.singular}: ${data?.name ?? ""}`}
        error={error}
        path={path}
      />
      <Fixing fixing={!error ? data : undefined} wallMaterialId={undefined} disabled={isMutating || !!error} />
    </>
  );
};

interface item {
  id: string;
  code: string;
  quantity: number;
  minConfigurableQuantity: number;
}

interface CreateWallMaterialFixingForm {
  name: string | null;
  description: string | null;
  descriptionIt: string | null;
  descriptionDe: string | null;
  descriptionEs: string | null;
  descriptionFr: string | null;
  order: number | null;
  items: item[];
  isVisible: boolean;
}

type FixingProps = {
  fixing?: AdminWallMaterialFixing;
  wallMaterialId?: string;
  disabled?: boolean;
};

const Fixing: FC<FixingProps> = ({ fixing, wallMaterialId, disabled }: FixingProps) => {
  const i18n = useI18n();
  const api = useApi();
  const { systemId } = useParams<{ systemId: string }>();
  const { installationTypeId } = useParams<{ installationTypeId: string }>();
  const dispatch = useContext(NotificationDispatch);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const wallMaterialFixingsApi = useAdminWallMaterialFixingsApi();

  const languages = getUILanguages(i18n.translation);
  const controller = useTabs(languages.length, "l_tab");
  const [tabs, setTabs] = useState(languages.map((x) => ({ title: x.display, value: x.value })));

  const { component, newImage, removeImage, onSave } = useImageUploader({
    defaultImage: fixing?.image,
    disabled: disabled || controller.selectedIndex != 0,
  });

  const [originalItems, setOriginalItems] = useState<AdminWallMaterialFixingItem[]>(fixing?.items ?? []);

  const saveImage = (id: string, file: File, callback: () => void) => {
    wallMaterialFixingsApi
      .generateUploadImageUrl(id, file.name)
      .then((uploadPolicy) => {
        api
          .uploadFile(uploadPolicy, file)
          .then((res) => {
            if (!res.ok) {
              dispatch(showErrorNotification(i18n, res));
              setIsSubmitting(false);
              return;
            }
            onSave();
            callback();
          })
          .catch((err) => {
            dispatch(showErrorNotification(i18n, err));
            setIsSubmitting(false);
          });
      })
      .catch((err) => {
        dispatch(showErrorNotification(i18n, err));
        setIsSubmitting(false);
      });
  };

  const defaultValues = {
    name: fixing?.name,
    description: fixing?.description,
    descriptionIt: fixing?.descriptionIt ?? null,
    descriptionDe: fixing?.descriptionDe ?? null,
    descriptionEs: fixing?.descriptionEs ?? null,
    descriptionFr: fixing?.descriptionFr ?? null,
    order: fixing?.order ?? 50,
    items: fixing?.items ?? [],
    isVisible: fixing?.isVisible ?? false,
  };

  useEffect(() => {
    setTabs(languages.map((x) => ({ title: x.display, value: x.value })));
  }, [i18n.translation]);

  const panel = (
    <ScrollContent direction="row">
      <Form<CreateWallMaterialFixingForm>
        defaultValues={defaultValues}
        submitText={i18n.translation.common.save}
        // disable only if it is a new fixing
        disabled={(isSubmitting && !fixing) || disabled}
        onSubmit={() => (formData) => {
          if (formData.items.length === 0) {
            return;
          }

          setIsSubmitting(true);

          const itemsWithQuantities = formData.items.map((x) => ({ id: x.id, quantity: x.quantity }));

          const commonBody = {
            name: formData.name?.trim()!,
            order: formData.order!,
            description: formData.description!,
            descriptionIt: formData.descriptionIt?.trim() ?? null,
            descriptionDe: formData.descriptionDe?.trim() ?? null,
            descriptionEs: formData.descriptionEs?.trim() ?? null,
            descriptionFr: formData.descriptionFr?.trim() ?? null,
            wallMaterialId: fixing?.wallMaterial.id ?? wallMaterialId!,
            image: newImage?.name,
            items: itemsWithQuantities,
            isVisible: formData.isVisible,
          };

          if (fixing?.id) {
            const onSuccess = () => {
              wallMaterialFixingsApi
                .updateAdminWallMaterialFixing(fixing.id, {
                  ...commonBody,
                  removeImage: removeImage,
                })
                .then(() => {
                  setIsSubmitting(false);
                  dispatch(showSuccessNotification(i18n.translation));
                })
                .catch((err) => {
                  dispatch(showErrorNotification(i18n, err));
                  setIsSubmitting(false);
                });
            };
            newImage?.name ? saveImage(fixing.id, newImage, onSuccess) : onSuccess();
          } else {
            wallMaterialFixingsApi
              .insertAdminWallMaterialFixing({
                ...commonBody,
              })
              .then((res) => {
                const onSuccess = () => {
                  setIsSubmitting(false);
                  navigate(
                    `/admin/systems/${systemId}/installation_types/${installationTypeId}/wall_materials/${wallMaterialId}/fixings/${res.id}`,
                    { replace: true }
                  );
                };
                newImage?.name ? saveImage(res.id, newImage, onSuccess) : onSuccess();
              })
              .catch((err) => {
                dispatch(showErrorNotification(i18n, err));
                setIsSubmitting(false);
              });
          }
        }}
      >
        {({ control, setValue, register, formState: { errors }, reset, clearErrors, watch }) => {
          const visible = !!watch("isVisible");

          useEffect(() => {
            fixing?.id && reset(defaultValues);
            setValue("items", fixing?.items ?? []);
            setOriginalItems(fixing?.items ?? []);
          }, [fixing?.id]);

          return (
            <FormFieldsContainer imageUploader={component} tabbed>
              <FormField label={i18n.translation.common.name} error={errors.name}>
                {({ labelId, isInvalid, isOptional }) => (
                  <Input
                    id={labelId}
                    isInvalid={isInvalid}
                    disabled={disabled || controller.selectedIndex != 0}
                    {...register("name", {
                      required: !isOptional,
                      minLength: 1,
                    })}
                  />
                )}
              </FormField>
              <FormField
                label={i18n.translation.common.description}
                error={errors.description}
                hidden={controller.selectedIndex != 0}
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("description", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return <Textarea id={labelId} isInvalid={isInvalid} disabled={disabled} {...options} />;
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.description}
                error={errors.descriptionIt}
                hidden={controller.selectedIndex != 1}
                isOptional
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("descriptionIt", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return <Textarea id={labelId} isInvalid={isInvalid} disabled={disabled} {...options} />;
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.description}
                error={errors.descriptionDe}
                hidden={controller.selectedIndex != 2}
                isOptional
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("descriptionDe", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return <Textarea id={labelId} isInvalid={isInvalid} disabled={disabled} {...options} />;
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.description}
                error={errors.descriptionEs}
                hidden={controller.selectedIndex != 3}
                isOptional
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("descriptionEs", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return <Textarea id={labelId} isInvalid={isInvalid} disabled={disabled} {...options} />;
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.description}
                error={errors.descriptionFr}
                hidden={controller.selectedIndex != 4}
                isOptional
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("descriptionFr", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return <Textarea id={labelId} isInvalid={isInvalid} disabled={disabled} {...options} />;
                }}
              </FormField>
              <FormField label={i18n.translation.common.isVisible}>
                {({ labelId }) => (
                  <Switch
                    id={labelId}
                    checked={visible}
                    disabled={disabled}
                    onChange={(checked) => setValue("isVisible", checked)}
                  />
                )}
              </FormField>
              <Controller
                name="order"
                control={control}
                rules={{ required: true, min: 1 }}
                render={({ field, fieldState }) => (
                  <FormField label={i18n.translation.common.order} error={fieldState.error}>
                    {({ labelId, isInvalid }) => (
                      <Numeric
                        {...field}
                        id={labelId}
                        disabled={disabled || controller.selectedIndex != 0}
                        isInvalid={isInvalid}
                        decimalScale={0}
                        allowNegative={false}
                        onBlur={(value) => setValue("order", value)}
                      />
                    )}
                  </FormField>
                )}
              />
              <Controller
                name="items"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: i18n.translation.common.atLeastOneItemNeeded,
                  },
                }}
                disabled={disabled}
                defaultValue={defaultValues.items}
                render={({ field, fieldState }) => (
                  <>
                    <FormField
                      label={`${i18n.translation.items.plural} (+${i18n.translation.common.qty})`}
                      error={fieldState.error}
                    >
                      <ControlledNumericWithQuantity.List>
                        {field.value.map((x) => (
                          <Fragment key={x.id}>
                            <ControlledNumericWithQuantity
                              item={{
                                id: x.id,
                                display: x.code,
                                quantity: x.quantity,
                                scale: x.minConfigurableQuantity ?? 1,
                              }}
                              disabled={{
                                display: true,
                                quantity: disabled || controller.selectedIndex != 0,
                                controls: disabled || controller.selectedIndex != 0,
                              }}
                              decimalScale={x.minConfigurableQuantity?.toString().split(".")[1]?.length ?? 0}
                              onUpdateQuantity={(id, quantity) =>
                                setValue(
                                  "items",
                                  field.value.reduce((rv: item[], i) => {
                                    if (i.id === id) {
                                      return [...rv, { ...i, quantity: quantity }];
                                    }
                                    return [...rv, i];
                                  }, [])
                                )
                              }
                              onRemove={(id) =>
                                setValue(
                                  "items",
                                  field.value.reduce((rv: item[], i) => {
                                    if (i.id === id) {
                                      return rv;
                                    }
                                    return [...rv, i];
                                  }, [])
                                )
                              }
                            />
                          </Fragment>
                        ))}
                      </ControlledNumericWithQuantity.List>
                    </FormField>
                    <div className={styles.itemsButtonContainer}>
                      <Button
                        glyph={SvgRestore}
                        type="secondary"
                        buttonProps={{
                          disabled: disabled || controller.selectedIndex != 0,
                          onClick: () => {
                            setValue("items", originalItems);
                            if (originalItems.length > 0) {
                              clearErrors("items");
                            }
                          },
                          title: i18n.translation.common.reset,
                        }}
                      />
                      <AddItemButton
                        disabled={disabled || controller.selectedIndex != 0}
                        onAdd={(item) => {
                          if (field.value.find((x) => x.id === item.id)) {
                            dispatch(
                              showErrorNotification(
                                i18n,
                                null,
                                i18n.translation.wallMaterialFixingItems.errors.alreadyPresent
                              )
                            );
                            return false;
                          }
                          setValue("items", [
                            ...field.value,
                            {
                              id: item.id,
                              code: item.code,
                              quantity: item.minConfigurableQuantity ?? 1,
                              minConfigurableQuantity: item.minConfigurableQuantity ?? 1,
                            },
                          ]);
                          return true;
                        }}
                      />
                    </div>
                  </>
                )}
              />
            </FormFieldsContainer>
          );
        }}
      </Form>
    </ScrollContent>
  );

  return <Tabs controller={controller} tabs={tabs} panels={languages.map(() => panel)} />;
};

type AddItemButtonProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, "onClick"> & {
  onAdd: (item: SearchAdminItem) => boolean;
};

const AddItemButton: FC<AddItemButtonProps> = ({ onAdd, ...rest }: AddItemButtonProps) => {
  const i18n = useI18n();
  const [showNewItemDialog, setShowNewItemDialog] = useState(false);
  const searchTerm = useRef("");
  const aborter = useRef(new AbortController());
  const itemsApi = useItemsApi();

  const loadOptions = createLoadOptions(aborter, searchTerm, itemsApi.getAdminItems);
  const [item, setItem] = useState<SearchAdminItem | null>(null);

  return (
    <>
      <Button glyph={SvgAdd} type="secondary" buttonProps={{ ...rest, onClick: () => setShowNewItemDialog(true) }} />
      <ModalDialog
        isOpen={showNewItemDialog}
        onClose={() => setShowNewItemDialog(false)}
        title={i18n.translation.common.addNewItem}
        minHeight="320px"
        actions={[
          { onClick: () => setShowNewItemDialog(false), title: i18n.translation.common.cancel },
          {
            onClick: () => {
              if (item) {
                const success = onAdd(item);

                if (success) {
                  setShowNewItemDialog(false);
                  setItem(null);
                }
              }
            },
            title: i18n.translation.common.add,
            isSubmitting: !item,
          },
        ]}
      >
        <AsyncSelect
          loadOptions={loadOptions}
          isClearable
          getOptionLabel={(x) => x.code}
          getOptionValue={(x) => x.id}
          styles={reactSelectStyles<SearchAdminItem>()}
          value={item}
          onChange={(value) => {
            searchTerm.current = "";
            setItem(value);
          }}
        />
      </ModalDialog>
    </>
  );
};
