import { FC } from "react";
import useI18n from "../../../../../hooks/useTranslations";
import ModalDialog from "../../../../ui/ModalDialog";
import LowerElementForm from "./LowerElementForm";

interface EditLowerElementModalDialogProps {
  lowerElementId?: string;
  steelVersionId?: string;
  installationTypeId?: string;
  onClose: () => void;
}

const EditLowerElementModalDialog: FC<EditLowerElementModalDialogProps> = ({
  lowerElementId,
  steelVersionId,
  installationTypeId,
  onClose,
}: EditLowerElementModalDialogProps) => {
  const i18n = useI18n();

  return (
    <ModalDialog isOpen={!!lowerElementId} onClose={() => onClose()} title={i18n.translation.lowerElements.edit}>
      <LowerElementForm
        onCancel={onClose}
        lowerElementId={lowerElementId}
        steelVersionId={steelVersionId}
        installationTypeId={installationTypeId}
      />
    </ModalDialog>
  );
};

export default EditLowerElementModalDialog;
