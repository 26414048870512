import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";
import useSWRMutation from "swr/mutation";
import { Paginated, PaginationQuery, PostPolicy } from "../../types";
import { buildUrl, mutateCache, useApi } from "../api";
import { CustomError } from "../errors";
import { AdminText, UpdateTextNewImageBody, UpdateTextRemoveImageBody } from "../types/vertigrip/texts";

export const useAdminTextsApi = () => {
  const api = useApi();

  const getAdminText = useCallback(
    (id: string, signal: AbortSignal) => {
      const url = `/admin/settings/vertigrip/texts/${id}`;
      return useSWRMutation<AdminText, CustomError, string>(url, (url) => api.get<AdminText>({ url, signal }));
    },
    [api]
  );

  const useAdminTexts = useCallback(
    (query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl("/admin/settings/vertigrip/texts", query);
      return useSWRImmutable<Paginated<AdminText>, CustomError, string>(url, (url) =>
        api.get<Paginated<AdminText>>({ url, signal })
      );
    },
    [api]
  );

  // const insertAdminText = useCallback(
  //   async (body: InsertTextBody): Promise<{ id: string }> => {
  //     const url = `/admin/settings/vertigrip/texts`;
  //     const text = await api.post<{ id: string }, InsertTextBody>(url, body);
  //     mutateCache([url]);
  //     return text;
  //   },
  //   [api]
  // );

  const updateAdminText = useCallback(
    async (textId: string, body: UpdateTextRemoveImageBody | UpdateTextNewImageBody): Promise<void> => {
      const url = `/admin/settings/vertigrip/texts/${textId}`;
      await api.put<UpdateTextRemoveImageBody | UpdateTextNewImageBody>(url, body),
        mutateCache(["/admin/settings/vertigrip/texts"]);
    },
    [api]
  );

  // const deleteAdminText = useCallback(
  //   async (textId: string): Promise<void> => {
  //     const url = `/admin/settings/vertigrip/texts/${textId}`;
  //     await api.del(url).then(() => {
  //       mutateCache([`/admin/settings/vertigrip/texts`]);
  //     });
  //   },
  //   [api]
  // );

  const generateUploadImageUrl = useCallback(
    async (textId: string, fileName: string): Promise<PostPolicy> => {
      const encodedFileName = encodeURIComponent(fileName);
      const url = `/admin/settings/vertigrip/texts/${textId}/image/generate_upload_url?file_name=${encodedFileName}`;
      return api.get<PostPolicy>({ url });
    },
    [api]
  );

  return {
    getAdminText,
    useAdminTexts,
    // insertAdminText,
    updateAdminText,
    // deleteAdminText,
    generateUploadImageUrl,
  };
};
