import classNames from "classnames";
import { Fragment, PropsWithChildren, ReactNode, useEffect, useState } from "react";
import { UseListFiltersReturn } from "../../hooks/useListFilters";
import useI18n from "../../hooks/useTranslations";
import SvgNavigateNext from "../icons/NagivateNext";
import SvgNavigateBefore from "../icons/NavigateBefore";
import Button from "./Button";
import ErrorText from "./ErrorText";
import ListItemMenu from "./ListItemMenu";
import LoadingSpinner from "./LoadingSpinner";
import SearchBar from "./SearchBar";
import styles from "./Table.module.css";

interface Props<T> {
  className?: string;
  filters?: UseListFiltersReturn;
  searchOptions?: {
    disabled?: boolean;
    placeholder: string;
    onChange: () => void;
  };
  total?: number;
  head?: ReactNode;
  items?: T[];
  renderRow: (item: T) => ReactNode;
  rowActions?: {
    title: string;
    onClick: (item: T) => void;
    isDisabled?: (item: T) => boolean;
  }[];
  footer?: ReactNode;
  isTall?: boolean;
  searchButtons?: ReactNode | ReactNode[];
  error?: boolean;
}

const Table = <T,>({
  className,
  filters,
  searchOptions,
  total = -1,
  head,
  items,
  renderRow,
  rowActions,
  footer,
  isTall = false,
  searchButtons,

  error,
}: Props<T>) => {
  const i18n = useI18n();
  const isSearchable = filters && filters.isSearchable;
  const page = filters?.state.page ?? 1;
  const limit = filters?.state.limit ?? 5;

  const fromItem = (page - 1) * limit + 1;
  const maxToItem = fromItem + limit - 1;

  const [data, setData] = useState<{ items: T[]; total: number }>();

  useEffect(() => {
    !!items && setData({ items, total });
  }, [items]);

  const toItem = Math.max(Math.min(maxToItem, data?.total ?? 0), 0);

  return (
    <>
      {(isSearchable || searchButtons) && (
        <div className={styles.heading}>
          <div className={styles.searchWrapper}>
            {isSearchable && (
              <SearchBar
                className={styles.search}
                placeholder={searchOptions?.placeholder ?? i18n.translation.common.search}
                value={filters.state.q ?? ""}
                onChange={(term) => {
                  if (searchOptions && !searchOptions?.disabled) {
                    searchOptions.onChange();
                    filters.setSearchTerm(term);
                  }
                }}
              />
            )}
          </div>
          {(Array.isArray(searchButtons) ? searchButtons : [searchButtons]).map((x, i) => (
            <Fragment key={`button_${i}`}>{x}</Fragment>
          ))}
        </div>
      )}

      <table
        className={classNames(className, styles.table, {
          [styles.tall]: isTall,
        })}
      >
        {head && <thead>{head}</thead>}
        {!error && !!data?.items && (
          <>
            <tbody>
              {data?.items?.map((item, index) => {
                return (
                  <tr key={`table_row_${index}`}>
                    {renderRow?.(item)}
                    {rowActions ? (
                      <td width={1}>
                        <ListItemMenu item={item} actions={rowActions} />
                      </td>
                    ) : (
                      <></>
                    )}
                  </tr>
                );
              })}
            </tbody>
            {footer && <tfoot>{footer}</tfoot>}
          </>
        )}
      </table>

      {!error && !!data?.items && data.total === 0 && (
        <div className={styles.noItemsFound}>{i18n.translation.common.noItemsFound}</div>
      )}

      {!error && !data?.items && (
        <div className={styles.loadingSpinner}>
          <LoadingSpinner />
        </div>
      )}
      {error && (
        <div className={styles.errorLoadingData}>
          <ErrorText text={i18n.translation.common.errorLoadingData} />
        </div>
      )}

      {!error && !!data?.items && data.total > 0 && (
        <div className={styles.controls}>
          <div>
            {fromItem} - {toItem} of {data.total}
          </div>
          {data.total > limit && (
            <div className={styles.pagination}>
              <Button
                glyph={SvgNavigateBefore}
                buttonProps={{
                  title: i18n.translation.common.previousNRows(limit),
                  disabled: page === 1,
                  onClick: () => filters?.prevPage(),
                  className: styles.paginationButton,
                }}
              />
              <Button
                glyph={SvgNavigateNext}
                buttonProps={{
                  title: i18n.translation.common.nextNRows(limit),
                  disabled: toItem === data.total,
                  onClick: () => filters?.nextPage(),
                  className: styles.paginationButton,
                }}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

const IconTd = ({ children }: PropsWithChildren) => (
  <td className={styles.iconTd}>
    <div>{children}</div>
  </td>
);

Table.IconTd = IconTd;

export default Table;
