import { FC } from "react";
import SvgError from "../icons/Error";
import styles from "./ErrorText.module.css";
import Icon from "./Icon";

interface Props {
  text: string;
}

const ErrorText: FC<Props> = ({ text }: Props) => (
  <span className={styles.text}>
    <Icon glyph={SvgError} className={styles.icon} /> {text}
  </span>
);

export default ErrorText;
