import { Glyph } from "../../types";

const SvgChecked: Glyph = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 -5 17 21" {...props} height="100%" width="100%">
      <path d="M11.264 0L5.26 6.004 2.103 2.847 0 4.95l5.26 5.26 8.108-8.107L11.264 0" fill="#fff" fillRule="evenodd" />
    </svg>
  );
};

export default SvgChecked;
