import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";
import useSWRMutation from "swr/mutation";
import { Paginated, PaginationQuery, PostPolicy } from "../../types";
import { buildUrl, mutateCache, useApi } from "../api";
import { CustomError } from "../errors";
import {
  AdminNormative,
  InsertNormativeBody,
  UpdateNormativeNewImageBody,
  UpdateNormativeRemoveImageBody,
} from "../types/vertigrip/normatives";

export const useAdminNormativesApi = () => {
  const api = useApi();

  const getAdminNormative = useCallback(
    (id: string, signal: AbortSignal) => {
      const url = `/admin/settings/vertigrip/normatives/${id}`;
      return useSWRMutation<AdminNormative, CustomError, string>(url, (url) =>
        api.get<AdminNormative>({ url, signal })
      );
    },
    [api]
  );

  const useAdminNormatives = useCallback(
    (query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl("/admin/settings/vertigrip/normatives", query);
      return useSWRImmutable<Paginated<AdminNormative>, CustomError, string>(url, (url) =>
        api.get<Paginated<AdminNormative>>({ url, signal })
      );
    },
    [api]
  );

  const insertAdminNormative = useCallback(
    async (body: InsertNormativeBody): Promise<{ id: string }> => {
      const url = `/admin/settings/vertigrip/normatives`;
      const id = await api.post<{ id: string }, InsertNormativeBody>(url, body);
      mutateCache([url]);
      return id;
    },
    [api]
  );

  const updateAdminNormative = useCallback(
    async (normativeId: string, body: UpdateNormativeRemoveImageBody | UpdateNormativeNewImageBody): Promise<void> => {
      const url = `/admin/settings/vertigrip/normatives/${normativeId}`;
      await api.put<UpdateNormativeRemoveImageBody | UpdateNormativeNewImageBody>(url, body),
        mutateCache(["/admin/settings/vertigrip/normatives"]);
    },
    [api]
  );

  const deleteAdminNormative = useCallback(
    async (normativeId: string): Promise<void> => {
      const url = `/admin/settings/vertigrip/normatives/${normativeId}`;
      await api.del(url).then(() => {
        mutateCache([`/admin/settings/vertigrip/normatives`]);
      });
    },
    [api]
  );

  const generateUploadImageUrl = useCallback(
    async (normativeId: string, fileName: string): Promise<PostPolicy> => {
      const encodedFileName = encodeURIComponent(fileName);
      const url = `/admin/settings/vertigrip/normatives/${normativeId}/image/generate_upload_url?file_name=${encodedFileName}`;
      return api.get<PostPolicy>({ url });
    },
    [api]
  );

  return {
    getAdminNormative,
    useAdminNormatives,
    insertAdminNormative,
    updateAdminNormative,
    deleteAdminNormative,
    generateUploadImageUrl,
  };
};
