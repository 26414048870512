import classNames from "classnames";
import { ReactNode } from "react";
import { DefaultValues, FieldValues, Mode, SubmitHandler, UseFormReset, UseFormReturn, useForm } from "react-hook-form";
import Button from "./Button";
import styles from "./Form.module.css";
import SubmitBar from "./SubmitBar";

export type FormChildrenProps<TFieldValues extends FieldValues> = UseFormReturn<TFieldValues> & {
  disabled?: boolean;
};

type Props<TFieldValues extends FieldValues> = {
  disabled?: boolean;
  secondaryButton?: ReactNode;
  onSubmit?: (reset: UseFormReset<TFieldValues>) => SubmitHandler<TFieldValues>;
  submitText?: string;
  defaultValues?: DefaultValues<TFieldValues>;
  children?: ReactNode | ((useFormProps: FormChildrenProps<TFieldValues>) => ReactNode);
  mode?: Mode;
  fullWidth?: boolean;
};

const Form = <TFieldValues extends FieldValues>({
  children,
  disabled,
  secondaryButton,
  submitText,
  defaultValues,
  onSubmit,
  mode = "onSubmit",
  fullWidth = false,
}: Props<TFieldValues>) => {
  const useFormProps = useForm<TFieldValues>({
    defaultValues,
    mode: mode,
  });
  const { isSubmitting } = useFormProps.formState;

  return (
    <form
      className={classNames(styles.form, fullWidth ? styles.fullWidth : undefined)}
      onSubmit={(e) => {
        if (onSubmit) {
          e.stopPropagation();
          useFormProps.handleSubmit(onSubmit(useFormProps.reset))(e);
        }
      }}
    >
      {typeof children === "function" ? children({ ...useFormProps, disabled }) : children}

      {((onSubmit && submitText) || secondaryButton) && (
        <SubmitBar>
          {!!secondaryButton && secondaryButton}
          {onSubmit && submitText && (
            <Button
              isLoading={isSubmitting}
              buttonProps={{
                type: "submit",
                disabled: disabled || isSubmitting,
              }}
            >
              {submitText}
            </Button>
          )}
        </SubmitBar>
      )}
    </form>
  );
};

export default Form;
