import { createContext, Dispatch, useReducer } from "react";
import { I18n, Translation } from "../hooks/useTranslations";
import { getErrorMessage } from "../http/errors";

type Severity = "success" | "error" | "informative";

type State = {
  show: boolean;
  severity: Severity;
  message: string;
  sticky?: boolean;
};

type Action =
  | {
      type: "SHOW_NOTIFICATION";
      severity: Severity;
      message: string;
      sticky?: boolean;
    }
  | { type: "HIDE_NOTIFICATION" };

const showNotification = (severity: Severity, message: string, sticky?: boolean): Action => ({
  type: "SHOW_NOTIFICATION",
  severity,
  message,
  sticky,
});

export const showInformativeNotification = (message: string, sticky: boolean): Action =>
  showNotification("informative", message, sticky);

export const showSuccessNotification = (translations: Translation, message?: string, sticky?: boolean): Action =>
  showNotification("success", message ?? translations.common.dataHasBeenSavedSuccessfully, sticky ?? false);

export const showErrorNotification = (i18n: I18n, error: unknown, message?: string, sticky?: boolean): Action => {
  if (error instanceof Error) {
    console.error(error);
  }
  return showNotification("error", message ?? getErrorMessage(i18n, error), sticky);
};

export const hideNotification = (): Action => ({
  type: "HIDE_NOTIFICATION",
});

const initialState: State = {
  show: false,
  severity: "success",
  message: "",
};

const notificationReducer = (_: State, action: Action): State => {
  switch (action.type) {
    case "SHOW_NOTIFICATION":
      return { show: true, severity: action.severity, message: action.message };
    case "HIDE_NOTIFICATION":
      return initialState;
  }
};

export const useNotification = (): [State, Dispatch<Action>] => useReducer(notificationReducer, initialState);

const NotificationDispatch = createContext<Dispatch<Action>>(() => {});

export default NotificationDispatch;
