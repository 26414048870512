import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";
import useSWRMutation from "swr/mutation";
import { Paginated, PaginationQuery } from "../../types";
import { buildUrl, mutateCache, useApi } from "../api";
import { CustomError } from "../errors";
import {
  InsertIntermediateElementBody,
  IntermediateElement,
  UpdateIntermediateElementBody,
} from "../types/vertigrip/intermediateElements";

export const useAdminIntermediateElementsApi = () => {
  const api = useApi();

  const getAdminIntermediateElement = useCallback(
    (id: string, signal: AbortSignal) => {
      const url = `/admin/settings/vertigrip/intermediate_elements/${id}`;
      return useSWRMutation<IntermediateElement, CustomError, string>(url, (url) =>
        api.get<IntermediateElement>({ url, signal })
      );
    },
    [api]
  );

  const useInstallationTypeAdminIntermediateElements = useCallback(
    (installationTypeId: string, query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl(
        `/admin/settings/vertigrip/installation_types/${installationTypeId}/intermediate_elements`,
        query
      );
      return useSWRImmutable<Paginated<IntermediateElement>, CustomError, string>(url, (url) =>
        api.get<Paginated<IntermediateElement>>({ url, signal })
      );
    },
    [api]
  );

  const useSystemTypeAdminIntermediateElements = useCallback(
    (systemTypeId: string, query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl(`/admin/settings/vertigrip/system_types/${systemTypeId}/intermediate_elements`, query);
      return useSWRImmutable<Paginated<IntermediateElement>, CustomError, string>(url, (url) =>
        api.get<Paginated<IntermediateElement>>({ url, signal })
      );
    },
    [api]
  );

  const useSteelVersionAdminIntermediateElements = useCallback(
    (steelVersionId: string, query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl(`/admin/settings/vertigrip/steel_versions/${steelVersionId}/intermediate_elements`, query);
      return useSWRImmutable<Paginated<IntermediateElement>, CustomError, string>(url, (url) =>
        api.get<Paginated<IntermediateElement>>({ url, signal })
      );
    },
    [api]
  );

  const insertAdminIntermediateElement = useCallback(
    async (body: InsertIntermediateElementBody): Promise<{ id: string }> => {
      const url = `/admin/settings/vertigrip/intermediate_elements`;
      const id = await api.post<{ id: string }, InsertIntermediateElementBody>(url, body);
      mutateCache([
        url,
        `/admin/settings/vertigrip/installation_types/${body.installationTypeId}/intermediate_elements`,
        `/admin/settings/vertigrip/system_types/${body.systemTypeId}/intermediate_elements`,
        `/admin/settings/vertigrip/steel_versions/${body.steelVersionId}/intermediate_elements`,
      ]);
      return id;
    },
    [api]
  );

  const updateAdminIntermediateElement = useCallback(
    async (intermediateElementId: string, body: UpdateIntermediateElementBody): Promise<void> => {
      const url = `/admin/settings/vertigrip/intermediate_elements/${intermediateElementId}`;
      await api.put<UpdateIntermediateElementBody>(url, body),
        mutateCache([
          `/admin/settings/vertigrip/intermediate_elements`,
          `/admin/settings/vertigrip/installation_types/${body.installationTypeId}/intermediate_elements`,
          `/admin/settings/vertigrip/system_types/${body.systemTypeId}/intermediate_elements`,
          `/admin/settings/vertigrip/steel_versions/${body.steelVersionId}/intermediate_elements`,
        ]);
    },
    [api]
  );

  const deleteAdminIntermediateElement = useCallback(
    async (
      intermediateElementId: string,
      steelVersionId: string,
      installationTypeId: string | null | undefined,
      systemTypeId: string | null | undefined
    ): Promise<void> => {
      const url = `/admin/settings/vertigrip/intermediate_elements/${intermediateElementId}`;
      await api.del(url).then(() => {
        mutateCache([
          `/admin/settings/vertigrip/intermediate_elements`,
          `/admin/settings/vertigrip/installation_types/${installationTypeId}/intermediate_elements`,
          `/admin/settings/vertigrip/system_types/${systemTypeId}/intermediate_elements`,
          `/admin/settings/vertigrip/steel_versions/${steelVersionId}/intermediate_elements`,
        ]);
      });
    },
    [api]
  );

  return {
    getAdminIntermediateElement,
    useInstallationTypeAdminIntermediateElements,
    useSystemTypeAdminIntermediateElements,
    useSteelVersionAdminIntermediateElements,
    insertAdminIntermediateElement,
    updateAdminIntermediateElement,
    deleteAdminIntermediateElement,
  };
};
